import {inject, injectable, Request, RequestStatus, Employee, GlobalContainerTypes, Language} from '../..';
import {RequestsNormalization} from './requests-normalization.types';
import {WorkspaceRequestActions, WorkspaceRequestStatus, WorkspacesRequestsResponse} from '../requests-api';
import {EmployeesNormalization} from '@innowise-group/core';

@injectable()
class RequestsNormalizationService implements RequestsNormalization {
  public static readonly type = GlobalContainerTypes.RequestsNormalization;

  @inject(GlobalContainerTypes.EmployeesNormalization) private employeesNormalization: EmployeesNormalization;
  @inject(GlobalContainerTypes.Language) private language: Language;

  constructor() {
    this.normalizeWorkspaceActionRequestFromApi = this.normalizeWorkspaceActionRequestFromApi.bind(this);
    this.normalizeWorkspaceRequestFromApi = this.normalizeWorkspaceRequestFromApi.bind(this);
    this.normalizeRequestStatusFromApi = this.normalizeRequestStatusFromApi.bind(this);
  }

  public normalizeWorkspaceActionRequestFromApi(workspaceActionRequest: WorkspaceRequestActions): {
    status: RequestStatus;
    officeManager: Employee;
  } {
    const {status, office_manager} = workspaceActionRequest;
    return {
      status: this.normalizeRequestStatusFromApi(status),
      officeManager: this.employeesNormalization.normalizeEmployeeFromApi(office_manager),
    };
  }

  public normalizeWorkspaceRequestFromApi(workspaceRequest: WorkspacesRequestsResponse): Request {
    const {
      id,
      occupation_start_date,
      occupation_end_date,
      status,
      employee,
      workspace,
      room,
      office,
      office_eng,
      office_manager,
      creation_time,
      floor,
      comment,
      office_id,
      room_id,
      floor_id,
      personal_request,
      manager_comment,
      //backend isn't ready yet, need to set default value
      location_eng = '',
      location = '',
      request_type,
    } = workspaceRequest;
    return {
      id: id && String(id),
      user: this.employeesNormalization.normalizeEmployeeFromApi(employee),
      office: {
        address: this.language.checkAppLanguage() ? `${location}, ${office}` : `${location_eng}, ${office_eng}`,
        id: office_id && String(office_id),
      },
      room: {number: room, id: room_id && String(room_id)},
      seat: {number: String(workspace?.number), id: String(workspace?.id)},
      range: {
        start: occupation_start_date && new Date(occupation_start_date),
        end: occupation_end_date && new Date(occupation_end_date),
      },
      status:
        status === 'In progress' &&
        occupation_end_date &&
        new Date(occupation_end_date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
          ? 'canceled'
          : this.normalizeRequestStatusFromApi(status),
      comment: comment,
      managerComment: manager_comment,
      floor: {number: floor && String(floor), id: floor_id && String(floor_id)},
      officeManager: this.employeesNormalization.normalizeEmployeeFromApi(office_manager),
      created: creation_time && new Date(creation_time),
      personalRequest: personal_request,
      requestType: request_type,
    };
  }

  private normalizeRequestStatusFromApi(workspaceStatus: WorkspaceRequestStatus): RequestStatus {
    switch (workspaceStatus) {
      case 'In progress':
        return 'in-clarification';
      case 'Approved':
        return 'approved';
      case 'Rejected':
        return 'rejected';
      case 'Delayed':
        return 'delayed';
      case 'Canceled':
        return 'canceled';
      case 'Conflict':
        return 'conflict';
    }
  }
}

export default RequestsNormalizationService;
