import {FieldValues, UseFormWatch} from 'react-hook-form';
import {addDays, differenceInDays, subDays, addMonths} from 'date-fns';

const minDateEndDay = (watch: UseFormWatch<FieldValues>, field: string) => {
  const startDay = watch(field);
  if (startDay) {
    return new Date(startDay);
  }
  return new Date();
};

const minDateStartDay = (watch: UseFormWatch<FieldValues>, field: string) => {
  const endDay = watch(field);
  if (endDay) {
    const difference = differenceInDays(endDay, new Date());
    return difference >= 31 ? subDays(endDay, 31) : new Date();
  }
  return new Date();
};

const maxDateStartDay = (watch: UseFormWatch<FieldValues>, field: string) => {
  const endDay = watch(field);
  return endDay ? new Date(endDay) : undefined;
};

const maxDateEndDay = (watch: UseFormWatch<FieldValues>, field: string) => {
  return addDays(watch(field), 31);
};

const maxInRange = (range: [Date, Date] | undefined) => {
  if (range?.every(Boolean)) return undefined;

  const startDate = range?.[0];
  return startDate ? addMonths(startDate, 1) : undefined;
};

const validateDate = {
  minDateStartDay,
  minDateEndDay,
  maxDateStartDay,
  maxDateEndDay,
  maxInRange,
};

export default validateDate;
