import React from 'react';
import {TabProps} from './tab.component';
import * as Styled from './tabs.styles';

export interface TabsProps {
  value?: unknown;
  noScroll?: boolean;
}

const Tabs: React.FC<TabsProps> = ({children, value = 0, noScroll}) => {
  return (
    <Styled.TabsContainer noScroll={noScroll}>
      {React.Children.map(children, (item) => {
        if (!React.isValidElement<TabProps>(item)) {
          return null;
        }

        return React.cloneElement(item, {...item.props, tabsvalue: value});
      })}
    </Styled.TabsContainer>
  );
};

export default Tabs;
