import {LocationsNormalization} from './locations-normalization.types';
import {GlobalContainerTypes, inject, Language, Location, LocationResponse} from '@innowise-group/core';
import {injectable} from 'inversify';

@injectable()
class LocationsNormalizationService implements LocationsNormalization {
  public static readonly type = GlobalContainerTypes.LocationsNormalization;
  @inject(GlobalContainerTypes.Language) private language: Language;

  constructor() {
    this.normalizeLocationFromApi = this.normalizeLocationFromApi.bind(this);
  }

  public normalizeLocationFromApi(location: LocationResponse): Location {
    return {
      id: location?.id && String(location?.id),
      countryName: location?.country_name,
      city: this.language.checkAppLanguage() ? location?.city : location?.city_eng,
      country: location?.country,
    };
  }
}

export default LocationsNormalizationService;
