import {Avatar, styled, Typography} from '@innowise-group/ui-kit';

export const EmployeeInfoContainerForm = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
  border-radius: 6px;
  padding: 15px;
  box-sizing: border-box;
`;

export const EmployeePhoto = styled(Avatar)`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const InfoText = styled(Typography)`
  position: absolute;
  top: 90px;
  left: 50px;
  color: ${({theme}) => theme.palette.general.darkGrey[60]};
  font-size: 10px;

  @media (max-width: 481px) {
    font-size: 8px;
    left: 0px;
    top: 65px;
  }
`;
