import {ContainerModule, interfaces} from '@innowise-group/core';
import {OfficesContainerTypes} from './offices-ioc.types';
import {OfficesModalsFacadeService, OfficesModalsFacade} from './offices-modals-facade';

export const officesContainer = new ContainerModule(
  (
    bind: interfaces.Bind,
    unbind: interfaces.Unbind,
    isBound: interfaces.IsBound,
    rebind: interfaces.Rebind,
    unbindAsync: interfaces.UnbindAsync,
    onActivation: interfaces.Container['onActivation'],
    onDeactivation: interfaces.Container['onDeactivation'],
  ) => {
    bind<OfficesModalsFacade>(OfficesContainerTypes.OfficesModalsFacade).to(OfficesModalsFacadeService);
  },
);
