import {css, styled} from '@innowise-group/ui-kit';

export const Row = styled.div<{statisticView?: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 14px 0;
  min-height: 60px;
  transition: 0.2s;
  border-bottom: 1px solid ${({theme}) => theme.palette.general.lightGrey[60]};

  &:hover {
    background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
  }

  ${({statisticView}) => {
    if (statisticView) {
      return css`
        @media (max-width: 769px) {
          flex-wrap: wrap;

          & > div:nth-child(2n) {
            flex: 1 1 20%;
          }
          & > div:nth-child(2n + 1) {
            flex: 1 1 40%;
          }
          & > div:nth-child(3) {
            min-width: 350px;
          }
        }
        @media (max-width: 481px) {
          padding: 10px 0;
          & > div:nth-child(3) {
            min-width: 310px;
          }
        }
      `;
    }
  }}
`;

export const RowFirstItem = styled.div`
  flex: 1.5;
  padding: 0 10px;
`;

export const RowItem = styled(RowFirstItem)`
  flex: 1;
`;

export const Container = styled.div`
  background-color: ${({theme}) => theme.palette.general.paper};
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[60]};
  overflow: hidden;
  margin-top: 10px;
`;

export const RowGroupItems = styled(Row)`
  flex: 2.2;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
  border: none;
  @media (max-width: 769px) {
    padding: 10px;
    & > div {
      flex: 1 1 45%;
    }
  }
  @media (max-width: 481px) {
    & > div {
      flex: 1 1 30%;
    }
  }

  @media (max-width: 391px) {
    & > div {
      flex: 1 1 70%;
    }
  }
`;

export const RowGroupItem = styled.div`
  margin: 0 15px;
  width: 210px;
  @media (max-width: 1625px) {
    margin: 0;
  }
  padding: 3px 0 3px;
`;
