import {Button, Checkbox, Divider, Form, Input, withForm, WithFormProps} from '@innowise-group/ui-kit';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {RoomType} from '@innowise-group/core';
import {RoomTypesSelect} from '../room-type-select';

export enum EditRoomFormFields {
  IsOur = 'isOur',
  RoomNumber = 'number',
  RoomType = 'type',
}

export type EditRoomValues = {
  isOur: boolean;
  number: string;
  type: RoomType;
};

const EditRoomForm: React.FC<WithFormProps<EditRoomValues>> = ({onSubmit, onCancel, initialValues, isSubmit}) => {
  const {isOur, number, type} = initialValues;
  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
  } = useFormContext();

  const {t} = useTranslation();

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      <Form.Layout layoutType="vertical">
        <Controller
          defaultValue={isOur}
          name={EditRoomFormFields.IsOur}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[EditRoomFormFields.IsOur]}>
              <Checkbox checked={value} onChange={onChange} position="left">
                {t('modals.isOur')}
              </Checkbox>
            </Form.Field>
          )}
        />

        <Divider />

        <Controller
          defaultValue={number}
          name={EditRoomFormFields.RoomNumber}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[EditRoomFormFields.RoomNumber]} label={t('modals.roomName')}>
              <Input defaultValue={value} onChange={onChange} />
            </Form.Field>
          )}
        />

        <Divider />

        {watch(EditRoomFormFields.IsOur, isOur) ? (
          <Controller
            defaultValue={type}
            name={EditRoomFormFields.RoomType}
            control={control}
            render={({field: {onChange, value}}) => (
              <Form.Field error={errors[EditRoomFormFields.RoomType]} label={t('modals.roomType')}>
                <RoomTypesSelect value={value} onValueChange={onChange} />
              </Form.Field>
            )}
          />
        ) : null}
      </Form.Layout>
      <Form.Actions>
        <Button type="submit" disabled={isSubmit} withLoader={isSubmit}>
          {t('buttons.save')}
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
      </Form.Actions>
    </Form.Container>
  );
};

export default withForm(EditRoomForm);
