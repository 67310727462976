import {BookedStatus, Workspace} from '@innowise-group/core';
import setHours from 'date-fns/setHours';
import {format} from 'date-fns';

interface UpdatedBookedWorkspace {
  workspace: number;
  occupation_start_date: string;
  comment: string;
  id: number;
  employee: number;
  occupation_end_date: string;
}

export interface UpdatedWorkspaceDataResult {
  number: number;
  rotate_degree: number;
  occupation_start_date: string;
  coordinates: Array<number[]>;
  comment: string;
  id: number;
  employee: number;
  room: number;
  status: 'Booked' | 'Occupied' | 'Reserved' | 'Free' | 'Remote' | 'Coworking';
  occupation_end_date: string;
  booked_status?: UpdatedBookedWorkspace[];
  active_requests?: UpdatedBookedWorkspace[];
}

const updateBookedWorkspace = (workspace: BookedStatus): UpdatedBookedWorkspace => {
  const {number, id, employee, comment, range} = workspace;
  const startDayParts = range?.startDay?.split('.').map(Number);
  const endDayParts = range?.endDay?.split('.').map(Number);
  const start =
    startDayParts && format(new Date(startDayParts[2], startDayParts[1] - 1, startDayParts[0]), 'yyyy-MM-dd');
  const end = endDayParts && format(new Date(endDayParts[2], endDayParts[1] - 1, endDayParts[0]), 'yyyy-MM-dd');
  return {
    id: id ? Number(id) : null,
    workspace: number ? Number(number) : null,
    employee: employee?.id ? Number(employee?.id) : null,
    comment: comment ? comment : null,
    occupation_start_date: start ? setHours(new Date(start), 12).toISOString() : null,
    occupation_end_date: end ? setHours(new Date(end), 21).toISOString() : null,
  };
};

export const updateWorkspaceData = (
  workspace: Workspace,
  multipleUpdate: boolean = false,
): UpdatedWorkspaceDataResult => {
  const {
    number,
    status,
    room,
    id,
    employee,
    comment,
    range,
    rotateDegree,
    coordinates,
    bookedStatus = [],
    activeRequests = [],
  } = workspace;
  let updatedWorkspace = {
    id: id ? Number(id) : null,
    number: number ? Number(number) : null,
    status: status,
    employee: employee?.id ? Number(employee?.id) : null,
    room: room?.id ? Number(room.id) : null,
    occupation_start_date: range?.startDay ? setHours(new Date(range?.startDay), 9).toISOString() : null,
    occupation_end_date: range?.endDay ? setHours(new Date(range?.endDay), 18).toISOString() : null,
    coordinates,
    comment: comment ? comment : null,
    rotate_degree: rotateDegree,
  };
  if (multipleUpdate) {
    const bookedWorkspaces =
      bookedStatus.length > 0 ? bookedStatus.map((workspace) => updateBookedWorkspace(workspace)) : [];
    const activeWorkspaces =
      activeRequests.length > 0 ? activeRequests.map((workspace) => updateBookedWorkspace(workspace)) : [];
    updatedWorkspace['booked_status'] = bookedWorkspaces;
    updatedWorkspace['active_requests'] = activeWorkspaces;
  }
  return updatedWorkspace;
};
