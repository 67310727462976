import {injectable} from '@innowise-group/core';
import {Modal, modalManager} from '@innowise-group/ui-kit';
import {GeotrackDatailsInfo} from '../../components/geotrack-details-info';
import {EmployeeDateTimeMarksData, GeotrackContainerTypes} from '../../types';
import {GeotrackModalsFacade} from './geotrack-modals-facade.types';

@injectable()
class GeotrackModalsFacadeService implements GeotrackModalsFacade {
  public static readonly type = GeotrackContainerTypes.GeotrackModalsFacade;

  public openGeoTrackDetailsModal(initialValues: EmployeeDateTimeMarksData): void {
    modalManager.open(({close}) => (
      <>
        <Modal.Container>
          <Modal.Body>
            <GeotrackDatailsInfo onClose={close} initialValues={initialValues} />
          </Modal.Body>
        </Modal.Container>
      </>
    ));
  }
}

export default GeotrackModalsFacadeService;
