import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Select, SelectProps} from '@innowise-group/ui-kit';
import {useTranslation} from 'react-i18next';
import {Employee, useService, EmployeesApiService, useDebounce} from '@innowise-group/core';

const EmployeesSelect: React.FC<SelectProps> = (props) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeName, setEmployeeName] = useState('');
  const employeesApi = useService(EmployeesApiService);

  const name = useDebounce(employeeName, 500);
  const {t} = useTranslation();

  useEffect(() => {
    if (name.length > 2) {
      const subscriber = employeesApi.getEmployees(name).subscribe(({employees}) => setEmployees(employees));
      return () => subscriber.unsubscribe();
    } else {
      setEmployees([]);
    }
  }, [name, employeesApi]);

  const valueTransform = useCallback(
    (id: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        if (!id) {
          reject('No ID provided for valueTransform function');
        } else {
          const employeeById = employees.find((employee) => employee.id === id);
          if (employeeById) {
            resolve(employeeById.fullName);
          } else {
            employeesApi.getEmployeeById(id).subscribe((employee) => {
              resolve(employee.fullName);
            });
          }
        }
      });
    },
    [employees, employeesApi],
  );

  const options = useMemo(
    () => employees.map(({id, fullName, firstName, lastName}) => ({value: id, title: fullName, firstName, lastName})),
    [employees],
  );

  return (
    <Select
      {...props}
      onChangeSearchValue={setEmployeeName}
      asyncValueTransformer={valueTransform}
      options={options}
      onNoValueFoundText={t('modals.noFoundEmployee')}
      clearable
    />
  );
};

export default EmployeesSelect;
