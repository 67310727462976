import {styled} from '../theme-provider';

type IconContainerProps = {background: string; size: number; isPointer: boolean; isHover: boolean};
export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({background}) => (background ? background : 'transparent')};
  border-radius: 50%;
  width: ${({size}) => `${size}px`};
  height: ${({size}) => `${size}px`};
  cursor: ${({isPointer}) => (isPointer ? 'pointer' : 'inherit')};
  margin: 0 3px 0 0;

  svg {
    &:hover {
      fill: ${({theme, isHover}) => isHover && theme.palette.general.darkGrey[80]};
    }
  }

  @media (max-width: 481px) {
    width: ${({size}) => `${size - 4}px`};
    height: ${({size}) => `${size - 4}px`};
    margin: 0 3px;
  }
`;
