import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Select, SelectProps} from '@innowise-group/ui-kit';

interface WorkspaceStatusSelectProps extends SelectProps {
  isVirtual: boolean;
  excludeFree?: boolean;
  excludeCoworking?: boolean;
}
const WorkspaceStatusSelect: React.FC<WorkspaceStatusSelectProps> = ({
  isVirtual,
  excludeFree,
  excludeCoworking,
  ...props
}) => {
  const {t} = useTranslation();

  const items = useMemo(() => {
    let statuses = [
      {value: 'Booked', title: t('statuses.booked')},
      {value: 'Reserved', title: t('statuses.reserved')},
      {value: 'Occupied', title: t('statuses.occupied')},
      {value: 'Coworking', title: t('statuses.coworking')},
    ];

    if (!excludeFree) statuses.unshift({value: 'Free', title: t('statuses.free')});

    if (excludeCoworking) statuses = statuses.filter((item) => item.value !== 'Coworking');

    return isVirtual ? [...statuses, {value: 'Remote', title: t('statuses.remote')}] : statuses;
  }, [isVirtual, t, excludeFree, excludeCoworking]);

  const transformValue = (value) => {
    return items.find((item) => item.value === value)?.title;
  };

  return <Select {...props} options={items} withSearch={false} valueTransformer={transformValue} />;
};

export default WorkspaceStatusSelect;
