import {Controller, useFormContext} from 'react-hook-form';
import {Dispatch, useCallback, useEffect, useRef} from 'react';

import {Form, withForm, WithFormProps} from '@innowise-group/ui-kit';
import {OfficesSelect} from '@shared-components';

import * as Styled from './search-floor-form.styles';

export enum SearchFloorFormFields {
  Country = 'country',
  Address = 'address',
  Floor = 'floor',
}

export type SearchFloorValues = {
  country: string;
  address: string;
  floor: string;
};

export interface SearchFloorFormProps extends WithFormProps<SearchFloorValues> {
  onAddressChanged?: Dispatch<string | undefined>;
}

const SearchFloorForm: React.FC<SearchFloorFormProps> = ({
  onSubmit,
  initialValues = {},
  setIsWatchFieldChanged,
  isWatchFieldChanged,
  onAddressChanged,
}) => {
  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
    resetField,
    reset,
    setValue,
  } = useFormContext();

  const {country, address, floor} = initialValues;
  const countryRef = useRef(country);
  const addressRef = useRef(address);

  useEffect(() => {
    setIsWatchFieldChanged({
      [SearchFloorFormFields.Address]: false,
      [SearchFloorFormFields.Floor]: false,
    });
  }, [setIsWatchFieldChanged]);

  useEffect(() => {
    const subscription = watch((values: SearchFloorValues, {type}) => {
      if (type) {
        if (countryRef.current !== values.country) {
          resetField(SearchFloorFormFields.Address);
          resetField(SearchFloorFormFields.Floor);
          countryRef.current = values.country;
          setIsWatchFieldChanged({
            [SearchFloorFormFields.Address]: true,
            [SearchFloorFormFields.Floor]: true,
          });
        } else if (addressRef.current !== values.address) {
          resetField(SearchFloorFormFields.Floor);
          addressRef.current = values.address;
          setIsWatchFieldChanged({
            [SearchFloorFormFields.Floor]: true,
          });
        } else {
          setIsWatchFieldChanged({
            [SearchFloorFormFields.Address]: false,
            [SearchFloorFormFields.Floor]: false,
          });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, resetField, setIsWatchFieldChanged, onSubmit]);

  useEffect(() => {
    setValue(SearchFloorFormFields.Country, country);
    setValue(SearchFloorFormFields.Address, address);
    setValue(SearchFloorFormFields.Floor, floor);
  }, [address, country, floor, reset, watch, setValue]);

  const onSubmitHandler = useCallback(
    (value: SearchFloorValues) => {
      onSubmit(value);
    },
    [onSubmit],
  );

  useEffect(() => {
    const subscription = watch((values: SearchFloorValues) => {
      if (typeof onAddressChanged === 'function') {
        onAddressChanged(values.address);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, onAddressChanged]);

  return (
    <Form.Container onSubmit={handleSubmit(onSubmitHandler)}>
      <Styled.FormLayout layoutType="horizontal" noPadding>
        <Controller
          name={SearchFloorFormFields.Country}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[SearchFloorFormFields.Country]}>
              <Styled.LocationsSelect value={value} onValueChange={onChange} />
            </Form.Field>
          )}
        />

        <Controller
          name={SearchFloorFormFields.Address}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[SearchFloorFormFields.Address]}>
              <OfficesSelect
                locationId={watch(SearchFloorFormFields.Country)}
                toUseDefaultValue={false}
                disabled={!watch(SearchFloorFormFields.Country)}
                onValueChange={onChange}
                value={value}
                isFieldChanged={isWatchFieldChanged[SearchFloorFormFields.Address]}
              />
            </Form.Field>
          )}
        />
      </Styled.FormLayout>
    </Form.Container>
  );
};

export default withForm(SearchFloorForm);
