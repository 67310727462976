import {appearAnimation} from '../global-styles';
import {styled, css} from '../theme-provider';
import {Typography} from '../typography';
import {NotificationType} from './notification.types';

export const NotificationsContainer = styled.div`
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1000;

  & > * {
    margin-bottom: 10px;
  }
`;

interface NotificationContainerProps {
  containerType: NotificationType;
}
export const NotificationContainer = styled.div<NotificationContainerProps>`
  border-radius: 10px;
  border: 1px solid;
  min-height: 76px;
  min-width: 380px;
  max-width: 380px;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  animation: ${appearAnimation} 0.175s ease-in-out;

  ${({
    theme: {
      palette: {state},
    },
    containerType,
  }) => {
    const colorsMap: Record<NotificationType, {background: string; border: string}> = {
      error: {
        background: state.error.background,
        border: state.error.default,
      },
      warning: {
        background: state.warning.background,
        border: state.warning.default,
      },
      success: {
        background: state.success.background,
        border: state.success.default,
      },
    };

    return css`
      background-color: ${colorsMap[containerType].background};
      border-color: ${colorsMap[containerType].border};
    `;
  }}
`;

export const NotificationTitleContainer = styled.div`
  display: flex;
`;

export const NotificationContentContainer = styled.div`
  flex: 1;
  display: flex;
  margin-left: 10px;
  flex-direction: column;
`;

export const NotificationTitle = styled(Typography)`
  font-weight: bold;
  flex: 1;
  margin-bottom: 20px;
`;
