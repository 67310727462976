import {map, Observable, tap} from 'rxjs';
import {notificationsManager} from '@innowise-group/ui-kit';
import {
  WorkspacesRequestsResponse,
  RequestsApi,
  WorkspaceRequestActions,
  CreateWorkspaceRequestPayload,
  EditWorkspaceRequestPayload,
  ApproveWorkspaceRequestPayload,
} from './requests-api.types';
import {Http, inject, injectable, GlobalContainerTypes, Employee, Request, RequestStatus} from '../..';
import {RequestsNormalization} from '../requests-normalization';

@injectable()
class RequestsApiService implements RequestsApi {
  public static readonly type = GlobalContainerTypes.RequestsApi;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(GlobalContainerTypes.RequestsNormalization) private requestsNormalization: RequestsNormalization;

  public getWorkspacesRequestsList(locationId?: string, officeId?: string): Observable<Request[]> {
    const params = new URLSearchParams('');
    if (!locationId && officeId) {
      params.append('workspace__room__floor__office_id', officeId);
    }
    if (locationId && !officeId) {
      params.append('workspace__room__floor__office__location_id', locationId);
    }
    if (locationId && officeId) {
      params.append('workspace__room__floor__office_id', officeId);
      params.append('workspace__room__floor__office__location_id', locationId);
    }
    return this.http
      .GET<WorkspacesRequestsResponse[]>(`/workspaces_requests/?${params}`)
      .pipe(map((list) => list.map(this.requestsNormalization.normalizeWorkspaceRequestFromApi)));
  }

  public getRequestsListWorkspacesCount(): Observable<number> {
    return this.http.GET<{count: number}>(`/workspaces_requests/requests-count/`).pipe(map(({count}) => count));
  }

  public getWorkspaceRequestById(requestId: string): Observable<Request> {
    return this.http
      .GET<WorkspacesRequestsResponse>(`/workspaces_requests/${requestId}/`)
      .pipe(map(this.requestsNormalization.normalizeWorkspaceRequestFromApi));
  }

  public workspaceApproveRequest(
    requestId: string,
    payload: ApproveWorkspaceRequestPayload,
  ): Observable<{status: RequestStatus; officeManager: Employee}> {
    return this.http
      .PATCH<WorkspaceRequestActions, ApproveWorkspaceRequestPayload>(
        `/workspaces_requests/${requestId}/approve-request/`,
        payload,
      )
      .pipe(
        map(this.requestsNormalization.normalizeWorkspaceActionRequestFromApi),
        tap(() => {
          notificationsManager.success({
            title: 'success',
            subtitle: 'acceptedRequest',
            subtitleProps: {requestNumber: requestId},
          });
        }),
      );
  }

  public workspaceRejectRequest(
    requestId: string,
    comment: string,
  ): Observable<{status: RequestStatus; officeManager: Employee}> {
    return this.http
      .PATCH<WorkspaceRequestActions>(`/workspaces_requests/${requestId}/reject-request/`, {manager_comment: comment})
      .pipe(
        map(this.requestsNormalization.normalizeWorkspaceActionRequestFromApi),
        tap(() => {
          notificationsManager.success({
            title: 'success',
            subtitle: 'rejectedRequest',
            subtitleProps: {requestNumber: requestId},
          });
        }),
      );
  }

  public createWorkspaceRequest(request: CreateWorkspaceRequestPayload): Observable<unknown> {
    return this.http.POST(`/workspaces_requests/`, {...request}).pipe(
      tap<void>(() => {
        notificationsManager.success({title: 'success', subtitle: 'bookWorkspace'});
      }),
    );
  }

  public editWorkspaceRequest(requestId: string, request: EditWorkspaceRequestPayload): Observable<unknown> {
    return this.http.PATCH(`/workspaces_requests/${requestId}/`, {...request}).pipe(
      tap<void>(() => {
        notificationsManager.success({title: 'success', subtitle: 'updateWorkspaceRequest'});
      }),
    );
  }

  public deleteWorkspaceRequest(requestId: string): Observable<unknown> {
    return this.http.DELETE(`/workspaces_requests/${requestId}/`).pipe(
      tap<void>(() => {
        notificationsManager.success({title: 'success', subtitle: 'deleteWorkspaceRequest'});
      }),
    );
  }

  public workspaceCanceledRequest(requestId: string): Observable<{status: RequestStatus; officeManager: Employee}> {
    return this.http.PATCH<WorkspaceRequestActions>(`/workspaces_requests/${requestId}/delay-request/`).pipe(
      map(this.requestsNormalization.normalizeWorkspaceActionRequestFromApi),
      tap(() => {
        notificationsManager.success({title: 'success', subtitle: 'canceledWorkspaceRequest'});
      }),
    );
  }
}

export default RequestsApiService;
