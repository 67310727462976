import {Checkbox, Form, Modal, withForm} from '@innowise-group/ui-kit';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {UploadFloorFormValues} from '../upload-floor-form';

export enum UploadFloorConfirmationFields {
  toSaveArrangements = 'toSaveArrangements',
}

type UploadFloorConfirmationProps = {
  onSubmit: (values: UploadFloorFormValues) => void;
  initialValues: {
    toSaveArrangements: boolean;
    confirmationText: string;
    cancelText: string;
    onCancel: () => void;
  };
};

const UploadFloorConfirmationForm = ({onSubmit, initialValues}: UploadFloorConfirmationProps) => {
  const {toSaveArrangements, confirmationText, cancelText, onCancel} = initialValues;
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useFormContext();

  const {t} = useTranslation();

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      <Form.Layout layoutType="vertical">
        <Controller
          defaultValue={toSaveArrangements}
          name={UploadFloorConfirmationFields.toSaveArrangements}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[UploadFloorConfirmationFields.toSaveArrangements]}>
              <Checkbox checked={value} onChange={onChange} position="left">
                {t('modals.saveArrangements')}
              </Checkbox>
            </Form.Field>
          )}
        />
        <Modal.Actions
          noVerticalPadding={true}
          actions={[
            {
              title: confirmationText || 'Approve',
              onClick: () => {
                handleSubmit(onSubmit)();
                onCancel();
              },
              highlighted: true,
              type: 'button',
            },
            {
              title: cancelText || 'Cancel',
              onClick: () => {
                onCancel();
              },
              type: 'button',
            },
          ]}
        />
      </Form.Layout>
    </Form.Container>
  );
};

export default withForm(UploadFloorConfirmationForm);
