import {styled, css} from '../theme-provider';
import {Link} from 'react-router-dom';
import {Typography} from '../typography';
import {Icon} from '@innowise-group/ui-kit';
import {IconContainer} from '@shared/ui-kit/icon/icon.styles';

const NAVIGATION_ICON_CONTAINER_WIDTH = 60;
const NAVIGATION_BAR_WIDTH = 240;
const NAVIGATION_ICON_CONTAINER_TABLET_WIDTH = 30;

interface NavigationBarContainerProps {
  collapsed: number;
}
export const NavigationBarContainer = styled.aside<NavigationBarContainerProps>`
  width: ${(props) => (props.collapsed ? NAVIGATION_ICON_CONTAINER_WIDTH : NAVIGATION_BAR_WIDTH)}px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.09);
  background-color: ${({theme}) => theme.palette.generalAlt.background.primary};
  transition: 0.2s;
  position: relative;

  ${({collapsed}) => {
    return css`
      p {
        display: ${collapsed ? 'none' : 'block'};
      }
    `;
  }};

  @media (max-width: 993px) {
    width: ${(props) => (props.collapsed ? '0' : NAVIGATION_BAR_WIDTH)}px;
    opacity: ${(props) => (props.collapsed ? '0' : '1')};
    visibility: ${(props) => (props.collapsed ? 'hidden' : 'visible')};
  }
  @media (max-width: 481px) {
    width: ${(props) => (props.collapsed ? '0' : '100%')};
    opacity: ${(props) => (props.collapsed ? '0' : '1')};
    visibility: ${(props) => (props.collapsed ? 'hidden' : 'visible')};
    border-right: none;
  }
`;

export const NavigationBarContentContainer = styled.div`
  width: inherit;
`;

interface NavigationItemProps {
  selected: number;
}
export const NavigationItem = styled.div<NavigationItemProps>`
  display: flex;
  align-items: center;
  height: 60px;
  position: relative;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 500;

  ${({selected, theme}) => {
    return css`
      background-color: ${selected ? theme.palette.generalAlt.background.highlight : 'transparent'};

      & ${NavigationLinkText} {
        color: ${theme.palette.generalAlt.font[selected ? 'primary' : 'tertiary']};
      }
      &:hover {
        opacity: 0.8;

        ${IconItem} {
          svg {
            fill: ${!selected && theme.palette.general.smart};
          }
        }
      }
    `;
  }};
`;

export const NavigationLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const NavigationLinkText = styled(Typography)`
  white-space: nowrap;
`;

export const CollapseButton = styled.button<NavigationBarContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: -14px;
  height: 28px;
  width: 28px;
  border: 1px solid #e1e1e1;
  background-color: ${({theme}) => theme.palette.generalAlt.background.primary};
  border-radius: 50%;
  z-index: 1000;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);

  &:hover {
    background-color: ${({theme}) => theme.palette.general.lightGrey[60]};
  }

  @media (max-width: 993px) {
    display: none;
  }
  & > ${IconContainer} {
    margin: ${({collapsed}) => (collapsed ? '0' : '0 2px 0 0')};
  }
`;

export const NavigationItemSelectedIndicator = styled.div`
  width: 3px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 3px 3px 0;
  background-color: ${({theme}) => theme.palette.generalAlt.font.highlight};
`;

export const IconItem = styled(Icon)`
  width: ${NAVIGATION_ICON_CONTAINER_WIDTH}px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px 0 0;

  @media (max-width: 993px) {
    width: ${NAVIGATION_ICON_CONTAINER_TABLET_WIDTH}px;
    margin: 0 10px;
  }
`;

export const Count = styled(Typography)`
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
  position: absolute;
  right: 26px;

  @media (max-width: 993px) {
    right: 15px;
  }
  @media (max-width: 481px) {
    position: static;
    margin-left: 10px;
  }
`;
