import {Form, Icon, styled, Typography} from '@innowise-group/ui-kit';

export const FormField = styled(Form.Field)`
  position: relative;
`;

export const InfoButton = styled(Icon)`
  cursor: pointer;
  width: 30px;
`;

export const TooltipContainer = styled.div`
  width: 160px;
`;

export const Text = styled(Typography)`
  font-size: 10px;
  line-height: 14px;
`;

export const IconContainer = styled.div`
  display: inline-block;
`;
