import {HTMLAttributes, useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ButtonHelper} from '@shared-components';
import {FadeInOutView, Tooltip, useClickOutside, useTheme} from '@innowise-group/ui-kit';
import {WorkspaceBookType} from '@innowise-group/core';
import {getThemeByRequestType} from '@innowise-group/utilities';

import * as Styled from './statuses-legend.styles';

interface StatusesLegendProps extends HTMLAttributes<HTMLDivElement> {
  isVirtual: boolean;
}

const StatusesLegend: React.FC<StatusesLegendProps> = ({isVirtual, ...rest}) => {
  const [isShowStatuses, setIsShowStatuses] = useState(false);
  const statusesRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const {t} = useTranslation();

  const statuses: Array<WorkspaceBookType> = useMemo(() => {
    if (isVirtual) {
      return ['Remote'];
    }
    return ['Free', 'Booked', 'Reserved', 'Occupied', 'Coworking'];
  }, [isVirtual]);

  const showStatuses = useCallback(() => {
    setIsShowStatuses((value) => !value);
  }, []);

  const closeStatuses = useCallback(() => {
    setIsShowStatuses(false);
  }, []);

  useClickOutside(statusesRef, closeStatuses);

  return (
    <div ref={statusesRef} {...rest}>
      <Tooltip
        horizontal="left"
        vertical="bottom"
        active={isShowStatuses}
        content={
          <FadeInOutView>
            <Styled.StatusesContainer title={null}>
              {statuses.map((status) => {
                const color = getThemeByRequestType(status, theme);
                return (
                  <Styled.StatusContainer key={status}>
                    <Styled.StatusBlock>
                      <Styled.Divider color={color.default} />
                      <Styled.Status color={color.default}>{t(`statuses.${status.toLowerCase()}`)}</Styled.Status>
                    </Styled.StatusBlock>
                    <Styled.StatusDescription>
                      {t(`statuses.description.${status.toLowerCase()}`)}
                    </Styled.StatusDescription>
                  </Styled.StatusContainer>
                );
              })}
            </Styled.StatusesContainer>
          </FadeInOutView>
        }
      >
        {/* @todo Button color is missed in theme */}
        <ButtonHelper onClick={showStatuses} text={t('buttons.statuses')} icon="u_info-circle" color="#3179E3" />
      </Tooltip>
    </div>
  );
};

export default StatusesLegend;
