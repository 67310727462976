import {styled} from '@innowise-group/ui-kit';

// @todo Color #3179E3 is missed in day theme
export const ListContainer = styled.ul`
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border: solid 1px #3179e3;
  box-sizing: border-box;
  left: 24px;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  width: 46px;
  margin-top: -62px;

  &.disabled {
    border: ${({theme: {palette}}) => `solid 1px ${palette.main.background}`};
  }
`;

// @todo Colors #3179E3, #D6E4F9 are missed in day theme
export const ListItem = styled.li<{active?: boolean}>`
  align-items: center;
  background: ${({theme: {palette}, active}) => (active ? '#3179E3' : palette.generalAlt.background.primary)};
  border-bottom: ${({active}) => `solid ${active ? 2 : 1}px #D6E4F9`};
  border-top: ${({active}) => (active ? 'solid 1px #D6E4F9' : 'none')};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: center;
  width: 100%;
  user-select: none;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &.disabled {
    background: ${({theme: {palette}, active}) =>
      active ? palette.main.background : palette.generalAlt.background.primary};
    border-bottom: ${({theme: {palette}, active}) => `solid ${active ? 2 : 1}px ${palette.main.background}`};
    border-top: ${({theme: {palette}, active}) => (active ? `solid 1px ${palette.main.background}` : 'none')};
    cursor: not-allowed;

    & p {
      color: ${({theme: {palette}, active}) =>
        active ? palette.generalAlt.background.primary : palette.main.background};
    }
  }

  & p {
    color: ${({theme: {palette}, active}) => (active ? palette.generalAlt.background.primary : '#3179E3')};
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
`;
