import {ContainerModule, interfaces} from '@innowise-group/core';
import {EmployeesContainerTypes} from './empoloyees-ioc.types';
import {EmployeesModalsFacade, EmployeesModalsFacadeService} from './employees-modals-facade';

export const employeesContainer = new ContainerModule(
  (
    bind: interfaces.Bind,
    unbind: interfaces.Unbind,
    isBound: interfaces.IsBound,
    rebind: interfaces.Rebind,
    unbindAsync: interfaces.UnbindAsync,
    onActivation: interfaces.Container['onActivation'],
    onDeactivation: interfaces.Container['onDeactivation'],
  ) => {
    bind<EmployeesModalsFacade>(EmployeesContainerTypes.EmployeesModalsFacade).to(EmployeesModalsFacadeService);
  },
);
