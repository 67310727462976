import React, {useEffect, useState} from 'react';
import * as Styled from './fade-in-out-view.styles';

export interface FadeInOutViewProps extends React.HTMLAttributes<HTMLDivElement> {}

const FadeInOutView: React.FC<FadeInOutViewProps> = ({children, ...props}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted((value) => !value);
  }, []);

  return (
    <Styled.FadeInOutViewContainer style={{opacity: Number(isMounted)}} {...props}>
      {children}
    </Styled.FadeInOutViewContainer>
  );
};

export default FadeInOutView;
