import {StyledComponent} from 'styled-components';
import {DefaultTheme, GlobalThemeContext, Icon, useTheme} from '@innowise-group/ui-kit';
import * as Styled from './button.styles';
import {useContext, useMemo} from 'react';

export type ButtonVariant = 'normal' | 'outlined' | 'link' | 'text';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode | string;
  variant?: ButtonVariant;
  color?: string;
  type?: 'button' | 'submit' | 'reset';
  iconSize?: number;
  iconColor?: string;
  iconPosition?: 'left' | 'right';
  withLoader?: boolean;
}

const variantStyledMap: Record<ButtonVariant, StyledComponent<'button', DefaultTheme, {}, never>> = {
  normal: Styled.ButtonContainer,
  link: Styled.LinkContainer,
  outlined: Styled.OutlinedContainer,
  text: Styled.TextContainer,
};

const Button: React.FC<ButtonProps> = ({
  icon,
  iconSize,
  iconColor,
  type,
  variant = 'normal',
  color,
  children,
  withLoader = false,
  iconPosition = 'left',
  ...props
}) => {
  const theme = useTheme();
  const Container = variantStyledMap[variant];
  const {theme: themeContext} = useContext(GlobalThemeContext);

  const buttonColor = useMemo(() => {
    return themeContext === 'dark' && variant === 'outlined' ? theme.palette.general.darkGrey[100] : color;
  }, [themeContext, variant, color, theme]);

  return (
    <Container type={type} color={buttonColor} {...props}>
      {icon && iconPosition === 'left' ? (
        <Styled.IconContainer position={iconPosition}>
          {typeof icon === 'string' ? (
            <Icon type={icon} fill={iconColor || theme.palette.general.white} size={iconSize} />
          ) : (
            icon
          )}
        </Styled.IconContainer>
      ) : null}
      {children}
      {icon && iconPosition === 'right' ? (
        <Styled.IconContainer position={iconPosition}>
          {typeof icon === 'string' ? (
            <Icon type={icon} fill={iconColor || theme.palette.general.white} size={iconSize} />
          ) : (
            icon
          )}
        </Styled.IconContainer>
      ) : null}
      {withLoader && (
        <Styled.LoaderContainer>
          <Styled.ButtonLoader size={10} />
        </Styled.LoaderContainer>
      )}
    </Container>
  );
};

export default Button;
