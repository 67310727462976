import {modalManager} from '@innowise-group/ui-kit';
import {ReleaseNotes} from '@shared-components';
import {useCallback, useEffect, useState} from 'react';

interface UseReleaseNotesResult {
  modalsReadyCallback: () => void;
  open: () => void;
}

export default function useReleaseNotes(): UseReleaseNotesResult {
  const [areModalsReady, setAreModalsReady] = useState(false);

  const modalsReadyCallback = useCallback(() => setAreModalsReady(true), []);
  const open = useCallback(
    () => modalManager.open(({close}) => <ReleaseNotes close={close} version={process.env.REACT_APP_VERSION} />),
    [],
  );

  useEffect(() => {
    if (!areModalsReady) return;

    const shownReleaseNotes: {[x: string]: boolean} = JSON.parse(localStorage.getItem('release_notes')) ?? {};
    const version = process.env.REACT_APP_VERSION;

    if (!(version in shownReleaseNotes)) {
      open();

      shownReleaseNotes[version] = true;
      localStorage.setItem('release_notes', JSON.stringify(shownReleaseNotes));
    }
  }, [areModalsReady, open]);

  return {
    modalsReadyCallback,
    open,
  };
}
