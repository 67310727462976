import {Typography} from '../typography';
import {styled, css} from '../theme-provider';

export const FormContainer = styled.form`
  position: relative;
`;

interface FormLayoutProps {
  layoutType: 'horizontal' | 'grid' | 'vertical';
  noPadding?: boolean;
  inset?: 'top' | 'bottom';
}
export const FormLayout = styled.div<FormLayoutProps>`
  ${({layoutType}) => {
    switch (layoutType) {
      case 'grid':
        return css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 10px;
          grid-row-gap: 10px;

          @media (max-width: 769px) {
            grid-template-columns: 1fr;
          }
        `;

      case 'vertical':
        return css`
          display: flex;
          flex-direction: column;

          & > * {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
              margin-top: 12px;
            }
          }
        `;

      default:
        return css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          gap: 10px;

          @media (max-width: 991px) {
            margin-top: 0;
          }
        `;
    }
  }}
  ${({inset = 'bottom', noPadding}) =>
    inset === 'bottom'
      ? css`
          padding-bottom: ${noPadding ? 0 : '24px'};
          @media (max-width: 481px) {
            padding-bottom: ${noPadding ? 0 : '15px'};
          }
        `
      : css`
          padding-top: ${noPadding ? 0 : '20px'};
          @media (max-width: 481px) {
            padding-top: 0;
          }
        `}
`;

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  /* @media (max-width: 481px) {
    margin: 5px 0;
  } */
`;

export const FormFieldLabel = styled(Typography)`
  margin-bottom: 8px;
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
  font-size: 12px;
`;

export const FormFieldError = styled.span`
  color: ${({theme}) => theme.palette.state.error.default};
  position: relative;
  font-size: 12px;
`;

export const FormActionsContainer = styled.div`
  display: flex;
  padding-top: 15px;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 481px) {
    padding-top: 10px;
  }
`;
