import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {FieldValues, useFormContext, UseFormResetField} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Icon, Loader} from '..';
import {filteredSearchName, shortenText} from '../../utilities';
import * as Styled from './select.styles';
import {useClickOutside} from '../use-click-outside';
import {useCalculatePosition} from '../use-calculate-position';

export interface SelectOption {
  value: string;
  title: string;
  firstName?: string;
  lastName?: string;
}

enum SearchFormField {
  Country = 'country',
}

export interface SelectProps extends React.HTMLAttributes<HTMLDivElement> {
  options?: SelectOption[];
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  value?: string;
  leftIcon?: React.ReactNode;
  placeholder?: string;
  withSearch?: boolean;
  onNoValueFoundText?: string;
  onValueChange?: (value: string | string[]) => void;
  onChangeSearchValue?: (value: string) => void;
  asyncValueTransformer?: (value: string | undefined) => Promise<any>;
  valueTransformer?: (value: string | undefined) => string;
  resetField?: UseFormResetField<FieldValues>;
  clearable?: boolean;
  size?: 'default' | 'small' | 'extra-small' | 'large';
  isFieldChanged?: boolean;
  multiple?: boolean;
  position?: 'absolute' | 'fixed';
  isRedHeaderColor?: boolean;
  isReadChosenItemColor?: boolean;
  verticalDirection?: 'bottom' | 'top';
}

const Select: React.FC<SelectProps> = ({
  options = [],
  disabled,
  onChange,
  error,
  onClick,
  value,
  withSearch = true,
  placeholder = 'Select',
  leftIcon,
  resetField,
  onNoValueFoundText,
  errorText,
  onValueChange,
  onChangeSearchValue,
  asyncValueTransformer,
  valueTransformer,
  clearable = false,
  defaultValue,
  size = 'default',
  multiple = false,
  position = 'absolute',
  isRedHeaderColor = false,
  isReadChosenItemColor = false,
  verticalDirection: externalVerticalDirection,
  ...elementProps
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [transformedValue, setTransformedValue] = useState<string>();
  const [search, setSearch] = useState<string>('');
  const [selectedValueData, setSelectedValueData] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const dropDownListRef = useRef(null);
  const isMounted = useRef(false);
  const {register, setValue} = useFormContext();

  const positionOptions = useCalculatePosition(dropDownListRef, isOpen);
  const verticalDirection = externalVerticalDirection ?? positionOptions.verticalDirection;

  const {t} = useTranslation();

  const closeSelect = useCallback(() => {
    setIsOpen(false);
  }, []);

  useClickOutside(dropDownRef, closeSelect);

  useEffect(() => {
    setSearch('');
    setIsFocused(isOpen);
  }, [isOpen]);

  useEffect(() => {
    isMounted.current = true;
    if (valueTransformer) {
      setTransformedValue(shortenText(valueTransformer(value || (defaultValue as string)), size));
      if (!value) {
        setSelectedList([]);
      } else if (multiple) {
        setSelectedList(value.split(', '));
      }
      setIsLoading(false);
    } else if (asyncValueTransformer) {
      asyncValueTransformer(value)
        .then((value) => {
          if (isMounted.current) {
            setTransformedValue(shortenText(value, size));
            setSelectedValueData(value);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setSelectedValueData(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return () => {
        isMounted.current = false;
        setIsLoading(false);
      };
    } else {
      setTransformedValue(shortenText(value, size));
      setIsLoading(false);
    }
    return () => {
      isMounted.current = false;
      setIsLoading(false);
    };
  }, [asyncValueTransformer, valueTransformer, value, defaultValue, size, multiple]);

  useEffect(() => {
    register('selectedData');
    setValue('selectedData', selectedValueData);
  }, [register, setValue, selectedValueData]);

  const isOneOfficeExists = (options: SelectOption[]) => {
    return options.length === 1;
  };

  const onClearSelectValueClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (isOneOfficeExists(options)) {
      resetField && resetField(SearchFormField.Country);
    }
    setTransformedValue(undefined);
    onValueChange(undefined);
  };

  const handleInputFocus = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsOpen((value) => !value);
  };

  const handleOnChangeSearchValue = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = target.value;
    setSearch(newValue);
    if (onChangeSearchValue) {
      onChangeSearchValue(newValue);
    }
  };

  const onOptionClicked = (value: string) => {
    if (!multiple) {
      setIsOpen(false);
      onValueChange(value);
      return;
    }

    const isItemSelected = selectedList.find((item) => item === value);
    const newSelectedList = isItemSelected ? selectedList.filter((item) => item !== value) : selectedList.concat(value);
    setSelectedList(newSelectedList.length ? newSelectedList : []);
    onValueChange(newSelectedList.length ? newSelectedList : []);
  };

  const renderList = () => {
    const renderArray = options.filter((item) => {
      const fullEngName = item?.firstName && item?.lastName ? item.firstName + ' ' + item.lastName : null;

      return (
        !withSearch ||
        (item.title || '').toLowerCase().includes(filteredSearchName(search)) ||
        (item.title || '').split(' ').reverse().join(' ').toLowerCase().includes(filteredSearchName(search)) ||
        (fullEngName || '').toLowerCase().includes(filteredSearchName(search)) ||
        (fullEngName || '').split(' ').reverse().join(' ').toLowerCase().includes(filteredSearchName(search))
      );
    });

    return !renderArray?.length ? (
      <Styled.ListItem readonly>{onNoValueFoundText ? onNoValueFoundText : t('modals.noFound')}</Styled.ListItem>
    ) : (
      renderArray.map(({value: itemValue, title}) => {
        const handleSelect = () => {
          onOptionClicked(itemValue);
        };
        const isChecked = selectedList?.find((item) => item === itemValue);

        return multiple ? (
          <Styled.ListItemContainer key={itemValue} onClick={handleSelect}>
            <Styled.ListItem selected={itemValue === value}>{title}</Styled.ListItem>
            <input type="checkbox" readOnly checked={!!isChecked} />
          </Styled.ListItemContainer>
        ) : (
          <Styled.ListItem
            key={itemValue}
            onClick={handleSelect}
            redItemBackground={isReadChosenItemColor}
            selected={itemValue === value}
          >
            {title}
          </Styled.ListItem>
        );
      })
    );
  };

  const renderSelectHeader = () => {
    if (withSearch && isOpen) {
      return (
        <>
          <Styled.DropDownSearchInput
            autoFocus
            onChange={handleOnChangeSearchValue}
            placeholder={placeholder}
            value={search}
            disabled={disabled}
          />
          <Styled.ExpandIcon isHover isOpen={isOpen} type="u_angle-down" size={24} />
        </>
      );
    }

    return transformedValue ? (
      <Styled.ListItem readonly noPadding isHeader redItemColor={isRedHeaderColor}>
        {transformedValue}
        {clearable && (
          <Styled.ClearButton onClick={onClearSelectValueClick}>
            <Icon type="u_multiply" isPointer isHover />
          </Styled.ClearButton>
        )}
      </Styled.ListItem>
    ) : (
      <>
        <Styled.Placeholder>{placeholder}</Styled.Placeholder>
        <Styled.ExpandIcon isHover isOpen={isOpen} type="u_angle-down" size={24} />
      </>
    );
  };

  return (
    <Styled.Select {...elementProps}>
      <Styled.DropdownContainer ref={dropDownRef} size={size}>
        <Styled.DropdownHeader disabled={disabled} isFocused={isFocused} onClick={onClick || handleInputFocus}>
          {isLoading ? <Loader size={20} /> : renderSelectHeader()}
          {!clearable && <Styled.ExpandIcon isHover isOpen={isOpen} type="u_angle-down" size={24} />}
        </Styled.DropdownHeader>
        {isOpen && !disabled && (
          <Styled.DropdownListContainer ref={dropDownListRef} vertical={verticalDirection} position={position}>
            <Styled.DropdownList>{renderList()}</Styled.DropdownList>
          </Styled.DropdownListContainer>
        )}
      </Styled.DropdownContainer>
    </Styled.Select>
  );
};

export default memo(Select);
