import {styled, css} from '../theme-provider';
import {Loader} from '../loader';

export const ButtonContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme, color}) => color || theme.palette.general.smart};
  color: ${({theme}) => theme.palette.general.white};
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  border: none;
  transition: 0.2s;
  margin: 0;
  height: 40px;
  cursor: pointer;
  white-space: nowrap;
  width: 200px;

  &:hover {
    background-color: ${({theme}) => theme.palette.state.error.hover};
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @media (max-width: 993px) {
    margin: 0 0 10px 0;
  }
  @media (max-width: 481px) {
    font-size: 10px;
    height: 30px;
    padding: 0 10px;
    width: auto;
  }
`;

export const OutlinedContainer = styled(ButtonContainer)`
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;

  color: ${({theme, color}) => color || theme.palette.general.smart};

  &:hover {
    background-color: transparent;
  }

  border: 1px solid ${({theme, color}) => color || theme.palette.general.smart};
`;

export const LinkContainer = styled(ButtonContainer)`
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;

  border: 1px solid ${({theme, color}) => color || theme.palette.general.smart};
`;

export const TextContainer = styled(ButtonContainer)`
  border: none;
  color: ${({theme}) => theme.palette.general.smart};
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  &:hover {
    background-color: transparent;
  }
`;

export const IconContainer = styled.div<{position?: 'left' | 'right'}>`
  height: 100%;
  display: inline-flex;
  align-items: center;
  ${({position}) => {
    return css`
      ${position === 'left' ? 'margin-right: 10px;' : 'margin-left: 10px;'}
    `;
  }};

  @media (max-width: 481px) {
    margin-right: 5px;
  }
`;

export const LoaderContainer = styled(IconContainer)`
  margin-right: 0;
  margin-left: 10px;
`;

export const ButtonLoader = styled(Loader)`
  margin: 0;
`;
