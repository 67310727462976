import React, {useEffect, useState} from 'react';
import {Request, RequestsApiService, useService} from '@innowise-group/core';
import {Loader} from '@innowise-group/ui-kit';
import {SeatRequestProps} from './seat-request.component';

interface WithSeatRequestDetailsProps extends Omit<SeatRequestProps, 'data'> {}

function withSeatRequestDetails(WrappedComponent: React.FC<SeatRequestProps>) {
  return function ({...props}: WithSeatRequestDetailsProps) {
    const {requestId} = props;
    const requestApi = useService(RequestsApiService);
    const [request, setRequest] = useState<Request>(null);

    useEffect(() => {
      if (requestId) {
        const subscriber = requestApi.getWorkspaceRequestById(requestId).subscribe(setRequest);
        return () => subscriber.unsubscribe();
      }
    }, [requestId, requestApi]);

    if (!request) {
      return <Loader />;
    }

    return <WrappedComponent data={request} {...props} />;
  };
}

export default withSeatRequestDetails;
