import {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {GlobalThemeContextProvider, Loader} from '@innowise-group/ui-kit';
import {ContainerProvider, globalContainer} from '@innowise-group/core';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from '@shared-components';
import App from './app.component';
import './index.css';

import './i18n';

ReactDOM.render(
  <ContainerProvider container={globalContainer}>
    <GlobalThemeContextProvider>
      <ThemeProvider>
        <Suspense fallback={<Loader />}>
          <Router>
            <App />
          </Router>
        </Suspense>
      </ThemeProvider>
    </GlobalThemeContextProvider>
  </ContainerProvider>,
  document.getElementById('root'),
);
