import {
  Http,
  inject,
  injectable,
  GlobalContainerTypes,
  Office,
  OfficeDetails,
  Floor,
  OfficeStatisticResponse,
  OfficeStatistic,
  OfficeResponse,
} from '../..';
import {map, Observable, tap} from 'rxjs';
import {OfficesApi, DetailsListOfficeResponse, FloorStatisticResponse} from './offices-api.types';
import {AddOfficeFormValues} from '../../../../modules/offices/components/add-office-form';
import {notificationsManager} from '@innowise-group/ui-kit';
import {OfficesNormalization} from '../offices-normalization';

@injectable()
class OfficesApiService implements OfficesApi {
  public static readonly type = GlobalContainerTypes.OfficesApi;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(GlobalContainerTypes.OfficesNormalization) private officesNormalization: OfficesNormalization;

  public addOffice({
    location,
    address,
    address_eng,
    index,
    floors,
    managers,
    jira_office_id,
  }: AddOfficeFormValues): Observable<Office> {
    return this.http
      .POST<DetailsListOfficeResponse>('/offices/', {
        location: Number(location),
        address,
        address_eng,
        postcode: index,
        jira_office_id: Number(jira_office_id),
        floors_numbers: floors.map(({number, isVirtual}) => ({number, is_virtual: isVirtual})),
        manager: managers ?? [],
      })
      .pipe(
        map(({id, location, address, postcode}) =>
          this.officesNormalization.normalizeListOfficeFromApi(id, location, postcode, address, address_eng),
        ),
        tap(() => {
          notificationsManager.success({title: 'success', subtitle: 'addOffice'});
        }),
      );
  }

  public editOffice(
    officeId: string,
    {address, address_eng, floors, index, location, managers, jira_office_id}: AddOfficeFormValues,
    silent?: boolean,
  ): Observable<Office> {
    const observable = this.http
      .PUT<DetailsListOfficeResponse>(`/offices/${officeId}/`, {
        location: Number(location),
        address,
        address_eng,
        postcode: index,
        floors_numbers: floors.map(({number, isVirtual}) => ({number, is_virtual: isVirtual})),
        jira_office_id: Number(jira_office_id),
        manager: managers ?? [],
      })
      .pipe(
        map(({location, address, postcode}) =>
          this.officesNormalization.normalizeListOfficeFromApi(
            Number(officeId),
            location,
            postcode,
            address,
            address_eng,
          ),
        ),
      );

    return silent
      ? observable
      : observable.pipe(
          tap(() => {
            notificationsManager.success({title: 'success', subtitle: 'editOffice'});
          }),
        );
  }

  public getOfficeById(officeId: string): Observable<OfficeDetails> {
    return this.http
      .GET<OfficeResponse>(`/offices/${officeId}/`)
      .pipe(map(this.officesNormalization.normalizeOfficeInfoFromApi));
  }

  public getOffices(): Observable<Office[]> {
    return this.http
      .GET<DetailsListOfficeResponse[]>('/offices/office-statistic/')
      .pipe(
        map((list) =>
          list.map(
            ({
              id,
              location,
              address,
              address_eng,
              postcode,
              percent_of_free_workspaces,
              number_of_reserved_workspaces,
              number_of_remote_workspaces,
              number_of_booked_workspaces,
              number_of_occupied_workspaces,
              number_of_free_workspaces,
              has_faceid,
              jira_office_id,
            }) =>
              this.officesNormalization.normalizeListOfficeFromApi(
                id,
                location,
                postcode,
                address,
                address_eng,
                Math.round(
                  100 -
                    (number_of_reserved_workspaces ||
                    number_of_free_workspaces ||
                    number_of_remote_workspaces ||
                    number_of_booked_workspaces ||
                    number_of_occupied_workspaces
                      ? percent_of_free_workspaces
                      : 100),
                ) || 0,
                jira_office_id,
                has_faceid,
              ),
          ),
        ),
      );
  }

  public deleteOffice(officeId: string): Observable<void> {
    return this.http.DELETE(`/offices/${officeId}/`).pipe(
      tap<void>(() => {
        notificationsManager.success({title: 'success', subtitle: 'deleteOffice'});
      }),
    );
  }

  public getOfficeFloors(officeId: string): Observable<Floor[]> {
    return this.http.GET<FloorStatisticResponse>(`/offices/${officeId}/floors-statistic/`).pipe(
      tap((list) =>
        list.sort(
          (firstItem, secondItem) =>
            firstItem.number - secondItem.number || +firstItem.is_virtual - +secondItem.is_virtual,
        ),
      ),
      map(this.officesNormalization.normalizeOfficeFloorsFromApi),
    );
  }

  public getOverallStatisticOffices(): Observable<OfficeStatistic[]> {
    return this.http.GET<OfficeStatisticResponse[]>(`/offices/statistics/`).pipe(
      map((list) =>
        list.map(
          ({
            id,
            number_of_all_workspaces,
            number_of_free_workspaces,
            number_of_reserved_workspaces,
            number_of_occupied_workspaces,
            number_of_remote_workspaces,
            number_of_booked_workspaces,
          }) => ({
            id: String(id),
            workspacesCount: this.officesNormalization.normalizeWorkspacesCountFromApi(
              number_of_all_workspaces,
              number_of_free_workspaces,
              number_of_reserved_workspaces,
              number_of_booked_workspaces,
              number_of_occupied_workspaces,
              number_of_remote_workspaces,
            ),
          }),
        ),
      ),
    );
  }
}

export default OfficesApiService;
