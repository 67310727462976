import {ReactNode} from 'react';
import {useTheme} from '../theme-provider';
import {Button} from '../button';
import * as Styled from './modal.styles';

export interface ModalAction {
  title: string;
  onClick: () => void;
  highlighted?: boolean;
  icon?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
}

export interface ModalActionsProps {
  actions: ModalAction[];
  noVerticalPadding: boolean;
}

const ModalActions: React.FC<ModalActionsProps> = ({actions, children, noVerticalPadding}) => {
  const {
    palette: {
      general: {smart, darkGrey},
    },
  } = useTheme();

  return (
    <Styled.ModalActionsContainer noVerticalPadding={noVerticalPadding}>
      {actions
        ? actions.map(({onClick, title, icon, highlighted, type}) => (
            <Button
              key={title}
              type={type}
              onClick={onClick}
              icon={icon}
              variant={highlighted ? 'normal' : 'outlined'}
              color={highlighted ? smart : darkGrey[60]}
            >
              {title}
            </Button>
          ))
        : children}
    </Styled.ModalActionsContainer>
  );
};

export default ModalActions;
