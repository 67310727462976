import {Icon, Input, styled} from '@innowise-group/ui-kit';

export const FloorsContainerForm = styled.div`
  position: relative;
  width: fit-content;
  top: 2.5px;
`;

export const FloorsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
`;

export const Floor = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 60px;
  background-color: ${({theme}) => theme.palette.general.lightGrey[40]};
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  border-radius: 10px;
  padding: 5px 10px;
`;

export const FloorInput = styled(Input)`
  padding: 0 30px 0 20px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 481px) {
    padding: 0 30px 0 10px;
  }
`;

export const AddFloorIcon = styled(Icon)`
  position: absolute;
  right: 25px;
  top: 10px;

  @media (max-width: 481px) {
    top: 6px;
  }
`;
