import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Styled from './custom-date-picker.styles';
import ru from 'date-fns/locale/ru';
import {getLanguageFromLS} from '@innowise-group/utilities';
import {StyledComponent} from 'styled-components';
registerLocale('ru', ru);

export interface CustomDatePickerProps {
  selected?: Date | null | undefined;
  onChange: (value: Date | [Date, Date]) => void;
  excludeDateIntervals?: Array<{start: Date; end: Date}>;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  placeholderText?: string;
  isClearable?: boolean;
  disabled?: boolean;
  rangeType?: 'start' | 'end';
  popperPlacement?: any;
  open?: boolean;
  onInputClick?: () => void;
  onClickOutside?: () => void;
  startDate?: Date;
  endDate?: Date;
  selectsRange?: boolean;
  monthsShown?: number;
  zIndex?: number;
  disableKeyboardNavigation?: boolean;
  locale?: string;
  shouldCloseOnSelect?: boolean;
  peekNextMonth?: boolean;
  size?: 'default' | 'small' | 'extra-small' | 'large';
  Container?: StyledComponent<
    any,
    any,
    {disabled?: boolean; zIndex?: number; autoWidth?: boolean; size?: 'default' | 'small' | 'extra-small' | 'large'},
    never
  >;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  onChange,
  rangeType = 'start',
  size = 'default',
  zIndex,
  Container = Styled.DatePickerContainer,
  ...props
}) => {
  // const handleChange = (date) => {
  //   onChange(setHours(new Date(date), rangeType === 'start' ? 12 : 21));
  // };

  const onCalendarClose = props.selectsRange
    ? () => {
        const {startDate, endDate} = props;
        if (startDate && !endDate) onChange([startDate, startDate]);
      }
    : undefined;

  return (
    <Container disabled={props.disabled} zIndex={zIndex} autoWidth={props.monthsShown > 1} size={size}>
      <DatePicker {...props} onChange={onChange} locale={getLanguageFromLS()} onCalendarClose={onCalendarClose} />
      <Styled.CalendarIcon type="u_calender" />
    </Container>
  );
};

export default CustomDatePicker;
