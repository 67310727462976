import {styled, css} from '../theme-provider';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:disabled {
    opacity: 0.5;
  }
`;

export const StyledCheckbox = styled.div<{checked: boolean; position: 'left' | 'right'}>`
  display: inline-block;
  margin-right: ${({position}) => (position === 'left' ? '10px' : '0')};
  margin-left: ${({position}) => (position === 'left' ? '0' : '10px')};
  width: 20px;
  height: 20px;
  background: ${({checked, theme}) => (checked ? theme.palette.general.smart : theme.palette.general.paper)};
  border-radius: 3px;
  transition: all 150ms;
  border: 2px solid
    ${({checked, theme}) => (checked ? theme.palette.general.smart : theme.palette.general.lightGrey[100])};
  box-sizing: border-box;

  ${Icon} {
    visibility: ${({checked}) => (checked ? 'visible' : 'hidden')};
  }
`;

export const Label = styled.label<{position: 'left' | 'right'; disabled: boolean}>`
  display: flex;
  flex-direction: ${({position}) => (position === 'left' ? 'row' : 'row-reverse')};
  align-items: center;
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  ${({disabled}) => {
    return css`
      opacity: ${disabled ? 0.5 : 1};
    `;
  }}
`;
