import React, {useEffect} from 'react';
import {Form, withForm, WithFormProps} from '@innowise-group/ui-kit';
import {Controller, useFormContext} from 'react-hook-form';
import {RequestStatusSelect} from '../request-status-select';

export enum RequestStatusFormFields {
  RequestStatuses = 'requestStatuses',
}

export type RequestStatusFormValues = {
  requestStatuses: string | null;
};

const RequestStatusForm: React.FC<WithFormProps<RequestStatusFormValues>> = ({onSubmit}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useFormContext();

  useEffect(() => {
    const subscription = watch((values: RequestStatusFormValues, {type}) => {
      if (type) {
        onSubmit(values);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onSubmit]);

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      <Form.Layout layoutType="horizontal" noPadding>
        <Controller
          name={RequestStatusFormFields.RequestStatuses}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[RequestStatusFormFields.RequestStatuses]}>
              <RequestStatusSelect onValueChange={onChange} value={value} clearable />
            </Form.Field>
          )}
        />
      </Form.Layout>
    </Form.Container>
  );
};

export default withForm(RequestStatusForm);
