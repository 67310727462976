import {ContainerModule, interfaces} from '@innowise-group/core';
import {GeotrackApiService} from './geotrack-api';
import {GeotrackApi, GeotrackNormalization, GeotrackContainerTypes} from '../types';
import {GeotrackNormalizationService} from './geotrack-normalization';
import {GeotrackModalsFacade} from './geotrack-modals-facade/geotrack-modals-facade.types';
import {GeotrackModalsFacadeService} from './geotrack-modals-facade';

export const geotrackContainer = new ContainerModule(
  (
    bind: interfaces.Bind,
    unbind: interfaces.Unbind,
    isBound: interfaces.IsBound,
    rebind: interfaces.Rebind,
    unbindAsync: interfaces.UnbindAsync,
    onActivation: interfaces.Container['onActivation'],
    onDeactivation: interfaces.Container['onDeactivation'],
  ) => {
    bind<GeotrackApi>(GeotrackContainerTypes.Geotrack).to(GeotrackApiService);
    bind<GeotrackNormalization>(GeotrackContainerTypes.GeotrackNormalization).to(GeotrackNormalizationService);
    bind<GeotrackModalsFacade>(GeotrackContainerTypes.GeotrackModalsFacade).to(GeotrackModalsFacadeService);
  },
);
