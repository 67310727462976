import ConfirmationModal from '../modals/confirmation-modal/confirmation-modal.component';
import {ModalAction} from '../modal-actions.component';
import {Modal} from '..';

type CloseCallback = () => void;

interface ModalConfig {
  predefinedId?: string;
  onBackdropClick?: () => void;
}

export interface ModalProps {
  close: CloseCallback;
}

export interface ModalInstance {
  id: string;
  component: React.FC<ModalProps>;
  onBackdropClick?: CloseCallback;
}

class ModalManager {
  private dispatch: React.Dispatch<React.SetStateAction<ModalInstance[]>>;

  public open(node: React.FC<ModalProps>, config: ModalConfig = {}): CloseCallback {
    if (!this.dispatch) {
      throw new Error('Modal Container is not rendered yet');
    }

    const id = config.predefinedId ? config.predefinedId : Date.now().toString();
    this.dispatch((value) => [...value, {id, component: node, onBackdropClick: config.onBackdropClick}]);

    return () => {
      this.close(id);
    };
  }

  public close(id?: string): void {
    if (!this.dispatch) {
      throw new Error('Modal Container is not rendered yet');
    }

    this.dispatch((value) => (id ? value.filter((item) => item.id !== id) : value.slice(0, value.length - 1)));
  }

  public confirmationModal(
    {
      onComplete,
      ...confirmationModalConfig
    }: {
      text?: string;
      title?: string;
      confirmationText?: string;
      cancelText?: string;
      onApprove?: () => void;
      onComplete?: () => void;
    },
    config: ModalConfig = {},
  ): CloseCallback {
    return this.open(
      ({close}) => (
        <ConfirmationModal
          noVerticalPadding
          close={() => {
            close();
            if (onComplete) {
              onComplete();
            }
          }}
          {...confirmationModalConfig}
        />
      ),
      config,
    );
  }

  public baseModal(
    node: React.ReactNode,
    config: {title?: string; actions?: ModalAction[]; button?: string} = {},
  ): CloseCallback {
    return this.open(({close}) => (
      <Modal.Container>
        <Modal.Header title={config.title || 'Modal window'} onClose={close} />
        <Modal.Body>{node}</Modal.Body>
        <Modal.Actions
          noVerticalPadding
          actions={
            config.actions || [
              {
                title: `${config.button}`,
                onClick: () => {
                  close();
                },
              },
            ]
          }
        />
      </Modal.Container>
    ));
  }

  public setDispatch(dispatch: React.Dispatch<React.SetStateAction<ModalInstance[]>>) {
    this.dispatch = dispatch;
  }
}

export default ModalManager;
