import React, {useCallback, useImperativeHandle, useState} from 'react';
import {FieldValues, FormProvider, useForm} from 'react-hook-form';
import {Observable} from 'rxjs';

export interface WithFormProps<T = unknown> {
  onSubmit?: (values: T) => unknown | Observable<unknown>;
  onCancel?: () => void;
  initialValues?: Partial<T>;
  isSubmit?: boolean;
  isWatchFieldChanged?: {[key: string]: boolean};
  setIsWatchFieldChanged?: (value: {[key: string]: boolean}) => void;
}

export interface WithPropsHandle {
  submit: () => void;
}

// const convertOnSubmitToKnownTypes = (onSubmit: (values: unknown) => unknown | Observable<unknown>, values: unknown) => {
//   const submitResult = onSubmit(values);
//   if (submitResult instanceof Observable) {
//     return firstValueFrom(submitResult);
//   }
//
//   return submitResult;
// };

function withForm<OwnProps = {}>(WrappedComponent: React.FC<WithFormProps & OwnProps>) {
  return React.forwardRef<WithPropsHandle, WithFormProps & OwnProps>((props: WithFormProps & OwnProps, ref) => {
    const form = useForm<FieldValues>();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isWatchFieldChanged, setIsWatchFieldChanged] = useState<{[key: string]: boolean}>({});

    const onHandlerSubmit = useCallback(
      (value) => {
        const submitResult = props.onSubmit(value);
        if (submitResult instanceof Observable) {
          setIsSubmit(true);
          return submitResult.subscribe({
            next() {
              setIsSubmit(false);
              props.onCancel();
            },
            error() {
              setIsSubmit(false);
            },
          });
        }
        return submitResult;
      },
      [props],
    );
    // const convertedOnSubmit = (values: unknown) => convertOnSubmitToKnownTypes(props.onSubmit, values);

    useImperativeHandle(ref, () => ({
      submit: () => {
        // if (props.onSubmit) {
        //   form.handleSubmit(convertedOnSubmit(props.onSubmit))();
        // }
      },
    }));

    return (
      <FormProvider {...form}>
        <WrappedComponent
          {...props}
          onSubmit={onHandlerSubmit}
          isSubmit={isSubmit}
          isWatchFieldChanged={isWatchFieldChanged}
          setIsWatchFieldChanged={setIsWatchFieldChanged}
        />
      </FormProvider>
    );
  });
}

export default withForm;
