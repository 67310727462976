import {default as FormField} from './form-field.component';
import {default as FormErrorMessage} from './form-error-message.component';
import {FormActionsContainer, FormLayout, FormContainer} from './form.styles';

export const Form = {
  Container: FormContainer,
  Field: FormField,
  ErrorMessage: FormErrorMessage,
  Actions: FormActionsContainer,
  Layout: FormLayout,
};

export {default as withForm} from './with-form.hook';
export type {WithFormProps, WithPropsHandle} from './with-form.hook';
