import * as Styled from './progress-bar.styles';

interface ProgressBarProps {
  value: number;
  color?: string;
  size?: Styled.ProgressBarSize;
}

const ProgressBar: React.FC<ProgressBarProps> = ({value, color, size = 'medium'}) => {
  if (value < 0 || value > 100) {
    throw new Error('Value is not in range [0; 100]');
  }

  return (
    <Styled.ProgressBarContainer size={size}>
      <Styled.ProgressBlock progressValue={value} color={color} />
      <Styled.Title progressValue={value}>{value}%</Styled.Title>
    </Styled.ProgressBarContainer>
  );
};

export default ProgressBar;
