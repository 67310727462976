import {useEffect, useState} from 'react';

const requestIC: (cb: () => void) => number = window.requestIdleCallback ?? setTimeout;
const cancelIC = window.cancelIdleCallback ?? clearTimeout;

export default function useDeferredValue<T>(value: T): T {
  const [deferred, setDeferred] = useState(value);

  useEffect(() => {
    const id = requestIC(() => setDeferred(value));
    return () => cancelIC(id);
  }, [value]);

  return deferred;
}
