import {inject, injectable} from 'inversify';
import {map, Observable} from 'rxjs';
import {GlobalContainerTypes, Http} from '@innowise-group/core';
import {HistoryApi, HistoryResponse} from './history-api.types';
import {ChangeHistory} from '../../models/change-history.model';
import {HistoryContainerTypes} from '../history-ioc.types';
import {HistoryNormalization} from '../history-normalization';

@injectable()
class HistoryApiService implements HistoryApi {
  public static readonly type = HistoryContainerTypes.History;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(HistoryContainerTypes.HistoryNormalization) private historyNormalization: HistoryNormalization;

  public getChangeHistoryList(locationId?: string, officeId?: string): Observable<ChangeHistory[]> {
    const params = this.createUrlSearchParams(locationId, officeId);
    return this.http
      .GET<HistoryResponse[]>(`/logs/logs-per-two-months/?${params}`)
      .pipe(map((list) => list.map(this.historyNormalization.normalizeHistoryFromApi)));
  }

  public getChangeHistoryListToday(locationId?: string, officeId?: string): Observable<ChangeHistory[]> {
    const params = this.createUrlSearchParams(locationId, officeId);
    return this.http
      .GET<HistoryResponse[]>(`/logs/logs-per-day/?${params}`)
      .pipe(map((list) => list.map(this.historyNormalization.normalizeHistoryFromApi)));
  }

  public getChangeHistoryListForWeek(locationId?: string, officeId?: string): Observable<ChangeHistory[]> {
    const params = this.createUrlSearchParams(locationId, officeId);
    return this.http
      .GET<HistoryResponse[]>(`/logs/logs-per-week/?${params}`)
      .pipe(map((list) => list.map(this.historyNormalization.normalizeHistoryFromApi)));
  }

  public getChangeHistoryListForMonth(locationId?: string, officeId?: string): Observable<ChangeHistory[]> {
    const params = this.createUrlSearchParams(locationId, officeId);
    return this.http
      .GET<HistoryResponse[]>(`/logs/logs-per-month/?${params}`)
      .pipe(map((list) => list.map(this.historyNormalization.normalizeHistoryFromApi)));
  }

  private createUrlSearchParams(locationId?: string, officeId?: string): URLSearchParams {
    const params = new URLSearchParams('');
    if (locationId && !officeId) {
      params.append('room__floor__office__location__id', locationId);
    }
    if (locationId && officeId) {
      params.append('room__floor__office__location__id', locationId);
      params.append('room__floor__office_id', officeId);
    }
    return params;
  }
}

export default HistoryApiService;
