import {styled} from '../theme-provider';

export const RadioButtonContainer = styled.div`
  display: inline-block;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
`;

export const RadioButtonMark = styled.span`
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  box-sizing: border-box;
  border-radius: 50%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 50%;
    opacity: 0;
    left: 50%;
    top: 50%;
  }
`;

export const RadioButton = styled.input`
  display: none;

  &:disabled + ${RadioButtonMark} {
    opacity: 0.5;
  }

  &:checked + ${RadioButtonMark} {
    background-color: ${({theme}) => theme.palette.general.smart};
    border: 2px solid ${({theme}) => theme.palette.general.smart};

    &::after {
      width: 8px;
      height: 8px;
      background-color: ${({theme}) => theme.palette.general.paper};
      opacity: 1;
      left: 4px;
      top: 4px;
    }
  }
`;
