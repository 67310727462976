import {useTheme as styledUseTheme} from 'styled-components';
import {Theme} from './theme.model';

export * from './theme.model';
export {default as styled} from 'styled-components';
export * from 'styled-components';
export {default as GlobalThemeContextProvider} from './theme-provider.component';
export {GlobalThemeContext} from './theme-provider.component';

export function useTheme(): Theme {
  return styledUseTheme() as Theme;
}

export function lightTheme(): Theme {
  return {
    palette: {
      main: {
        background: '#DCEAFF',
        backgroundSecondary: '#B8D4FF',
        default: '#0B69FF',
        hover: '#0058E3',
        pressed: '#0055DC',
      },

      secondary: {
        background: '#f0dcfa',
        backgroundSecondary: '#e5c2fd',
        default: '#9757B0',
        hover: '#7B468E',
        pressed: '#643772',
      },

      info: {
        background: '#80bcf5',
        backgroundSecondary: '#5caefa',
        default: '#3EA2FF',
        hover: '#2c8ce5',
        pressed: '#1571c7',
      },

      generalAlt: {
        background: {
          primary: '#FFFFFF',
          secondary: '#F7F7F7',
          tertiary: '#FFFFFF',
          highlight: '#F5F5F5',
          highlightAlt: '#F5F8FE',
        },

        border: {
          primary: '#CFCFCF',
          secondary: '#E1E1E1',
          tertiary: '#CFCFCF',
        },

        rows: {
          odd: '#FFFFFF',
          even: '#FAFAFA',
        },

        scrollbar: {
          track: '#F7F7F7',
          thumb: '#C3C3C3',
        },

        input: {
          background: {
            primary: '#F7F7F7',
            secondary: '#F7F7F7',
          },
        },

        font: {
          primary: '#353535',
          secondary: '#4A4A4A',
          tertiary: '#767676',
          highlight: '#C63031',
          highlightAlt: '#055AA9',
        },

        blue: '#3EA2FF',
        red: '#DF4472',
        green: '#2AB0A8',
      },

      general: {
        darkGrey: {
          '100': '#353535',
          '90': '#494949',
          '80': '#5D5D5D',
          '60': '#868686',
          '40': '#AAAAAA',
          '20': '#F7F7F7',
        },

        lightGrey: {
          '100': '#C3C3C3',
          '90': '#C9C9C9',
          '80': '#CFCFCF',
          '60': '#DBDBDB',
          '40': '#F3F3F3',
          '20': '#FBFBFB',
        },

        green: {
          '100': '#086268',
          '90': '#098C84',
          '80': '#2AB0A8',
          '60': '#7FD0CB',
          '40': '#EAF7F6',
          '20': '#F4FBFB',
        },

        paper: 'white',

        white: '#FFFFFF',

        smart: '#C63031',

        lilac: '#9AB4E8',
      },

      specials: {
        workingDays: {
          confirmedOffice: '#2AB0A8',
          office: '#3179E3',
          outOfOffice: '#AEAEAE',
          remote: '#8B53E9',
          noInfo: '#000',
        },

        grades: {
          trainee: '#5D5D5D',
          intern: '#5D5D5D',
          junior: '#086268',
          middle: '#0B4174',
          senior: '#B14320',
          lead: '#9F2147',
          architect: '#9F2147',
        },

        managerialLevels: {
          p: {
            default: '#5D5D5D',
            background: '#EBEBEB',
          },
          m1: {
            default: '#098C84',
            background: '#EAF7F6',
          },
          m2: {
            default: '#DA5922',
            background: '#FEF2EC',
          },
          m3: {
            default: '#055AA9',
            background: '#EAF2FC',
          },
          m4: {
            default: '#6433B3',
            background: '#F2EAFF',
          },
        },

        avatars: {
          first: '#B3F4E1',
          second: '#B0CDFA',
          third: '#D5BBFF',
          fourth: '#FFBBD3',
          fifth: '#FFDCBB',
          sixth: '#FFF1BB',
          seventh: '#00B2CA',
          eights: '#0051CA',
          nines: '#4D00CA',
          tens: '#F13838',
          eleventh: '#D98304',
          twelfth: '#CA9F00',
        },

        timelines: {
          first: '#A9ECA3',
          second: '#B3F4E1',
          third: '#A4E3FE',
          fourth: '#AFC1FF',
          fifth: '#C3AEFF',
          sixth: '#FF9DE4',
          seventh: '#FF9D9D',
          eights: '#FFC28A',
          nines: '#FFD89D',
          tens: '#DDC196',
        },
      },

      state: {
        error: {
          default: '#F13838',
          pressed: '#D71414',
          hover: '#E42727',
          background: '#FFEDED',
          backgroundSecondary: '#FFBABA',
        },

        success: {
          default: '#2DCA73',
          pressed: '#1DB05F',
          hover: '#26BF6A',
          background: '#F0FFF7',
          backgroundSecondary: '#BAFFDA',
        },

        warning: {
          default: '#FFB800',
          pressed: '#EAA800',
          hover: '#F2AE00',
          background: '#FFF6E0',
          backgroundSecondary: '#FFECBF',
        },
      },
    },
  };
}

export function darkTheme(): Theme {
  return {
    palette: {
      main: {
        background: '#64b5f6',
        backgroundSecondary: '#42a5f5',
        default: '#1976d2',
        hover: '#1976d2',
        pressed: '#1565c0',
      },

      secondary: {
        background: '#b39ddb',
        backgroundSecondary: '#9575cd',
        default: '#7e57c2',
        hover: '#673ab7',
        pressed: '#5e35b1',
      },

      info: {
        background: '#6ea9e1',
        backgroundSecondary: '#589de0',
        default: '#3c88d2',
        hover: '#2375c5',
        pressed: '#1264b4',
      },

      generalAlt: {
        background: {
          primary: '#4A4A4A',
          secondary: '#353535',
          tertiary: '#5D5D5D',
          highlight: '#C63031',
          highlightAlt: '#3179E3',
        },

        border: {
          primary: '#767676',
          secondary: '#767676',
          tertiary: '#AEAEAE',
        },

        rows: {
          odd: '#4A4A4A',
          even: '#5D5D5D',
        },

        scrollbar: {
          track: '#353535',
          thumb: '#AEAEAE',
        },

        input: {
          background: {
            primary: '#767676',
            secondary: '#5D5D5D',
          },
        },

        font: {
          primary: '#FEFEFE',
          secondary: '#EBEBEB',
          tertiary: '#FAFAFA',
          highlight: '#FFFFFF',
          highlightAlt: '#FFFFFF',
        },

        blue: '#84AEEE',
        red: '#EC8FAA',
        green: '#7FD0CB',
      },

      general: {
        lightGrey: {
          '100': 'rgba(247,247,247,0.9)',
          '90': '#AAAAAA',
          '80': '#868686',
          '60': '#5D5D5D',
          '40': '#494949',
          '20': '#353535',
        },

        darkGrey: {
          '100': 'rgba(251,251,251,0.9)',
          '90': '#F3F3F3',
          '80': '#DBDBDB',
          '60': '#CFCFCF',
          '40': '#C9C9C9',
          '20': '#C3C3C3',
        },

        green: {
          '100': '#086268',
          '90': '#098C84',
          '80': '#2AB0A8',
          '60': '#7FD0CB',
          '40': '#EAF7F6',
          '20': '#F4FBFB',
        },

        paper: '#2E2E2E',

        white: '#FFFFFF',

        smart: '#C63031',
        lilac: '#7C92BE',
      },

      specials: {
        workingDays: {
          confirmedOffice: '#2AB0A8',
          office: '#3179E3',
          outOfOffice: '#AEAEAE',
          remote: '#8B53E9',
          noInfo: '#000',
        },

        grades: {
          trainee: '#FAFAFA',
          intern: '#FAFAFA',
          junior: '#FAFAFA',
          middle: '#FAFAFA',
          senior: '#FAFAFA',
          lead: '#FAFAFA',
          architect: '#FAFAFA',
        },

        managerialLevels: {
          p: {
            default: '#5D5D5D',
            background: '#EBEBEB',
          },
          m1: {
            default: '#098C84',
            background: '#EAF7F6',
          },
          m2: {
            default: '#DA5922',
            background: '#FEF2EC',
          },
          m3: {
            default: '#055AA9',
            background: '#EAF2FC',
          },
          m4: {
            default: '#6433B3',
            background: '#F2EAFF',
          },
        },

        avatars: {
          first: '#B3F4E1',
          second: '#B0CDFA',
          third: '#D5BBFF',
          fourth: '#FFBBD3',
          fifth: '#FFDCBB',
          sixth: '#FFF1BB',
          seventh: '#00B2CA',
          eights: '#0051CA',
          nines: '#4D00CA',
          tens: '#F13838',
          eleventh: '#D98304',
          twelfth: '#CA9F00',
        },

        timelines: {
          first: '#A9ECA3',
          second: '#B3F4E1',
          third: '#A4E3FE',
          fourth: '#AFC1FF',
          fifth: '#C3AEFF',
          sixth: '#FF9DE4',
          seventh: '#FF9D9D',
          eights: '#FFC28A',
          nines: '#FFD89D',
          tens: '#DDC196',
        },
      },

      state: {
        error: {
          default: '#d32f2f',
          pressed: '#c62828',
          hover: '#d02d2d',
          background: '#e57373',
          backgroundSecondary: '#ef5350',
        },

        success: {
          default: '#2e7d32',
          pressed: '#1b5e20',
          hover: '#2a772e',
          background: '#81c784',
          backgroundSecondary: '#4caf50',
        },

        warning: {
          default: '#ffb300',
          pressed: '#ff8f00',
          hover: '#ffa000',
          background: '#ffd54f',
          backgroundSecondary: '#ffca28',
        },
      },
    },
  };
}
