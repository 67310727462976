import {inject, injectable, GlobalContainerTypes, PlanElement, Language} from '../..';
import {FloorsNormalization} from './floors-normalization.types';
import {FloorPlan, RetrievePlan, ElementResponse, FloorResponse, RetrievePlanResponse} from '../floors-api';
import {EmployeesNormalization} from '../employees-normalization';
import {WorkspacesNormalization} from '../workspaces-normalization';

@injectable()
class FloorsNormalizationService implements FloorsNormalization {
  public static readonly type = GlobalContainerTypes.FloorsNormalization;

  @inject(GlobalContainerTypes.EmployeesNormalization) private employeesNormalization: EmployeesNormalization;
  @inject(GlobalContainerTypes.WorkspacesNormalization) private workspacesNormalization: WorkspacesNormalization;
  @inject(GlobalContainerTypes.Language) private language: Language;

  constructor() {
    this.normalizeRetrievePlanFromApi = this.normalizeRetrievePlanFromApi.bind(this);
    this.normalizeElement = this.normalizeElement.bind(this);
    this.normalizeFloor = this.normalizeFloor.bind(this);
  }

  public normalizeRetrievePlanFromApi({columns, ...rest}: RetrievePlanResponse): RetrievePlan {
    const normalizePlan = {};
    const key = Object.keys(rest);
    key.forEach((key) => {
      if (key === 'location') {
        normalizePlan[key] = String(rest[key]);
      } else if (key === 'floor') {
        normalizePlan[key] = this.normalizeFloor(rest[key], key);
      } else if (key === 'workspaces') {
        normalizePlan[key] = this.workspacesNormalization.normalizeWorkspaceFromApi(rest[key], key);
      } else {
        normalizePlan[key] = this.normalizeElement(rest[key], key);
      }
    });
    return normalizePlan as RetrievePlan;
  }

  public normalizeElement(elements: ElementResponse[], type: string): PlanElement[] {
    return elements.map((element) => ({
      type,
      id: element?.id && String(element?.id),
      floor: element?.floor && String(element?.floor),
      url: element?.layout,
      coordinates: element?.container_coordinates,
      number: element?.number,
      isOur: element?.our,
      rotateDegree: element?.rotate_deg,
      roomType: element?.room_type,
      workspaceCount: element?.workspace_count,
    }));
  }

  public normalizeFloor(floor: FloorResponse, type: string): FloorPlan {
    return {
      type,
      floorHeight: floor?.floor_height,
      floorWidth: floor?.floor_width,
      id: floor?.id && String(floor?.id),
      number: floor?.number && String(floor?.number),
      office: floor?.office && String(floor?.office),
      svgHeight: floor?.svg_height,
      svgWidth: floor?.svg_width,
      isVirtual: floor?.is_virtual,
      officeCity: this.language.checkAppLanguage() ? floor?.office_city : floor?.office_city_eng,
      officeCountry: floor?.office_country,
      officeAddress: this.language.checkAppLanguage() ? floor?.office_address : floor?.office_address_eng,
    };
  }
}

export default FloorsNormalizationService;
