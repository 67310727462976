import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {FloorsApiService, RetrievePlan, useService} from '@innowise-group/core';
import {RESIZE_OBSERVER_DESKTOP_SCREEN_SIZE, useResizeObserver} from '@innowise-group/ui-kit';
import {reloadPage} from '@innowise-group/utilities';

import {FloorElements} from '../../services/floors-data-manager/floors-data-manager.types';
import {FloorsModalsFacadeService} from '../../services/floors-modals-facade';
import {Room} from './use-floor-details.hook';
import {mapRoomsToElements, mapViewToElements, mapWorkspaceToElements} from '../../utilities/element-mapper.utility';

export interface FloorData {
  country: string;
  address: string;
  floor: string;
  isVirtual: boolean;
  fullAddress: string;
}

export interface UsePlanDetailsResult {
  floor: FloorData;
  isPlanLoading: boolean;
  setIsPlanLoading: (value: boolean) => void;
  isPlanExists: boolean;
  uploadPlanHandler: () => void;
}

export const usePlanDetails = (
  floorId: string,
  setRoomCoordinates: (value: React.SetStateAction<Room[]>) => void,
  initialize: (elements: FloorElements) => void,
  isViewMode: boolean,
): UsePlanDetailsResult => {
  const [floor, setFloor] = useState<FloorData>({} as FloorData);
  const [isPlanLoading, setIsPlanLoading] = useState(true);
  const [isPlanExists, setIsPlanExists] = useState<boolean>(false);
  const floorsApi = useService(FloorsApiService);
  const floorsModalsFacade = useService(FloorsModalsFacadeService);
  const {t} = useTranslation();
  const isDesktopView = useResizeObserver('application-container', RESIZE_OBSERVER_DESKTOP_SCREEN_SIZE);

  const mapPlanToElements = useCallback(
    (elements: Omit<RetrievePlan, 'location' | 'floor'>) => {
      const floorElements = {} as FloorElements;
      Object.keys(elements).forEach((key) => {
        if (key === 'workspaces') {
          floorElements[key] = mapWorkspaceToElements(elements[key], isViewMode);
        } else if (key === 'rooms') {
          floorElements[key] = mapRoomsToElements(elements[key], setRoomCoordinates, isViewMode, isDesktopView);
        } else {
          floorElements[key] = mapViewToElements(elements[key]);
        }
      });
      initialize(floorElements);
    },
    [initialize, isDesktopView, setRoomCoordinates, isViewMode],
  );

  const getFloorPlanById = useCallback(
    (floorId: string) => {
      return floorsApi.retrievePlan(floorId).subscribe({
        next({floor, location, ...rest}: RetrievePlan) {
          const {office, id, isVirtual, officeCity, officeCountry, officeAddress, number} = floor;
          const floorData: FloorData = {
            country: location,
            address: office,
            floor: id,
            isVirtual,
            fullAddress: `${officeCountry}, ${officeCity}, ${officeAddress}, ${t('pages.floors.floor')} ${number}`,
          };
          setFloor(floorData);
          mapPlanToElements(rest);
        },
        error() {
          setIsPlanExists(false);
          setIsPlanLoading(false);
        },
        complete() {
          setIsPlanExists(true);
          setIsPlanLoading(false);
        },
      });
    },
    [floorsApi, t, mapPlanToElements],
  );

  const fetchPlan = useCallback(() => {
    return getFloorPlanById(floorId);
  }, [getFloorPlanById, floorId]);

  useEffect(() => {
    const subscriber = fetchPlan();
    return () => {
      subscriber.unsubscribe();
    };
  }, [fetchPlan]);

  const openUploadModal = useCallback(
    (toSaveArrangements = true) => {
      floorsModalsFacade.openPlanUploadModal((values) => {
        setIsPlanLoading(true);
        floorsApi.uploadPlan(floorId, values, toSaveArrangements).subscribe(() => {
          fetchPlan();
          reloadPage();
        });
      }, t('modals.uploadPlan'));
    },
    [fetchPlan, floorId, floorsApi, floorsModalsFacade, t],
  );

  const handleUploadPlan = useCallback(() => {
    if (isPlanExists) {
      floorsModalsFacade
        .openUploadConfirmationModal(
          t('modals.uploadPlan'),
          t('modals.uploadPlanText'),
          t('buttons.upload'),
          t('buttons.cancel'),
        )
        .subscribe((toSaveArrangements) => openUploadModal(toSaveArrangements));
    } else {
      openUploadModal();
    }
  }, [floorsModalsFacade, isPlanExists, openUploadModal, t]);

  return {
    floor,
    isPlanLoading,
    setIsPlanLoading,
    isPlanExists,
    uploadPlanHandler: handleUploadPlan,
  };
};
