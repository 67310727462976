import React from 'react';
import {BookedStatus, RequestStatus, WorkspaceBookType} from '@innowise-group/core';
import {useTranslation} from 'react-i18next';
import {Icon, useTheme} from '@innowise-group/ui-kit';
import {getIconByRequestType, getThemeByRequestType} from '@innowise-group/utilities';
import * as Styled from './workspace-view.styles';
import WorkspaceBookingInfo from './workspace-booking-info.component';

interface WorkspaceEmployeesListProps {
  workspaces: BookedStatus[];
  status: WorkspaceBookType | RequestStatus;
}

const WorkspaceEmployeesList: React.FC<WorkspaceEmployeesListProps> = ({workspaces, status}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const color = getThemeByRequestType(status, theme);

  return (
    <>
      <Styled.WorkspaceStatusBlock>
        <Icon type={getIconByRequestType(status)} size={16} fill="white" background={color.default} />
        <Styled.WorkspaceStatusText type="h3" textColor={color.default}>
          {t(`statuses.${status.toLowerCase()}`)}
        </Styled.WorkspaceStatusText>
      </Styled.WorkspaceStatusBlock>
      {workspaces.map(({id, employee, range}) => (
        <WorkspaceBookingInfo key={id} employee={employee} range={range} />
      ))}
    </>
  );
};

export default React.memo(WorkspaceEmployeesList);
