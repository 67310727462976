import {RequestStatus} from '@innowise-group/core';

export const getIconByRequestType = (type: RequestStatus): string => {
  switch (type) {
    case 'approved':
      return 'u_check-circle';
    case 'in-clarification':
    case 'conflict':
      return 'u_clock-seven';
    case 'rejected':
      return 'u_exclamation-triangle';
    case 'delayed':
      return 'u_multiply';
    case 'canceled':
      return 'u_multiply';
  }
};
