import React from 'react';
import {ThemeProvider, lightTheme} from '../theme-provider';

const theme = lightTheme();

export const themeDecorator = [
  (Story: React.FC) => (
    <ThemeProvider theme={theme}>
      <Story />
    </ThemeProvider>
  ),
];
