import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {NodeComponentProps} from 'react-flow-renderer';
import {PlanElement} from '@innowise-group/core';
import * as Styled from './plan-element-view.styles';
import {RoomInfo} from '../room-info';
import stairs from '../../assets/stairs.png';
import lift from '../../assets/lift.png';
import balcony from '../../assets/balcony.png';
import {Room} from '../../pages/floor-details/use-floor-details.hook';
import {FloorContext} from '../floor-plan/floor-plan.component';

const getPlanElementSign = (type) => {
  switch (type) {
    case 'stairs':
      return <Styled.PlanElementSign src={stairs} alt="img" />;
    case 'balconies':
      return <Styled.PlanElementSign src={balcony} alt="img" />;
    case 'lifts':
      return <Styled.PlanElementSign src={lift} alt="img" />;
    default:
      return null;
  }
};

export interface PlanElementData extends PlanElement {
  setRoomCoordinates?: (value: React.SetStateAction<Room[]>) => void;
  isViewMode?: boolean;
}

const PlanElementView: React.FC<NodeComponentProps<PlanElementData>> = ({
  data: {url, number, setRoomCoordinates, id, coordinates, isViewMode, roomType, isOur, workspaceCount},
  selected,
  type,
}) => {
  const [width, setWidth] = useState<number>(null);
  const [height, setHeight] = useState<number>(null);
  const ref = useRef<HTMLImageElement>(null);
  const isSelectedRoom = useMemo(() => selected && type === 'rooms', [selected, type]);
  const isEditRoom = useMemo(() => isSelectedRoom && !isViewMode, [isSelectedRoom, isViewMode]);
  const {floorsEventEmitter} = useContext(FloorContext);

  useEffect(() => {
    const img = ref.current;
    img.onload = function () {
      setWidth(img.naturalWidth);
      setHeight(img.naturalHeight);
    };
  }, []);

  useEffect(() => {
    if (setRoomCoordinates) {
      const roomCoordinates = {
        x1: coordinates[0][0],
        x2: coordinates[0][0] + width,
        y1: coordinates[0][1],
        y2: coordinates[0][1] + height,
      };
      setRoomCoordinates((value) => [...value, {id, number, roomType, coordinates: roomCoordinates}]);
    }
  }, [coordinates, width, height, setRoomCoordinates, id, number, roomType]);

  const handlerEditRoom = useCallback(() => {
    floorsEventEmitter.emit('editRoom', id, roomType, number, isOur);
  }, [floorsEventEmitter, id, isOur, number, roomType]);

  const onDragStartHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <Styled.PlanElementViewContainer width={width} height={height} onDragStart={onDragStartHandler}>
      <Styled.Label selected={isSelectedRoom}>
        {number && (
          <RoomInfo
            id={id}
            workspaceCount={workspaceCount}
            roomType={roomType}
            label={number}
            isEditRoom={isEditRoom}
            isEditMode={!isViewMode}
            handlerEditRoom={handlerEditRoom}
            isOur={isOur}
          />
        )}
        {getPlanElementSign(type)}
      </Styled.Label>
      {type === 'rooms' ? (
        <Styled.RoomImage
          src={url}
          ref={ref}
          alt="img"
          crossOrigin="anonymous"
          selected={isSelectedRoom}
          isOur={isOur}
          isEditRoom={isEditRoom}
        />
      ) : type === 'doors' ? (
        <Styled.DoorImage src={url} ref={ref} alt="img" crossOrigin="anonymous" />
      ) : (
        <Styled.Image src={url} ref={ref} alt="img" crossOrigin="anonymous" />
      )}
    </Styled.PlanElementViewContainer>
  );
};

export default React.memo(PlanElementView);
