import {Icon} from '../icon';
import {styled} from '../theme-provider';

export const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 60px;
  background-color: ${({theme}) => theme.palette.general.lightGrey[40]};
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  border-radius: 10px;
  padding: 5px 5px 5px 10px;
  margin: 5px 10px;

  @media (max-width: 481px) {
    min-width: 30px;
    padding: 3px 6px;
    margin: 5px;
  }
`;
export const IconContainer = styled(Icon)`
  margin-left: 5px;
  padding-top: 2px;

  @media (max-width: 481px) {
    padding-top: 1px;
  }
`;
