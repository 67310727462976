import {useTranslation} from 'react-i18next';
import {Typography} from '@innowise-group/ui-kit';
import * as Styled from './no-matches.styles';

interface NoMatchesProps {
  helperText?: string;
}

const NoMatches: React.FC<NoMatchesProps> = ({helperText = 'helperText.noMatches'}) => {
  const {t} = useTranslation();

  return (
    <Styled.NoMatches>
      <Typography>{t(helperText)}</Typography>
    </Styled.NoMatches>
  );
};

export default NoMatches;
