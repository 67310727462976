import React, {useCallback} from 'react';
import {Select, SelectProps} from '@innowise-group/ui-kit';
import countries from './countries.json';
import {useTranslation} from 'react-i18next';
import {LanguageService, useService} from '@innowise-group/core';

const CountriesSelect: React.FC<SelectProps> = ({...props}) => {
  const {t} = useTranslation();
  const {checkAppLanguage} = useService(LanguageService);

  const transformValue = useCallback(
    (value: string): string => {
      const countryByValue = countries.find((country) => country.id === value);
      return countryByValue ? (checkAppLanguage() ? countryByValue.name : countryByValue.name_eng) : undefined;
    },
    [checkAppLanguage],
  );

  const normalizationCountries = useCallback(
    (countries) => {
      return countries
        .map(({id, name, name_eng}) => ({
          value: id,
          title: checkAppLanguage() ? name : name_eng,
        }))
        .sort((x, y) => x.title.localeCompare(y.title));
    },
    [checkAppLanguage],
  );

  return (
    <Select
      options={normalizationCountries(countries)}
      valueTransformer={transformValue}
      {...props}
      placeholder={t('placeholders.country')}
    />
  );
};

export default CountriesSelect;
