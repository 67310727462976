import {Button, styled} from '@innowise-group/ui-kit';

export const DropArea = styled.div`
  width: 100%;
  height: 100px;
  margin: 10px 0;
  border: ${({theme}) => `2px dashed ${theme.palette.general.lightGrey[60]}`};
  border-radius: 10px;
  background: ${({theme}) => theme.palette.general.lightGrey[40]};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const DropAreaInputFile = styled(DropArea)`
  visibility: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const PlanIcon = styled.div`
  margin-right: 10px;
`;

export const ClearIcon = styled.div`
  position: absolute;
  right: 20px;
  width: 16px;
  cursor: pointer;
`;

export const UploadButton = styled(Button)`
  margin: 0 auto;
`;
