import {NotificationType} from '../notification.types';

type CloseCallback = () => void;

export interface ModalProps {
  close: CloseCallback;
}

export interface NotificationInstance {
  id: string;
  title: string;
  type: NotificationType;
  subtitle?: string;
  isCustomNotification?: boolean;
  subtitleProps?: any;
}

interface NotificationPayload {
  title: string;
  subtitle?: string;
  statusCode?: string;
  isCustomNotification?: boolean;
  subtitleProps?: any;
}

class NotificationManager {
  private dispatch: React.Dispatch<React.SetStateAction<NotificationInstance[]>>;

  private add(config: NotificationPayload, type: NotificationType = 'success'): CloseCallback {
    if (!this.dispatch) {
      throw new Error('Notifications Container is not rendered yet');
    }

    const {title, subtitle, isCustomNotification, subtitleProps} = config;
    const id = Date.now().toString();
    this.dispatch((value) => [...value, {id, title, type, subtitle, isCustomNotification, subtitleProps}]);

    return () => {
      this.close(id);
    };
  }

  public success(config: NotificationPayload) {
    this.add(config, 'success');
  }

  public warning(config: NotificationPayload) {
    this.add(config, 'warning');
  }

  public error(config: NotificationPayload) {
    this.add(config, 'error');
  }

  public close(id: string): void {
    if (!this.dispatch) {
      throw new Error('Notifications Container is not rendered yet');
    }

    this.dispatch((value) => value.filter((item) => item.id !== id));
  }

  public setDispatch(dispatch: React.Dispatch<React.SetStateAction<NotificationInstance[]>>) {
    this.dispatch = dispatch;
  }
}

export default NotificationManager;
