import {Controller, useFormContext} from 'react-hook-form';
import {Form, Input, withForm, Button, WithFormProps, Divider} from '@innowise-group/ui-kit';
import {CountriesSelect} from '@shared-components';
import {useTranslation} from 'react-i18next';
import * as Styled from './add-location-form.styles';

export enum AddLocationFormFields {
  Country = 'country',
  City = 'city',
  CityEng = 'city_eng',
}

export interface AddLocationFormValues {
  [AddLocationFormFields.Country]: string;
  [AddLocationFormFields.City]: string;
  [AddLocationFormFields.CityEng]: string;
}

const AddLocationForm: React.FC<WithFormProps> = ({onSubmit, onCancel, isSubmit}) => {
  const {
    handleSubmit,
    control,
    register,
    formState: {errors},
  } = useFormContext();

  const {t} = useTranslation();

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      <Form.Layout layoutType="vertical">
        <Styled.LocationFieldForm>
          <Controller
            name={AddLocationFormFields.Country}
            control={control}
            rules={{required: {value: true, message: t('modals.messages.required')}}}
            render={({field: {onChange, value}}) => (
              <Form.Field label={`${t('modals.country')} *`} error={errors[AddLocationFormFields.Country]?.message}>
                <CountriesSelect value={value} onValueChange={onChange} position="fixed" />
              </Form.Field>
            )}
          />
        </Styled.LocationFieldForm>
      </Form.Layout>

      <Form.Layout layoutType="grid">
        <Form.Field label={`${t('modals.city')} (RU) *`} error={errors[AddLocationFormFields.City]?.message}>
          <Input
            {...register(AddLocationFormFields.City, {
              required: {value: true, message: t('modals.messages.required')},
            })}
            placeholder={t('placeholders.city')}
          />
        </Form.Field>
        <Form.Field label={`${t('modals.city')} (EN) *`} error={errors[AddLocationFormFields.CityEng]?.message}>
          <Input
            {...register(AddLocationFormFields.CityEng, {
              required: {value: true, message: t('modals.messages.required')},
            })}
            placeholder={t('placeholders.city')}
          />
        </Form.Field>
      </Form.Layout>

      <Divider />

      <Form.Actions>
        <Button type="submit" disabled={isSubmit} withLoader={isSubmit}>
          {t('buttons.save')}
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
      </Form.Actions>
    </Form.Container>
  );
};

export default withForm(AddLocationForm);
