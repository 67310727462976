import {Modal, modalManager} from '@innowise-group/ui-kit';
import {injectable} from '@innowise-group/core';
import {Observable} from 'rxjs';
import {ChooseOfficeFloorFormValues, ChooseOfficeFloorForm} from '@shared-components';
import {EmployeesModalsFacade} from './employees-modals-facade.types';
import {EmployeesContainerTypes} from '../empoloyees-ioc.types';

@injectable()
class EmployeesModalsFacadeService implements EmployeesModalsFacade {
  public static readonly type = EmployeesContainerTypes.EmployeesModalsFacade;

  public openChooseOfficeModal(title: string): Observable<unknown> {
    return new Observable((observer) => {
      modalManager.open(({close}) => {
        const handlerSubmit = (values: ChooseOfficeFloorFormValues) => {
          observer.next(values);
          observer.complete();
          close();
        };
        const handlerCancel = () => {
          observer.complete();
          close();
        };
        return (
          <Modal.Container>
            <Modal.Header title={title} onClose={close} />
            <Modal.Body>
              <ChooseOfficeFloorForm onCancel={handlerCancel} onSubmit={handlerSubmit} />
            </Modal.Body>
          </Modal.Container>
        );
      });
    });
  }
}

export default EmployeesModalsFacadeService;
