import {styled} from '@innowise-group/ui-kit';

export const VerticalContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const AppContainer = styled.section`
  flex: 1;
  display: flex;
  overflow: hidden;
  background-color: ${({theme}) => theme.palette.generalAlt.background.secondary};
`;

export const ContentContainer = styled.section`
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  margin: min(3vh, 40px) min(2vh, 24px) min(2vh, 20px) min(2.5vh, 34px);
  flex: 1;
  border-radius: 6px;
  background-color: ${({theme}) => theme.palette.generalAlt.background.primary};
  border: 1px solid ${({theme}) => theme.palette.generalAlt.border.primary};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);

  overflow: hidden auto;

  @media (max-width: 993px) {
    margin: 0;
  }
`;
