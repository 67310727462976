import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Form, Input, withForm, WithFormProps, Button, Divider, Typography} from '@innowise-group/ui-kit';
import {SelectFileForm} from '../select-file-form';

export enum FloorDimensionsFormFields {
  Length = 'floor_height',
  Width = 'floor_width',
}

export interface UploadFloorFormValues {
  plan: File;
  toSaveArrangements: boolean;
  [FloorDimensionsFormFields.Length]: string;
  [FloorDimensionsFormFields.Width]: string;
}

const UploadFloorForm: React.FC<WithFormProps<UploadFloorFormValues>> = ({onSubmit, onCancel}) => {
  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    getValues,
  } = useFormContext();

  const {t} = useTranslation();

  const handleSubmitData = (values) => {
    onSubmit(values);
    onCancel();
  };

  return (
    <Form.Container onSubmit={handleSubmit((data) => handleSubmitData(data))}>
      {!errors?.layout && getValues('layout')?.name && <Typography>{t('modals.uploadPlanSuccess')}</Typography>}
      <Form.Layout layoutType="vertical">
        {errors && errors?.layout && errors?.layout?.type === ('validate' as unknown) && (
          <Form.ErrorMessage>{errors.layout.message}</Form.ErrorMessage>
        )}
        <SelectFileForm />
      </Form.Layout>
      <Form.Layout layoutType="horizontal">
        <Controller
          name={FloorDimensionsFormFields.Length}
          control={control}
          rules={{
            required: {value: true, message: t('modals.messages.required')},
            min: {value: 1, message: t('modals.messages.positiveNumber')},
          }}
          render={({field: {onChange, value}}) => (
            <Form.Field
              label={t('modals.floorLength')}
              error={errors[FloorDimensionsFormFields.Length]?.message}
              errorType={errors[FloorDimensionsFormFields.Length]?.type as unknown as string}
            >
              <Input type="number" defaultValue={value} onChange={onChange} placeholder={t('modals.floorLength')} />
            </Form.Field>
          )}
        />
        <Controller
          name={FloorDimensionsFormFields.Width}
          control={control}
          rules={{
            required: {value: true, message: t('modals.messages.required')},
            min: {value: 1, message: t('modals.messages.positiveNumber')},
          }}
          render={({field: {onChange, value}}) => (
            <Form.Field
              label={t('modals.floorWidth')}
              error={errors[FloorDimensionsFormFields.Width]?.message}
              errorType={errors[FloorDimensionsFormFields.Width]?.type as unknown as string}
            >
              <Input type="number" defaultValue={value} onChange={onChange} placeholder={t('modals.floorWidth')} />
            </Form.Field>
          )}
        />
      </Form.Layout>

      <Divider />

      <Form.Actions>
        <Button type="submit">{isSubmitting ? 'Adding' : t('buttons.save')}</Button>
        <Button variant="outlined" onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
      </Form.Actions>
    </Form.Container>
  );
};

export default withForm(UploadFloorForm);
