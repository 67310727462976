import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '../typography';
import {Icon} from '../icon';
import * as Styled from './notification.styles';
import {NotificationType} from './notification.types';
import {Theme, useTheme} from '../theme-provider';

export interface NotificationProps {
  type?: NotificationType;
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  statusCode?: string;
  isCustomNotification?: boolean;
  subtitleProps?: any;
}

const getTextByType = (type: NotificationType, t: Function) => {
  return t(`notifications.titles.${type}`);
};

function getColorByRequestType(
  type: NotificationType,
  {
    palette: {
      state: {error, success, warning},
    },
  }: Theme,
): string {
  switch (type) {
    case 'success':
      return success.default;
    case 'warning':
      return warning.default;
    case 'error':
      return error.default;
  }
}

const getNotificationIconByType = (type: NotificationType) => {
  switch (type) {
    case 'error':
      return 'u_exclamation-triangle';
    case 'warning':
      return 'u_info-circle';
    default:
      return 'u_check';
  }
};

const Notification: React.FC<NotificationProps> = ({
  type = 'success',
  title,
  subtitle,
  onClose,
  statusCode = '',
  isCustomNotification = true,
  subtitleProps,
}) => {
  const theme = useTheme();
  const color = getColorByRequestType(type, theme);

  const {t} = useTranslation();

  return (
    <Styled.NotificationContainer containerType={type}>
      <Icon type={getNotificationIconByType(type)} fill="white" background={color} />
      <Styled.NotificationContentContainer>
        <Styled.NotificationTitleContainer>
          <Styled.NotificationTitle>
            {title ? t(`notifications.titles.${title}`, {statusCode}) : getTextByType(type, t)}!
          </Styled.NotificationTitle>
          <Icon type="u_multiply" onClick={onClose} isPointer isHover />
        </Styled.NotificationTitleContainer>
        {subtitle && isCustomNotification ? (
          <Typography>
            {subtitleProps ? t(`notifications.text.${subtitle}`, subtitleProps) : t(`notifications.text.${subtitle}`)}
          </Typography>
        ) : (
          <Typography>{subtitle}</Typography>
        )}
      </Styled.NotificationContentContainer>
    </Styled.NotificationContainer>
  );
};
export default Notification;
