import styled from 'styled-components';
import {Select} from '@innowise-group/ui-kit';
import {
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListContainer,
  ExpandIcon,
} from '../../ui-kit/select/select.styles';

export const LanguagesSelect = styled(Select)`
  ${DropdownContainer} {
    box-sizing: border-box;
    color: #5d5d5d;
    max-width: 90px;
    border: 1px solid #c3c3c3;
    height: 25.6px;
    display: flex;
    min-width: unset;
    align-items: center;
    font-weight: 600;
    border-radius: 12.8px;
    letter-spacing: 0.3px;
    @media (max-width: 769px) {
      max-width: 84px;
    }
    @media (max-width: 481px) {
      max-width: 74px;
    }
  }
  ${DropdownHeader} {
    width: 100%;
    padding: 0 26px 0 12px;
    border: none;
    height: 32px;
    border-radius: 8px;

    & > li {
      font-size: 12px;
      font-weight: normal;
      color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
      cursor: pointer;
    }

    @media (max-width: 769px) {
      position: static;
      padding-right: 2px;
    }
    @media (max-width: 481px) {
      height: 20px;
      & > li {
        font-size: 10px;
      }
    }
  }
  ${DropdownListContainer} {
    box-sizing: border-box;
    width: 100%;
    top: 85%;
    left: 3%;
  }
  ${DropdownList} {
    & > li {
      box-sizing: border-box;
      font-size: 12px;
    }

    @media (max-width: 481px) {
      & > li {
        font-size: 10px;
      }
    }
  }
  ${ExpandIcon} {
    right: 2px;
    margin: 0;
    @media (max-width: 769px) {
      right: 0px;
    }
  }
`;
