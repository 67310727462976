import {RoomType, Workspace} from '@innowise-group/core';

export const createEmptyElement = (
  isVirtual: boolean,
  x: number = 0,
  y: number = 0,
  rotateDegree: number = 0,
  room?: {id: string; number: string; room_type: RoomType},
): Workspace => {
  return {
    id: null,
    status: isVirtual ? 'Remote' : 'Free',
    width: 100,
    height: 50,
    rotateDegree: rotateDegree,
    coordinates: [[x + 50, y + 25]],
    room,
    number: null,
    employee: null,
    range: null,
    bookedStatus: [],
    comment: null,
    type: 'workspaces',
    activeRequests: [],
  };
};
