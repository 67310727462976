import {RefObject, useLayoutEffect, useState} from 'react';

export interface PositionOptions {
  horizontalDirection: string;
  verticalDirection: string;
}

const useCalculatePosition = (
  ref: RefObject<HTMLElement | null>,
  active: boolean,
  settings: PositionOptions = {
    horizontalDirection: 'left',
    verticalDirection: 'bottom',
  },
): PositionOptions => {
  const {horizontalDirection, verticalDirection} = settings;
  const [horizontal, setHorizontal] = useState<string>(horizontalDirection);
  const [vertical, setVertical] = useState<string>(verticalDirection);

  useLayoutEffect(() => {
    if (ref.current) {
      const coords = ref.current.getBoundingClientRect();
      const {width, left, bottom} = coords;
      setVertical(bottom >= window.innerHeight ? 'top' : 'bottom');
      setHorizontal(width < left ? 'right' : 'left');
    } else {
      setHorizontal(horizontalDirection);
      setVertical(verticalDirection);
    }
  }, [ref, active, horizontalDirection, verticalDirection]);

  return {horizontalDirection: horizontal, verticalDirection: vertical};
};

export default useCalculatePosition;
