import React, {useState, useEffect, useRef, useMemo} from 'react';
import * as Styled from './icon.styles';
import {useTheme} from '../theme-provider';

export interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  type: string;
  size?: number;
  fill?: string;
  background?: string;
  isPointer?: boolean;
  isHover?: boolean;
}

const Icon: React.FC<IconProps> = ({
  type,
  size = 20,
  fill,
  background = 'transparent',
  isPointer = false,
  isHover = false,
  ...props
}) => {
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const fillIcon = useMemo(() => {
    return fill ? fill : theme.palette.general.darkGrey[60];
  }, [fill, theme]);

  useEffect(() => {
    if (type) {
      setLoading(true);
      const importIcon = async () => {
        try {
          const {default: namedImport} = await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!../../../assets/icons/${type}.svg`
          );
          ImportedIconRef.current = namedImport;
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };

      importIcon();
      return () => setLoading(false);
    }
  }, [type]);

  if (!loading && ImportedIconRef.current) {
    const {current: ImportedIcon} = ImportedIconRef;
    return (
      <Styled.IconContainer background={background} size={size} isPointer={isPointer} isHover={isHover} {...props}>
        <ImportedIcon width={size} height={size} fill={fillIcon} />
      </Styled.IconContainer>
    );
  }

  return null;
};

export default Icon;
