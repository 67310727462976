import {redirectToHRMProfile} from '@innowise-group/utilities';
import {useState} from 'react';
import * as Styled from './avatar.styles';
import unknownUserLogo from '../../../assets/icons/unknown-user-logo.svg';

interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  badge?: React.ReactNode;
  hrmId?: string;
  imageUrl: string;
  size?: number;
  withTooltip?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({imageUrl, hrmId, badge = null, size = 40, withTooltip = false, ...props}) => {
  const handleAvatarClick = () => {
    hrmId && redirectToHRMProfile(hrmId);
  };

  const [source, setSource] = useState(() => imageUrl);

  const onError = () => setSource(unknownUserLogo);

  return (
    <Styled.AvatarWrapper onClick={handleAvatarClick} {...props} withTooltip={withTooltip}>
      <Styled.Avatar onError={onError} src={source} style={{width: size, height: size}} />
      <Styled.BadgeContainer>{badge}</Styled.BadgeContainer>
    </Styled.AvatarWrapper>
  );
};

export default Avatar;
