import {modalManager, Modal, Typography} from '@innowise-group/ui-kit';
import {ApproveWorkspaceRequestPayload, Employee, injectable} from '@innowise-group/core';
import {Observable} from 'rxjs';
import {BookingWorkspaceForm, BookingWorkspaceValues, WorkspacesBookingList} from '@shared-components';
import {RequestsModalsFacade, NextValueType, EditRequestValueType} from './requests-modals-facade.types';
import {RequestsContainerTypes} from '../requests-ioc.types';
import {SeatRequest} from '../../components/seat-request';

@injectable()
class RequestsModalsFacadeService implements RequestsModalsFacade {
  public static readonly type = RequestsContainerTypes.RequestsModalsFacade;

  public openRequestDetailsModal(id: string, title?: string, person?: Employee): Observable<NextValueType> {
    return new Observable((observer) => {
      modalManager.open(({close}) => {
        const closeModal = () => {
          close();
        };
        return (
          <Modal.Container>
            <Modal.Header title={title} onClose={closeModal} />
            <Modal.Body>
              <SeatRequest
                approveRequest={(payload: ApproveWorkspaceRequestPayload) => {
                  observer.next({approved: payload});
                  observer.complete();
                  closeModal();
                }}
                rejectRequest={(comment) => {
                  observer.next({rejected: comment});
                  observer.complete();
                  closeModal();
                }}
                editRequest={(data: EditRequestValueType) => {
                  observer.next({editRequest: data});
                  observer.complete();
                  close();
                }}
                deleteRequest={() => {
                  observer.next({deleteRequest: 'deleteRequest'});
                  observer.complete();
                  closeModal();
                }}
                cancelRequest={() => {
                  observer.next({canceled: 'canceled'});
                  observer.complete();
                  closeModal();
                }}
                requestId={id}
                person={person}
                confirmationModal={this.openConfirmationModal.bind(this)}
              />
            </Modal.Body>
          </Modal.Container>
        );
      });
    });
  }

  public openEditRequestWorkspaceForm(
    handler?: (values?: BookingWorkspaceValues) => Observable<unknown>,
    initialValues?: Partial<BookingWorkspaceValues>,
    title?: string,
    submitButton?: string,
    employee?: Employee,
    id?: string,
    workspacesList?: WorkspacesBookingList,
  ): void {
    modalManager.open(({close}) => (
      <Modal.Container>
        <Modal.Header title={title} onClose={close} />
        <Modal.Body>
          <BookingWorkspaceForm
            submitButton={submitButton}
            initialValues={initialValues}
            onCancel={close}
            onSubmit={handler}
            employee={employee}
            confirmationModal={this.openConfirmationModal.bind(this)}
            withValidateBookedWorkspace
            editRequestId={id}
            workspacesList={workspacesList}
          />
        </Modal.Body>
      </Modal.Container>
    ));
  }

  public openConfirmationModal(
    title: string,
    text: string,
    confirmationText: string,
    cancelText: string,
    onComplete?: () => void,
  ): Observable<void> {
    return new Observable((observer) => {
      modalManager.confirmationModal({
        text,
        title,
        confirmationText,
        cancelText,
        onApprove: () => {
          observer.next();
        },
        onComplete: onComplete
          ? onComplete
          : () => {
              observer.complete();
            },
      });
    });
  }

  public openInfoModal(text: string, title: string, button: string): void {
    modalManager.baseModal(<Typography>{text}</Typography>, {title, button});
  }
}

export default RequestsModalsFacadeService;
