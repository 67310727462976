import {BehaviorSubject, combineLatest, map, Observable} from 'rxjs';
import {inject, injectable} from 'inversify';
import {Elements} from 'react-flow-renderer';
import {
  GlobalContainerTypes,
  RoomResponse,
  WorkspaceResponse,
  WorkspacesNormalization,
  FloorsNormalization,
} from '@innowise-group/core';
import {notificationsManager} from '@innowise-group/ui-kit';
import {PlanElementData} from '../../components/plan-element-vew';
import {WorkspaceElementData} from '../../components/workspace-view';
import {FloorsContainerTypes} from '../floors-ioc.types';
import {FloorElements, FloorsDataManager} from './floors-data-manager.types';
import {mapWorkspaceToElements} from '../../utilities/element-mapper.utility';

@injectable()
class FloorsDataManagerService implements FloorsDataManager {
  public static readonly type = FloorsContainerTypes.FloorsDataManager;
  @inject(GlobalContainerTypes.WorkspacesNormalization) private workspacesNormalization: WorkspacesNormalization;
  @inject(GlobalContainerTypes.FloorsNormalization) private floorsNormalization: FloorsNormalization;

  public workspaces$: BehaviorSubject<Elements<WorkspaceElementData>>;
  public rooms$: BehaviorSubject<Elements<PlanElementData>>;
  private stairs$: BehaviorSubject<Elements<PlanElementData>>;
  private balconies$: BehaviorSubject<Elements<PlanElementData>>;
  private lifts$: BehaviorSubject<Elements<PlanElementData>>;
  private doors$: BehaviorSubject<Elements<PlanElementData>>;
  // private columns$: BehaviorSubject<Elements<PlanElementData>>;

  public initialWorkspaces$: BehaviorSubject<Elements<WorkspaceElementData>>;
  public createdWorkspaces$: BehaviorSubject<Elements<WorkspaceElementData>>;
  public deletedWorkspaces$: BehaviorSubject<Elements<WorkspaceElementData>>;

  constructor() {
    this.workspaces$ = new BehaviorSubject([]);
    this.rooms$ = new BehaviorSubject([]);
    this.stairs$ = new BehaviorSubject([]);
    this.balconies$ = new BehaviorSubject([]);
    this.lifts$ = new BehaviorSubject([]);
    this.doors$ = new BehaviorSubject([]);
    // this.columns$ = new BehaviorSubject([]);

    this.initialWorkspaces$ = new BehaviorSubject([]);
    this.createdWorkspaces$ = new BehaviorSubject([]);
    this.deletedWorkspaces$ = new BehaviorSubject([]);

    this.createWorkspaceSubscription = this.createWorkspaceSubscription.bind(this);
    this.initialize = this.initialize.bind(this);
    this.setWorkspacesSubscription = this.setWorkspacesSubscription.bind(this);
    this.deleteWorkspaceSubscription = this.deleteWorkspaceSubscription.bind(this);
    this.editWorkspaceSubscription = this.editWorkspaceSubscription.bind(this);
    this.editRoomSubscription = this.editRoomSubscription.bind(this);
    this.errorSubscription = this.errorSubscription.bind(this);
    this.getNormalizeWorkspaceElement = this.getNormalizeWorkspaceElement.bind(this);
    this.getWorkspaceByIdSubscription = this.getWorkspaceByIdSubscription.bind(this);
  }

  public initialize(elements: FloorElements): void {
    Object.keys(elements).forEach((key) => {
      this[`${key}$`].next(elements[key]);
    });
    this.initialWorkspaces$.next(elements.workspaces);
  }

  public createWorkspaceSubscription(workspace: WorkspaceResponse, isViewMode: boolean, showNotification: boolean) {
    if (showNotification) {
      notificationsManager.success({title: 'success', subtitle: 'createWorkspace'});
    }
    const createdWorkspaceElement = this.getNormalizeWorkspaceElement([workspace], isViewMode);
    const workspacesElements = this.workspaces$.getValue();
    this.workspaces$.next([...workspacesElements, ...createdWorkspaceElement]);
    const createdWorkspacesElements = this.createdWorkspaces$.getValue();
    this.createdWorkspaces$.next([...createdWorkspacesElements, ...createdWorkspaceElement]);
  }

  public setWorkspacesSubscription(workspaces: WorkspaceResponse[] = [], isViewMode: boolean) {
    const workspacesElements = this.getNormalizeWorkspaceElement(workspaces, isViewMode);
    this.workspaces$.next(workspacesElements);
  }

  public deleteWorkspaceSubscription(
    workspace: WorkspaceResponse,
    isViewMode: boolean,
    workspaceId: string,
    showNotification: boolean,
  ): void {
    if (showNotification) {
      notificationsManager.success({title: 'success', subtitle: 'deleteWorkspace'});
    }
    const deletedWorkspaceElement = this.getNormalizeWorkspaceElement([{...workspace, id: +workspaceId}], isViewMode);
    const workspacesElements = this.workspaces$.getValue();
    const restElements = workspacesElements.filter((element) => element.data.id !== workspaceId);
    this.workspaces$.next(restElements);
    const deletedWorkspacesElements = this.deletedWorkspaces$.getValue();
    this.deletedWorkspaces$.next([...deletedWorkspacesElements, ...deletedWorkspaceElement]);
    const initialWorkspaces = this.initialWorkspaces$.getValue();
    const filteredInitialWorkspaces = initialWorkspaces.filter((element) => element.data.id !== workspaceId);
    this.initialWorkspaces$.next(filteredInitialWorkspaces);
  }

  public editWorkspaceSubscription(
    workspace: WorkspaceResponse,
    isViewMode: boolean,
    withNotification = false,
    showNotification: boolean,
  ): void {
    if (withNotification && showNotification) {
      notificationsManager.success({title: 'success', subtitle: 'updateWorkspace'});
    }
    const editedWorkspaceElement = this.getNormalizeWorkspaceElement([workspace], isViewMode)[0];
    const workspacesElements = this.workspaces$.getValue();
    const newWorkspacesElements = workspacesElements.map((workspace) =>
      workspace.data.id === editedWorkspaceElement.data.id ? editedWorkspaceElement : workspace,
    );
    this.workspaces$.next(newWorkspacesElements);
  }

  public editRoomSubscription(room: RoomResponse, showNotifications: boolean) {
    if (showNotifications) {
      notificationsManager.success({title: 'success', subtitle: 'editRoom'});
    }
    const roomElements = this.rooms$.getValue();
    const newRoomElements = roomElements.map(({id, data, ...rest}) =>
      id === `rooms-${room.id}`
        ? {id, data: {...data, number: room.number, roomType: room.room_type, isOur: room.our}, ...rest}
        : {id, data, ...rest},
    );
    this.rooms$.next(newRoomElements);
  }

  public errorSubscription(status_code: number, error: {[key: string]: any} | string, showNotification: boolean) {
    if (showNotification) {
      notificationsManager.error({
        title: `${status_code >= 400 ? 'statusTextError' : 'networkError'}`,
        subtitle: `${
          typeof error === 'object'
            ? Object.entries(error).reduce((accumulator, [key, value]) => accumulator + `${key}: ${value}`, '')
            : error
        }`,
        statusCode: `${status_code}`,
        isCustomNotification: false,
      });
    }
  }

  public getWorkspaceByIdSubscription(workspace: WorkspaceResponse, isViewMode: boolean) {
    const retrieveWorkspace = this.getNormalizeWorkspaceElement([workspace], isViewMode)[0];
    const workspacesElements = this.workspaces$.getValue();
    const newWorkspacesElements = workspacesElements.map((workspace) =>
      workspace.data.id === retrieveWorkspace.data.id ? retrieveWorkspace : workspace,
    );
    this.workspaces$.next(newWorkspacesElements);
  }

  public get planElements$(): Observable<Elements<PlanElementData>> {
    return combineLatest([this.stairs$, this.balconies$, this.lifts$, this.doors$]).pipe(
      map((data) => data.reduce((accumulator, array) => accumulator.concat(array), [])),
    );
  }
  private getNormalizeWorkspaceElement(
    workspaces: WorkspaceResponse[],
    isViewMode: boolean,
  ): Elements<WorkspaceElementData> {
    const normalizeWorkspaces = this.workspacesNormalization.normalizeWorkspaceFromApi(workspaces, 'workspaces');
    return mapWorkspaceToElements(normalizeWorkspaces, isViewMode);
  }
}

export default FloorsDataManagerService;
