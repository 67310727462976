import {TableContainer, Container} from './table.styles';

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {}

const Table: React.FC<TableProps> = ({children, ...elementProps}) => {
  return (
    <Container>
      <TableContainer cellSpacing={0} {...elementProps}>
        {children}
      </TableContainer>
    </Container>
  );
};

export default Table;
