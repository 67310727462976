import {getLanguageFromLS} from '@innowise-group/utilities';
import {GlobalContainerTypes, injectable} from '../..';
import {Language} from './language.types';

@injectable()
class LanguageService implements Language {
  public static readonly type = GlobalContainerTypes.Language;
  private readonly appLanguage: string;

  constructor() {
    this.appLanguage = getLanguageFromLS();
    this.getAppLanguage = this.getAppLanguage.bind(this);
    this.checkAppLanguage = this.checkAppLanguage.bind(this);
  }

  public getAppLanguage(): string {
    return this.appLanguage;
  }

  public checkAppLanguage(): boolean {
    return this.appLanguage === 'ru';
  }
}

export default LanguageService;
