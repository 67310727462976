import {Typography} from '@innowise-group/ui-kit';
import styled from 'styled-components';

export const Container = styled.section<{background: string}>`
  width: 100%;
  height: 100%;
  background-image: url(${({background}) => background});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  height: 100%;
  width: 340px;
  overflow: hidden;
`;

export const Row = styled.div``;

export const DividersGroup = styled.div<{background: string}>`
  background-image: url(${({background}) => background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 149px;
  height: 90px;
  z-index: 10;
  right: 2%;
  top: 2%;
`;

export const ContentHeader = styled.div``;

export const HeaderText = styled(Typography)`
  font-size: 30px;
  font-weight: 700;
  color: ${({theme}) => theme.palette.general.paper};
  text-align: center;
  margin-top: 30px;
`;

export const AuthButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 330px;
  margin: 48px 0 60px 0;
  border-radius: 29px;
  border: 2px solid ${({theme}) => theme.palette.general.lightGrey[40]};
  box-sizing: border-box;
  background: none;
  cursor: pointer;
`;

export const AuthButtonText = styled(Typography)`
  color: ${({theme}) => theme.palette.general.paper};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
`;

export const EmojisContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 340px;
`;

export const EmojiContainer = styled.div`
  border-radius: 50%;
  height: 72px;
  width: 72px;
  background-color: ${({theme}) => theme.palette.general.paper};
`;

export const EmojiImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  &svg {
    fill: black;
  }
`;

export const Version = styled(Typography)`
  position: absolute;
  bottom: 100px;
  font-size: 18px;
  cursor: pointer;
  color: ${({theme}) => theme.palette.general.paper};
  &:hover {
    color: ${({theme}) => theme.palette.general.lightGrey[80]};
  }
`;

export const SupportLinkBlock = styled.div`
  width: 100%;
  height: 44px;
  padding-top: 20px;
  border-top: 1px solid ${({theme}) => theme.palette.general.lightGrey[40]};
  text-align: center;
  position: absolute;
  bottom: 20px;
`;

export const SupportLink = styled.a`
  text-decoration: none;
  color: ${({theme}) => theme.palette.general.paper};
  cursor: pointer;
  font-size: 14px;
  z-index: 100;
  position: relative;

  &:hover {
    color: ${({theme}) => theme.palette.general.lightGrey[40]};
  }
`;

export const SupportLinkBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: ${({theme}) => theme.palette.general.paper};
  opacity: 0.3;
`;
