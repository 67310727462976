import {combineLatest} from 'rxjs';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {AppRoutes} from '@shared/core/constants';
import {NavigationBar} from '@innowise-group/ui-kit';
import {NavBarCollapseContext} from '@shared/core/services/nav-bar';
import {setLanguageToLC} from '@innowise-group/utilities';

import {
  AbilityContext,
  Can,
  EmployeesApiService,
  LanguageService,
  PersonContext,
  RequestsApiService,
  useInterval,
  useService,
} from '@innowise-group/core';

import * as Styled from './navigation-bar-container.styles';
import {LanguagesSelect} from '../languages-select';
import {ThemeSwitch} from '../theme-switch';

interface NavigationBarContainerProps {
  openReleaseNotes: () => void;
}

const NavigationBarContainer: React.FC<NavigationBarContainerProps> = ({openReleaseNotes}) => {
  const [employeesCount, setEmployeesCount] = useState(null);
  const [requestsCount, setRequestsCount] = useState(null);

  const person = useContext(PersonContext);
  const employeesApi = useService(EmployeesApiService);
  const requestsApi = useService(RequestsApiService);
  const {getAppLanguage} = useService(LanguageService);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() => getAppLanguage());
  const {isNavBarCollapsed, toggleNavBar} = useContext(NavBarCollapseContext);

  const {i18n} = useTranslation();

  const onLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setLanguageToLC(language);
    window.location.reload();
  };

  const fetchData = useCallback(() => {
    if (person?.allowed_locations) {
      return combineLatest([
        employeesApi.getEmployeesCountWithoutSeats(),
        requestsApi.getRequestsListWorkspacesCount(),
      ]).subscribe(([employeesCount, requestsCount]) => {
        setEmployeesCount(employeesCount);
        setRequestsCount(requestsCount);
      });
    }
  }, [employeesApi, requestsApi, person]);

  useEffect(() => {
    if (person?.allowed_locations) {
      const subscriber = fetchData();
      return () => subscriber.unsubscribe();
    }
  }, [fetchData, person]);

  useInterval(fetchData, 60000);

  const {t} = useTranslation();
  const ability = useContext(AbilityContext);

  return (
    <>
      <Can I="APPROVE" a="WORKSPACE-REQUEST" passThrough>
        {(allowed) => (
          <NavigationBar
            isCollapsed={isNavBarCollapsed}
            toggleCollapsed={toggleNavBar}
            routes={[
              {
                name: t('pages.offices.pageName'),
                path: AppRoutes.Offices,
                icon: 'u_building',
                activeWhen: (route) => route.includes(AppRoutes.Offices) || route.includes(AppRoutes.Floors),
                allowed: true,
              },
              {
                name: t(`${allowed ? 'pages.requests.pageName' : 'pages.requests.employeePageName'}`),
                path: AppRoutes.Requests,
                icon: 'u_chat-bubble-user',
                allowed: true,
                count: allowed && requestsCount > 0 ? requestsCount : '',
              },
              {
                name: t('pages.administration.pageName'),
                path: AppRoutes.Managers,
                icon: 'u_university',
                allowed: true,
              },
              {
                name: t('pages.history.pageName'),
                path: AppRoutes.History,
                icon: 'u_history',
                allowed: ability.can('READ', 'CHANGES'),
              },
              {
                name: t('pages.employees.pageName'),
                path: AppRoutes.Employees,
                icon: 'u_users-alt',
                allowed: ability.can('READ', 'EMPLOYEES'),
                count: employeesCount > 0 ? employeesCount : '',
              },
              {
                name: t('pages.geotrack.pageName'),
                path: AppRoutes.Geotrack,
                icon: 'u_robot',
                allowed: ability.can('READ', 'GEOTRACK'),
              },
            ]}
          >
            <Styled.TogglesContainer>
              <LanguagesSelect value={selectedLanguage} onLanguageChange={onLanguageChange} />
              <ThemeSwitch />
            </Styled.TogglesContainer>
            <Styled.VersionText onClick={openReleaseNotes}>
              {t('pages.version')}: {process.env.REACT_APP_VERSION}
            </Styled.VersionText>
          </NavigationBar>
        )}
      </Can>
    </>
  );
};

export default NavigationBarContainer;
