import {Typography} from '../typography';
import {Logo} from '../logo';
import {Input} from '../input';
import {styled} from '../theme-provider';
import {boxShadowStyles, appearAnimation} from '../global-styles';
import {Icon} from '../icon';

export const ApplicationLogo = styled(Logo)`
  cursor: pointer;
  width: 160px;
  transition: 0.2s;
  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 993px) {
    height: 50px;
    margin-left: 40px;
    margin-right: auto;
  }
  @media (max-width: 481px) {
    display: none;
  }
`;

export const SearchInput = styled(Input)`
  background-color: ${({theme}) => theme.palette.generalAlt.input.background.primary};
`;

export const UserMenu = styled.div`
  position: absolute;
  top: 115%;
  right: 0;
  width: 75%;
  min-width: 260px;
  padding: 10px 0;
  box-sizing: border-box;
  margin: 0;
  background-color: ${({theme}) => theme.palette.general.paper};
  border-radius: 10px;
  z-index: 10000;
  ${boxShadowStyles};
  animation: ${appearAnimation} 0.175s ease-in-out;
`;

export const UserMenuItem = styled(Typography)`
  display: flex;
  align-items: center;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
  }
`;

export const ApplicationBarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px 0 20px;
  min-height: 60px;
  width: 100%;
  background-color: ${({theme}) => theme.palette.generalAlt.background.tertiary};

  @media (max-width: 993px) {
    padding: 0 5px 0 20px;
  }
  @media (max-width: 481px) {
    padding: 0 5px 0 10px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;

  @media (max-width: 481px) {
    justify-content: space-between;
    width: 90%;
  }
`;

export const UserPreviewContainer = styled.div`
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;
  height: 100%;
  align-items: center;
  transition: 0.2s;

  @media (max-width: 481px) {
    padding: 0;
  }
`;

export const UserPosition = styled(Typography)`
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
  font-size: 12px;
`;

export const UserInfoContainer = styled.div`
  padding: 0 20px 0 10px;
  @media (max-width: 993px) {
    display: none;
  }
`;

export const IconContainer = styled(Icon)<{desktopOnly?: boolean; isOpen?: boolean}>`
  display: ${({desktopOnly}) => (desktopOnly ? 'flex' : 'none')};
  transition: 0.3s;
  transform: ${({isOpen}) => `rotate(${isOpen ? 180 : 0}deg)`};
  @media (max-width: 993px) {
    display: ${({desktopOnly}) => (desktopOnly ? 'none' : 'flex')};
  }
`;

export const VerticalSeparator = styled.div`
  position: absolute;
  top: 8px;
  left: 239px;
  height: 42px;
  width: 1px;
  background: #ebebeb;
`;
