import React, {useEffect, useRef, useState} from 'react';
import {FadeInOutView} from '../fade-in-out-view';
import {NotificationsManager, NotificationInstance} from './manager';
import Notification from './notification.component';
import * as Styled from './notification.styles';

export const notificationsManager = new NotificationsManager();

const NotificationContainer: React.FC = () => {
  const [collection, setCollection] = useState<NotificationInstance[]>([]);
  const previousCollectionRef = useRef<NotificationInstance[]>();

  useEffect(() => {
    previousCollectionRef.current = collection;
  });

  useEffect(() => {
    const difference = collection.filter((item) =>
      previousCollectionRef.current.map((collectionItem) => collectionItem.id).includes(item.id),
    );

    difference.forEach(({id}) => {
      setTimeout(() => {
        notificationsManager.close(id);
      }, 3000);
    });
  }, [collection]);

  useEffect(() => {
    notificationsManager.setDispatch(setCollection);
  }, [setCollection]);

  return (
    <Styled.NotificationsContainer>
      {collection.map(({id, title, subtitle, type, isCustomNotification, subtitleProps}) => (
        <FadeInOutView key={id}>
          <Notification
            title={title}
            type={type}
            isCustomNotification={isCustomNotification}
            subtitle={subtitle}
            subtitleProps={subtitleProps}
            onClose={() => {
              notificationsManager.close(id);
            }}
          />
        </FadeInOutView>
      ))}
    </Styled.NotificationsContainer>
  );
};

export default NotificationContainer;
