import {
  GlobalContainerTypes,
  Language,
  Office,
  OfficeDetails,
  OfficeResponse,
  WorkspacesCount,
} from '@innowise-group/core';
import {inject, injectable} from 'inversify';
import {OfficesNormalization} from './offices-normalization.types';
import {EmployeesNormalization} from '../employees-normalization';
import {LocationsNormalization} from '../locations-normalization';
import {FloorStatisticResponse} from '../offices-api';
import {Floor} from 'shared/core/models';

@injectable()
class OfficesNormalizationService implements OfficesNormalization {
  public static readonly type = GlobalContainerTypes.OfficesNormalization;

  @inject(GlobalContainerTypes.EmployeesNormalization) private employeesNormalization: EmployeesNormalization;
  @inject(GlobalContainerTypes.LocationsNormalization) private locationsNormalization: LocationsNormalization;
  @inject(GlobalContainerTypes.Language) private language: Language;

  constructor() {
    this.normalizeListOfficeFromApi = this.normalizeListOfficeFromApi.bind(this);
    this.normalizeWorkspacesCountFromApi = this.normalizeWorkspacesCountFromApi.bind(this);
    this.normalizeOfficeInfoFromApi = this.normalizeOfficeInfoFromApi.bind(this);
    this.normalizeOfficeFloorsFromApi = this.normalizeOfficeFloorsFromApi.bind(this);
  }

  public normalizeListOfficeFromApi(
    id: number,
    location: number,
    postcode: string,
    address: string,
    address_eng: string,
    fillPercentage: number = 0,
    jira_office_id?: number,
    has_faceid?: boolean,
  ): Office {
    return {
      id: id && String(id),
      locationId: location && String(location),
      fillPercentage: fillPercentage,
      floors: [],
      postcode: postcode,
      address: this.language.checkAppLanguage() ? address : address_eng,
      addressRu: address,
      addressEng: address_eng,
      hasFaceId: has_faceid,
      jiraOfficeId: jira_office_id && String(jira_office_id),
    };
  }

  public normalizeWorkspacesCountFromApi(
    total: number,
    free: number,
    reserved: number,
    booked: number,
    occupied: number,
    remote?: number,
  ): WorkspacesCount {
    return {
      total,
      free,
      reserved,
      booked,
      occupied,
      remote,
    };
  }

  public normalizeOfficeInfoFromApi(office: OfficeResponse): OfficeDetails {
    const {
      id,
      address,
      address_eng,
      location,
      postcode,
      manager: managers,
      number_of_free_workspaces,
      number_of_occupied_workspaces,
      number_of_reserved_workspaces,
      number_of_remote_workspaces,
      number_of_booked_workspaces,
      jira_office_id,
    } = office;
    return {
      ...this.normalizeListOfficeFromApi(
        id,
        location.id,
        postcode,
        address,
        address_eng,
        Math.round(
          ((number_of_reserved_workspaces + number_of_occupied_workspaces) /
            (number_of_free_workspaces + number_of_reserved_workspaces + number_of_occupied_workspaces)) *
            100,
        ) || 0,
        jira_office_id,
      ),
      location: this.locationsNormalization.normalizeLocationFromApi(location),
      managers: managers.map((manager) => ({
        ...this.employeesNormalization.normalizeEmployeeFromApi(manager),
        managerType: manager.manager_type,
      })),
      workspacesCount: this.normalizeWorkspacesCountFromApi(
        number_of_free_workspaces +
          number_of_occupied_workspaces +
          number_of_reserved_workspaces +
          number_of_booked_workspaces,
        number_of_free_workspaces,
        number_of_reserved_workspaces,
        number_of_booked_workspaces,
        number_of_occupied_workspaces,
        number_of_remote_workspaces,
      ),
    };
  }

  public normalizeOfficeFloorsFromApi(floors: FloorStatisticResponse): Floor[] {
    return floors.map((floor) => {
      const {number_of_not_free_workspaces, number_of_free_workspaces, id, manager1, manager2, hr} = floor;

      return {
        ...floor,
        manager1: this.employeesNormalization.normalizeEmployeeFromApi(manager1),
        manager2: this.employeesNormalization.normalizeEmployeeFromApi(manager2),
        hr: this.employeesNormalization.normalizeEmployeeFromApi(hr),
        id: String(id),
        fillPercentage:
          Math.round(
            (number_of_not_free_workspaces / (number_of_free_workspaces + number_of_not_free_workspaces)) * 100,
          ) || 0,
      };
    });
  }
}

export default OfficesNormalizationService;
