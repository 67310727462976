import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {inject, injectable} from 'inversify';
import {from, map, Observable, take} from 'rxjs';
import {Auth} from '../auth';
import {GlobalContainerTypes} from '../../types';
import {Http, RequestConfig} from './http.types';
import {notificationsManager} from '@innowise-group/ui-kit';
import {getLanguageFromLS} from '@innowise-group/utilities';

@injectable()
class HttpService implements Http {
  public static readonly type = GlobalContainerTypes.Http;

  @inject('Auth') private auth: Auth;
  private readonly language: string;
  private axiosInstance: AxiosInstance;

  public constructor() {
    this.language = getLanguageFromLS();
    this.axiosInstance = axios.create({baseURL: `${this.originUrl}${this.languageUrl}/api/v1`});
    this.applyInterceptors();
  }

  public get originUrl(): string {
    return process.env.REACT_APP_API_BASE_URL || window.location.origin;
  }

  public get hrmRequestConfig(): RequestConfig {
    return {baseURL: `${process.env.REACT_APP_HRM_API_BASE_URL || window.location.origin}/api`};
  }

  public get chatbotRequestConfig(): RequestConfig {
    return {baseURL: process.env.REACT_APP_CHATBOT_API_BASE_URL || window.location.origin, ignoreInterceptors: true};
  }

  private get languageUrl(): string {
    return this.language === 'ru' ? '' : '/en';
  }

  private applyInterceptors(): void {
    this.axiosInstance.interceptors.request.use(async (config: RequestConfig) => {
      if (config.ignoreInterceptors) return config;

      await this.auth.updateToken();
      config.headers = {
        Authorization: `Bearer ${this.auth.authState.accessToken}`,
        'Content-Type': 'application/json',
      };
      return config;
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          notificationsManager.error({title: 'networkError', subtitle: 'networkError'});
        } else {
          const {data, status, statusText} = error.response;

          if (status >= 400) {
            notificationsManager.error({
              title: `statusTextError`,
              subtitle:
                typeof data === 'object'
                  ? Object.entries(data).reduce((accumulator, [key, value]) => accumulator + `${key}: ${value}`, '')
                  : 'unknownError',
              statusCode: `${statusText}`,
            });
          }
          return Promise.reject(error);
        }
      },
    );

    // this.axiosInstance.interceptors.response.use(
    //   (response) => {
    //     return response.data;
    //   },
    //   async function (error) {
    //     if (error.response.status === 401 && !error.config.retry) {
    //       error.config.retry = true;
    //       const refreshToken = refreshHeader();
    //       const newToken = await refreshToken(refresh_token);
    //       error.response.config.headers['Authorization'] = 'Bearer ' + newToken;
    //       return axiosApiInstance(error.response.config);
    //     }
    //     return Promise.reject(error);
    //   },
    // );
  }

  public GET<T>(url: string, config?: AxiosRequestConfig): Observable<T> {
    return from(this.axiosInstance.get<T>(url, config)).pipe(
      take(1),
      map((response) => response.data),
    );
  }

  public POST<T, G = {}>(url: string, form: G, config?: AxiosRequestConfig): Observable<T> {
    return from(this.axiosInstance.post<T>(url, form, config)).pipe(
      take(1),
      map((response) => response.data),
    );
  }

  public PUT<T, G = {}>(url: string, form: G): Observable<T> {
    return from(this.axiosInstance.put<T>(url, form)).pipe(
      take(1),
      map((response) => response.data),
    );
  }

  public DELETE<T, G = {}>(url: string, form: G): Observable<T> {
    return from(this.axiosInstance.delete<T>(url, form)).pipe(
      take(1),
      map((response) => response.data),
    );
  }

  public PATCH<T, G = {}>(url: string, form: G): Observable<T> {
    return from(this.axiosInstance.patch<T>(url, form)).pipe(
      take(1),
      map((response) => response.data),
    );
  }
}

export default HttpService;
