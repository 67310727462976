import {injectable, inject, decorate, GlobalContainerTypes, Auth} from '@innowise-group/core';
import EventEmitter from 'events';
import {notificationsManager} from '@innowise-group/ui-kit';
import {FloorsContainerTypes} from '../floors-ioc.types';
import {
  FloorEvents,
  FloorsEventEmitter,
  FloorsSocketEmitEvents,
  FloorsSocketEmitMultipleEvents,
} from './floors-event-emitter.types';

decorate(injectable(), EventEmitter);

declare interface FloorsEventEmitterService {
  on<U extends keyof FloorEvents>(event: U, listener: FloorEvents[U]): this;

  emit<U extends keyof FloorEvents>(event: U, ...args: Parameters<FloorEvents[U]>): boolean;
}

class FloorsEventEmitterService extends EventEmitter implements FloorsEventEmitter {
  public static readonly type = FloorsContainerTypes.FloorsEventEmitter;
  public client: WebSocket;
  @inject(GlobalContainerTypes.Auth) private auth: Auth;

  constructor() {
    super();
    this.client = null;
    this.connect = this.connect.bind(this);
    this.check = this.check.bind(this);
    this.close = this.close.bind(this);
  }

  async connect(floorId: string) {
    await this.auth?.updateToken();

    if (this.auth?.authState.accessToken) {
      const websocket = new WebSocket(
        `wss://${this.host}/ws/floor/${floorId}?token=${this.auth?.authState.accessToken}`,
      );
      let connectInterval: NodeJS.Timeout;
      websocket.onopen = () => {
        this.client = websocket;
        this.emit('clientReady');
        clearTimeout(connectInterval); // clear Interval on open of websocket connection
      };
      websocket.onclose = (event: CloseEvent) => {
        if (event.code === 1011) {
          notificationsManager.error({title: 'networkError', subtitle: 'networkError'});
          connectInterval = setTimeout(() => this.check(floorId), 5000);
        }
        if (!event.wasClean) {
          notificationsManager.error({title: 'networkError', subtitle: 'networkError'});
          connectInterval = setTimeout(() => this.check(floorId), 5000); //call check function after timeout
        }
      };
      websocket.onerror = () => {
        websocket.close();
      };
      websocket.onmessage = (event: MessageEvent) => {
        const messageData = JSON.parse(event.data);
        this.emit(messageData.event, messageData);
      };
    }
  }

  public get host(): string {
    return process.env.REACT_APP_API_WEBSOCKET_HOST || window.location.host;
  }

  public get isClientReady(): boolean {
    return this.client && this.client.readyState !== WebSocket.CLOSED ? true : false;
  }

  close() {
    if (this.client) {
      this.client.close();
    }
  }

  emit(
    eventName: string | symbol | keyof (FloorsSocketEmitEvents & FloorsSocketEmitMultipleEvents),
    ...args: any[]
  ): boolean {
    switch (eventName) {
      case 'patch':
      case 'delete':
      case 'create':
      case 'list':
      case 'retrieve':
      case 'patch_room':
      case 'rotate_workspace':
      case 'multiple_create':
      case 'multiple_delete':
      case 'multiple_patch':
      case 'patch_drag_n_drop':
      case 'update_plan': {
        const data = {
          event: eventName,
          ...args[0],
        };
        if (this.client && this.client.readyState !== WebSocket.CLOSED) {
          this.client.send(JSON.stringify(data));
        }
        break;
      }
    }
    return super.emit(eventName, ...args);
  }

  private check(floorId: string) {
    //check if websocket instance is closed, if so call `connect` function.
    if (!this.client || this.client.readyState === WebSocket.CLOSED) {
      this.connect(floorId);
    }
  }
}

export default FloorsEventEmitterService;
