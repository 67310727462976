import {RefObject, useEffect, useRef} from 'react';

const useClickOutside = (ref: RefObject<HTMLElement | null>, onClickAway: (event: MouseEvent | TouchEvent) => void) => {
  const savedCallback = useRef(onClickAway);

  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);

  useEffect(() => {
    const handler = (event: MouseEvent | TouchEvent) => {
      const {current} = ref;
      current && !current.contains(event.target as Node) && savedCallback.current(event);
    };

    document.addEventListener('click', handler, true);
    document.addEventListener('touchstart', handler, true);

    return () => {
      document.removeEventListener('click', handler, true);
      document.removeEventListener('touchstart', handler, true);
    };
  }, [ref]);
};

export default useClickOutside;
