import {styled, css} from '@innowise-group/ui-kit';

export const PlanElementViewContainer = styled.div<{width: number; height: number}>`
  position: relative;
  box-sizing: border-box;
  width: ${({width}) => `${width}px`};
  height: ${({height}) => `${height}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

export const Label = styled.div<{selected: boolean}>`
  position: relative;
  z-index: 100;
  background-color: ${({selected, theme}) => (selected ? theme.palette.general.white : '')};
  transform: scale(${({selected}) => (selected ? 2 : 1)});
  transition: transform 0.5s;
`;

export const PlanElementSign = styled.img`
  width: 35px;
  height: 35px;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  filter: drop-shadow(0px 3px 0px black) drop-shadow(3px 0px 0px black) drop-shadow(0px -2px 0px black)
    drop-shadow(-2px 0px 0px black);
`;

export const DoorImage = styled(Image)`
  filter: drop-shadow(0px 1px 0px black) drop-shadow(1px 0px 0px black) drop-shadow(0px -1px 0px black)
    drop-shadow(-1px 0px 0px black);
`;

export const RoomImage = styled(Image)<{selected: boolean; isOur: boolean; isEditRoom: boolean}>`
  ${({isOur, selected, theme, isEditRoom}) => {
    const red = theme.palette.general.smart;
    return css`
      filter: invert(${isOur ? 0 : 0.3}) drop-shadow(0px 3px 0px ${isEditRoom ? red : 'black'})
        drop-shadow(3px 0px 0px ${isEditRoom ? red : 'black'}) drop-shadow(0px -2px 0px ${isEditRoom ? red : 'black'})
        drop-shadow(-2px 0px 0px ${isEditRoom ? red : 'black'});
      opacity: ${selected ? 0.7 : 1};
    `;
  }}
`;
