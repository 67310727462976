import {useCallback, ReactNode, Fragment} from 'react';
import {Icon, Typography} from '@innowise-group/ui-kit';
import {GeotrackAvatar} from '../geotrack-avatar';
import * as Styled from './geotrack-details-info.styles';
import {Table, TableData, TableRow} from '@innowise-group/ui-kit';
import exitIcon from '../../../../assets/icons/exit.svg';
import entranceIcon from '../../../../assets/icons/entrance.svg';
import {format} from 'date-fns';
import {EmployeeDateTimeMarksData} from '../../types';
import {useTranslation} from 'react-i18next';

const tableHeadList = [' ', 'pages.geotrack.filters.time', 'pages.geotrack.entryOffice'];

interface GeotrackDatailsInfoProps {
  initialValues: EmployeeDateTimeMarksData;
  onClose: () => void;
}

const GeotrackDatailsInfo: React.FC<GeotrackDatailsInfoProps> = ({initialValues, onClose}) => {
  const {t} = useTranslation();
  const withPlaceholder = useCallback((field: ReactNode) => field || '-', []);

  return (
    <>
      <Styled.HeaderContainer>
        <Styled.HeaderWrapper>
          <GeotrackAvatar user={initialValues} avatarSize={54} />
          <div>
            <div>
              <Styled.EmployeeNameTypography>
                {initialValues?.preferred_name.map((part, i) => (
                  <Fragment key={part}>{part} </Fragment>
                ))}
              </Styled.EmployeeNameTypography>
            </div>
            <div>
              <Typography>{format(new Date(initialValues?.date), 'dd.MM.yyyy')}</Typography>
            </div>
          </div>
        </Styled.HeaderWrapper>
        <Icon type="u_multiply" onClick={onClose} isPointer isHover />
      </Styled.HeaderContainer>
      <Styled.GeotrackInfoContainer>
        <Table>
          <thead>
            <TableRow>
              {tableHeadList.map((head, index) => (
                <Styled.GeotrackTableHead key={head ?? index}>{head && t(head)}</Styled.GeotrackTableHead>
              ))}
            </TableRow>
          </thead>
          <tbody>
            {initialValues?.all_date_time_marks?.map((mark) => (
              <TableRow key={`${mark.date_time}-${mark.location}`}>
                <TableData>
                  <img src={mark.is_entrance ? entranceIcon : exitIcon} alt="s" />
                </TableData>
                <TableData>
                  <Styled.GeotrackTypography noBreak>{withPlaceholder(mark.date_time)}</Styled.GeotrackTypography>
                </TableData>
                <TableData>
                  <Styled.GeotrackTypography noBreak>
                    {withPlaceholder(
                      mark?.location_country && mark.location && (
                        <>
                          <b>{[mark.location_city, mark.location_country].filter((it) => it).join(', ')}</b>
                          <br />
                          {mark.location}
                        </>
                      ),
                    )}
                  </Styled.GeotrackTypography>
                </TableData>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </Styled.GeotrackInfoContainer>
    </>
  );
};

export default GeotrackDatailsInfo;
