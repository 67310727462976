import {FadeInOutView} from '../fade-in-out-view';
import {styled} from '../theme-provider';

export const PaperContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled(FadeInOutView)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.palette.general.lightGrey[60]};
  padding: 15px 20px;

  @media (max-width: 993px) {
    padding: 10px 10px 0 10px;
    min-height: auto;
  }
  @media (max-width: 481px) {
    padding: 15px 10px 0 10px;
    min-height: auto;
  }
`;
