import {MouseEventHandler} from 'react';
import {Icon} from '../icon';
import styled from 'styled-components';

const pointerIfClickable = ({onClick}: {onClick?: MouseEventHandler}) => (onClick ? 'pointer' : 'unset');

export const Container = styled.div`
  @media (max-width: 769px) {
    width: 100%;
    overflow-y: scroll;
  }
`;

export const TableContainer = styled.table`
  /* table-layout: fixed; */
  width: 100%;
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  border-radius: 6px;
  overflow: hidden;

  @media (max-width: 769px) {
    width: 800px;
  }
`;

export const TableHeadContainer = styled.th`
  cursor: ${pointerIfClickable};
  font-size: 14px;
  font-weight: 700;
  color: ${({theme}) => theme.palette.general.darkGrey[60]};
  background-color: ${({theme}) => theme.palette.general.lightGrey[40]};
  border-bottom: 1px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  text-align: left;
  padding: 5px;

  @media (max-width: 481px) {
    font-size: 10px;
  }
`;

export const TableHeadContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TableHeadSortIcon = styled(Icon)<{direction: 'asc' | 'desc'}>`
  transition: 0.5s;
  transform: ${({direction}) => (direction === 'asc' ? 'rotate(-180deg)' : 'rotate(0deg)')};
`;

export const TableRowContainer = styled.tr`
  height: 64px;
  & > td,
  th {
    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }
  &:nth-child(1n) {
    background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
    &:hover {
      background-color: ${({theme}) => theme.palette.general.lightGrey[60]};
    }
  }
  &:nth-child(2n) {
    background-color: ${({theme}) => theme.palette.general.lightGrey[40]};
    &:hover {
      background-color: ${({theme}) => theme.palette.general.lightGrey[60]};
    }
  }

  @media (max-width: 481px) {
    height: 40px;
  }
  cursor: ${pointerIfClickable};
`;

export const TableDataContainer = styled.td`
  cursor: ${pointerIfClickable};
  font-size: 14px;
  padding: 5px;
`;
