import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FadeInOutView, Form, Input, Tooltip, useClickOutside, useTheme} from '@innowise-group/ui-kit';
import {useCallback, useRef, useState} from 'react';
import {FloorsForm} from '../floors-form';
import * as Styled from './office-information-form.styles';

export enum OfficeInformationFormFields {
  Address = 'address',
  AddressEng = 'address_eng',
  JiraOfficeId = 'jira_office_id',
}

export interface OfficeInformationFormValues {
  address: string;
  address_eng: string;
  jira_office_id: string;
}

const OfficeInformationForm: React.FC<{
  initialValues?: Partial<OfficeInformationFormValues>;
  floors: {number: number; isVirtual: boolean}[];
  showFloorsForm?: boolean;
}> = ({initialValues, floors, showFloorsForm}) => {
  const {
    register,
    formState: {errors},
  } = useFormContext();

  const [showTooltip, setShowTooltip] = useState(false);
  const theme = useTheme();
  const {t} = useTranslation();
  const tooltipRef = useRef(null);
  const openTooltipHandler = () => setShowTooltip((value) => !value);
  const closeTooltipHandler = useCallback(() => setShowTooltip(false), []);
  useClickOutside(tooltipRef, closeTooltipHandler);

  const {address, address_eng, jira_office_id} = initialValues;

  return (
    <div>
      <Form.Layout layoutType="grid" inset="top">
        <Form.Field
          label={`${t('modals.address')} (RU) *`}
          error={errors[OfficeInformationFormFields.Address]?.message}
        >
          <Input
            {...register(OfficeInformationFormFields.Address, {
              required: {value: true, message: t('modals.messages.required')},
            })}
            defaultValue={address}
            placeholder={t('placeholders.address')}
          />
        </Form.Field>
        <Form.Field
          label={`${t('modals.address')} (EN) *`}
          error={errors[OfficeInformationFormFields.AddressEng]?.message}
        >
          <Input
            {...register(OfficeInformationFormFields.AddressEng, {
              required: {value: true, message: t('modals.messages.required')},
            })}
            defaultValue={address_eng}
            placeholder={t('placeholders.address')}
          />
        </Form.Field>
        {showFloorsForm && <FloorsForm initialValues={{floors}} />}
        <Styled.FormField
          label={`${t('modals.officeId')} *`}
          error={errors[OfficeInformationFormFields.JiraOfficeId]?.message}
          labelTooltip={
            <Styled.IconContainer ref={tooltipRef}>
              <Tooltip
                horizontal="left"
                vertical="bottom"
                active={showTooltip}
                withPointer={false}
                content={
                  <FadeInOutView>
                    <Styled.TooltipContainer>
                      <Styled.Text>{t('pages.offices.officeDetails.tooltipInfo')}</Styled.Text>
                    </Styled.TooltipContainer>
                  </FadeInOutView>
                }
              >
                <Styled.InfoButton
                  onClick={openTooltipHandler}
                  type="u_info-circle"
                  size={15}
                  fill={theme.palette.state.error.pressed}
                />
              </Tooltip>
            </Styled.IconContainer>
          }
        >
          <Input
            {...register(OfficeInformationFormFields.JiraOfficeId, {
              required: {value: true, message: t('modals.messages.required')},
            })}
            defaultValue={jira_office_id}
            placeholder={t('placeholders.officeId')}
          />
        </Styled.FormField>
      </Form.Layout>
    </div>
  );
};

export default OfficeInformationForm;
