import {LocationsSelect as Select} from '@shared-components';
import {styled, Form} from '@innowise-group/ui-kit';

import {DropdownContainer} from '../../../../shared/ui-kit/select/select.styles';

export const LocationsSelect = styled(Select)`
  ${DropdownContainer} {
    width: 210px;

    @media (max-width: 391px) {
      width: 160px;
    }
  }
`;

export const FormLayout = styled(Form.Layout)`
  gap: 32px;
`;
