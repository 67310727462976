import {styled, Typography} from '@innowise-group/ui-kit';

export const Container = styled.div<{desktopOnly?: boolean; noExtraMargin?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 22px;
  cursor: pointer;
  margin-left: ${({noExtraMargin}) => (noExtraMargin ? 0 : '20px')};
  padding: 10px 5px;
  &:hover {
    background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
  }

  @media (max-width: 768px) {
    display: ${({desktopOnly}) => (desktopOnly ? 'none' : 'flex')};
  }
`;

export const Text = styled(Typography)<{color?: string}>`
  margin-left: 5px;
  color: ${({color}) => color};
`;
