import {inject, injectable} from 'inversify';
import {Http} from '../../http';
import {GlobalContainerTypes} from '../../../types';
import {Authentication, Permissions, PermissionsResponse} from './authentication.types';
import {map, Observable} from 'rxjs';
import {EmployeesNormalization} from '../../employees-normalization';

@injectable()
class AuthenticationService implements Authentication {
  public static readonly type = GlobalContainerTypes.Authentication;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(GlobalContainerTypes.EmployeesNormalization) private employeesNormalization: EmployeesNormalization;
  public constructor() {
    this.authenticate = this.authenticate.bind(this);
  }

  public authenticate(): Observable<Permissions> {
    return this.http.GET<PermissionsResponse>(`employees/retrieve-roles/me/`).pipe(
      map(({permissions, ids, person, allowed_locations}) => {
        return {
          permissions,
          ids,
          allowed_locations,
          person: this.employeesNormalization.normalizeEmployeeFromApi(person),
        };
      }),
    );
  }
}

export default AuthenticationService;
