import {PlanElement, Workspace} from '@innowise-group/core';
import {Elements} from 'react-flow-renderer';

import {PlanElementData} from '../components/plan-element-vew';
import {WorkspaceElementData} from '../components/workspace-view';
import {Room} from '../pages/floor-details/use-floor-details.hook';

export const mapRoomsToElements = (
  data: PlanElement[],
  setRoomCoordinates: (value: React.SetStateAction<Room[]>) => void,
  isViewMode: boolean,
  selectable: boolean,
): Elements<PlanElementData> =>
  data &&
  data.map(({id, coordinates, type, ...rest}) => {
    return {
      id: `${type}-${id}`,
      type,
      data: {
        setRoomCoordinates,
        id,
        coordinates,
        isViewMode,
        type,
        ...rest,
      },
      draggable: false,
      selectable: selectable,
      position: {
        x: coordinates[0][0],
        y: coordinates[0][1],
      },
    };
  });

export const mapViewToElements = (data: PlanElement[]): Elements<PlanElementData> =>
  data &&
  data.map(({id, type, coordinates, ...rest}) => {
    return {
      id: `${type}-${id}`,
      type,
      data: {
        type,
        coordinates,
        id,
        ...rest,
      },
      draggable: false,
      selectable: false,
      position: {
        x: coordinates[0][0],
        y: coordinates[0][1],
      },
    };
  });

export const mapWorkspaceToElements = (data: Workspace[], isViewMode: boolean): Elements<WorkspaceElementData> =>
  data &&
  data.map(({id, type, coordinates, ...rest}) => {
    return {
      id: `${type}-${id}`,
      type,
      dragHandle: `.${type}`,
      data: {
        id,
        type,
        isViewMode,
        coordinates,
        ...rest,
      },
      selectable: true,
      draggable: !isViewMode,
      position: {
        x: coordinates[0][0] - 50,
        y: coordinates[0][1] - 25,
      },
    };
  });
