import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useResizeObserver, RESIZE_OBSERVER_SMALL_DESKTOP_SCREEN_SIZE} from '@innowise-group/ui-kit';
import {LanguageService, useAuth, useService} from '@innowise-group/core';

import {AppRoutes} from '../../shared/core/constants/app.router';
import {DesktopViewAuth} from './components/desktop-view';
import {MobileViewAuth} from './components/mobile-view';

import * as Styled from './auth.styles';

interface AuthProps {
  openReleaseNotes: () => void;
}

const Auth: React.FC<AuthProps> = ({openReleaseNotes}) => {
  const navigate = useNavigate();
  const {authenticated, login} = useAuth();
  const {getAppLanguage} = useService(LanguageService);
  const {i18n} = useTranslation();
  const isDesktopView = useResizeObserver('auth-container', RESIZE_OBSERVER_SMALL_DESKTOP_SCREEN_SIZE);

  useEffect(() => {
    const language = getAppLanguage();
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [getAppLanguage, i18n]);

  useEffect(() => {
    const authenticatedValue = authenticated.getValue();
    if (authenticatedValue) {
      const requestId = sessionStorage.getItem('requestId');
      sessionStorage.removeItem('requestId');
      if (requestId) {
        navigate(`/requests/?id=${requestId}`);
      } else {
        navigate(localStorage.getItem('hotelling-path') || AppRoutes.Home);
      }
    }
  }, [authenticated, navigate]);

  const handleSignIn = () => {
    login();
  };

  const commonProps = {openReleaseNotes, handleSignIn};

  return (
    <Styled.AuthContainer id="auth-container">
      {isDesktopView ? <DesktopViewAuth {...commonProps} /> : <MobileViewAuth {...commonProps} />}
    </Styled.AuthContainer>
  );
};

export default Auth;
