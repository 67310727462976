export enum GlobalContainerTypes {
  Auth = 'Auth',
  Http = 'Http',
  Authentication = 'Authentication',
  EmployeesApi = 'EmployeesApi',
  LocationsApi = 'LocationsApi',
  OfficesApi = 'OfficesApi',
  LocationsNormalization = 'LocationsNormalization',
  EmployeesNormalization = 'EmployeesNormalization',
  OfficesNormalization = 'OfficesNormalization',
  FloorsApi = 'FloorsApi',
  WorkspacesApi = 'WorkspacesApi',
  RoomsApi = 'RoomsApi',
  FloorsNormalization = 'FloorsNormalization',
  WorkspacesNormalization = 'WorkspacesNormalization',
  RequestsApi = 'RequestsApi',
  RequestsNormalization = 'RequestsNormalization',
  Language = 'Language',
}
