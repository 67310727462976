import * as Styled from './employees-list.styles';
import {Employee, EmployeesApiService, useDebounce, useService} from '@innowise-group/core';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Avatar} from '@innowise-group/ui-kit';
import {useTranslation} from 'react-i18next';

interface EmployeesListProps {
  name: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSearchName: React.Dispatch<React.SetStateAction<string>>;
  setEmployees: React.Dispatch<React.SetStateAction<Employee[]>>;
  employees: Employee[];
}

const EmployeesList: React.FC<EmployeesListProps> = ({name, page, setPage, setSearchName, setEmployees, employees}) => {
  const [count] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const employeesApi = useService(EmployeesApiService);
  const employeeContainer = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver>();
  const searchName = useDebounce(name, 500);
  const {t} = useTranslation();

  useEffect(() => {
    const subscriber = employeesApi
      .getEmployees(searchName, count, page)
      .subscribe(({employees: fetchedEmployees, count}) => {
        if (page === 1) {
          setEmployees(fetchedEmployees);
        } else {
          setEmployees((employees) => [...employees, ...fetchedEmployees]);
        }
        setTotalCount(count);
      });
    return () => subscriber.unsubscribe();
  }, [count, page, searchName, employeesApi, setEmployees]);

  useEffect(() => {
    const element = employeeContainer.current;
    const performScroll = (event) => {
      event.preventDefault();
      const delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));
      element.scrollLeft -= delta * 50;
    };
    element.addEventListener('mousewheel', performScroll, false);
    return () => {
      element.removeEventListener('mousewheel', performScroll);
      setSearchName('');
    };
  }, [setSearchName, employeeContainer]);

  const onDragStartHandler = (event: React.DragEvent<HTMLDivElement>, employee: Employee) => {
    event.dataTransfer.setData('employee', JSON.stringify(employee));
    event.dataTransfer.effectAllowed = 'move';
  };
  const lastEmployeeElementRef = useCallback(
    (node: Element) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [setPage],
  );

  return (
    <Styled.EmployeesListContainer>
      <Styled.EmployeesContainer ref={employeeContainer} show={!!employees.length}>
        {employees.map((employee, index) => (
          <Styled.EmployeeBlock
            key={employee.id}
            draggable={true}
            onDragStart={(event) => onDragStartHandler(event, employee)}
            ref={employees.length - 1 === index && page < Math.ceil(totalCount / count) ? lastEmployeeElementRef : null}
          >
            <Avatar imageUrl={employee.photoUrl} />
            <Styled.EmployeeInfo>
              <Styled.EmployeeName>{employee.fullName}</Styled.EmployeeName>
              {!employee?.isHaveWorkspace && (
                <Styled.InfoMessage>{t('pages.floors.withoutWorkspace')}</Styled.InfoMessage>
              )}
            </Styled.EmployeeInfo>
          </Styled.EmployeeBlock>
        ))}
      </Styled.EmployeesContainer>
    </Styled.EmployeesListContainer>
  );
};

export default React.memo(EmployeesList);
