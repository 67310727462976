import {Modal, Typography} from '@innowise-group/ui-kit';
import {useTranslation} from 'react-i18next';
import * as Styled from './release-notes.styles';

interface ReleaseNotesProps {
  version: string;
  close: () => void;
}

const ReleaseNotes: React.FC<ReleaseNotesProps> = ({version, close}) => {
  const {t} = useTranslation();

  return (
    <Modal.Container>
      <Modal.Header title={t('releaseNotes.title')} onClose={close} />
      <Modal.Body>
        <Styled.VersionText>
          {t('pages.version')} {version}
        </Styled.VersionText>
        <Styled.UnorderedList>
          {t<string, string[]>('releaseNotes.list', {returnObjects: true}).map((note, index) => (
            <Styled.ListItem key={index}>
              <Typography>{note}</Typography>
            </Styled.ListItem>
          ))}
        </Styled.UnorderedList>
      </Modal.Body>
    </Modal.Container>
  );
};

export default ReleaseNotes;
