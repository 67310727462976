import {ContainerModule, interfaces} from '@innowise-group/core';
import {HistoryApi, HistoryApiService} from './history-api';
import {HistoryContainerTypes} from './history-ioc.types';
import {HistoryNormalization, HistoryNormalizationService} from './history-normalization';

export const historyContainer = new ContainerModule(
  (
    bind: interfaces.Bind,
    unbind: interfaces.Unbind,
    isBound: interfaces.IsBound,
    rebind: interfaces.Rebind,
    unbindAsync: interfaces.UnbindAsync,
    onActivation: interfaces.Container['onActivation'],
    onDeactivation: interfaces.Container['onDeactivation'],
  ) => {
    bind<HistoryApi>(HistoryContainerTypes.History).to(HistoryApiService);
    bind<HistoryNormalization>(HistoryContainerTypes.HistoryNormalization).to(HistoryNormalizationService);
  },
);
