import {useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {AppRoutes} from '../../constants/app.router';
import {useAuth} from './use-auth.hook';

export const PrivateRoute: React.FC = ({children}) => {
  const {pathname, search} = useLocation();
  const {authenticated} = useAuth();
  const authenticatedValue = authenticated.getValue();
  useEffect(() => localStorage.setItem('hotelling-path', pathname + search), [pathname, search]);

  return <>{authenticatedValue ? children : <Navigate to={AppRoutes.Auth} />}</>;
};
