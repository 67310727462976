import {Icon, Input, styled, Typography} from '@innowise-group/ui-kit';

export const EmployeeSeatsContainer = styled.div`
  overflow: hidden auto;
  min-height: 20px;
  max-height: 240px;
  min-width: 210px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.palette.general.lightGrey[40]};
    border-radius: 0 2px 2px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.palette.general.lightGrey[60]};
    border-radius: 0 2px 2px 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.palette.general.lightGrey[80]};
  }

  @media (max-width: 480px) {
    width: 320px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 80px;
`;

export const EmployeeSeatBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  min-width: 300px;
  min-height: 60px;
  cursor: ${({allowedToInteract}: {allowedToInteract: boolean}) => allowedToInteract && 'pointer'};
  opacity: ${({allowedToInteract}: {allowedToInteract: boolean}) => !allowedToInteract && 0.5};

  &:hover {
    background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
  }

  @media (max-width: 480px) {
    width: 330px;
  }
`;

export const EmployeeSeatInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const LocationIcon = styled(Icon)`
  margin-right: 5px;
`;

export const SeatInfo = styled(Typography)<{margin?: boolean}>`
  margin: ${({margin}) => (margin ? '0 5px 0 1px' : 0)};
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
  font-weight: bold;
  white-space: nowrap;
`;

export const Span = styled.span<{matches?: boolean}>`
  color: ${({theme, matches}) => (matches ? theme.palette.general.smart : theme.palette.general.darkGrey[100])};
`;

export const SeatInfoBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  margin-right: 28px;
`;

export const SearchInput = styled(Input)`
  background-color: ${({theme}) => theme.palette.generalAlt.input.background.primary};
  border: none;
  border-radius: 16px;
  margin-right: 48px;

  &::placeholder {
    color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
  }

  @media (max-width: 481px) {
    width: 275px;
  }
`;
