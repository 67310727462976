import {GlobalContainerTypes, Http, inject, injectable, Location, LocationResponse} from '../..';
import {map, Observable, tap} from 'rxjs';
import {LocationsApi, PostLocationPayload} from './locations-api.types';
import {notificationsManager} from '@innowise-group/ui-kit';
import {LocationsNormalization} from '../locations-normalization';

@injectable()
class LocationsApiService implements LocationsApi {
  public static readonly type = GlobalContainerTypes.LocationsApi;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(GlobalContainerTypes.LocationsNormalization) private locationsNormalization: LocationsNormalization;

  public getLocations(): Observable<Location[]> {
    return this.http
      .GET<Array<LocationResponse>>('/locations/')
      .pipe(map((list) => list.map(this.locationsNormalization.normalizeLocationFromApi)));
  }

  public addLocation(locationData: PostLocationPayload): Observable<Location> {
    return this.http.POST<LocationResponse>('/locations/', locationData).pipe(
      map(this.locationsNormalization.normalizeLocationFromApi),
      tap(() => {
        notificationsManager.success({title: 'success', subtitle: 'addLocation'});
      }),
    );
  }

  public deleteLocation(locationId: string): Observable<void> {
    return this.http.DELETE(`/locations/${locationId}/`).pipe(
      tap<void>(() => {
        notificationsManager.success({title: 'success', subtitle: 'deleteLocation'});
      }),
    );
  }
}

export default LocationsApiService;
