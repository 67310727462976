import {Select, SelectProps} from '@innowise-group/ui-kit';
import React, {useEffect, useState} from 'react';
import {AvailableRoomsAndWorkspaces} from '@innowise-group/core';
import {useTranslation} from 'react-i18next';

interface RoomsSelectProps extends SelectProps {
  floorsId?: string;
  roomsList?: AvailableRoomsAndWorkspaces[];
}

const RoomsSelect: React.FC<RoomsSelectProps> = ({roomsList, defaultValue, isFieldChanged, ...props}) => {
  const [rooms, setRoom] = useState<{value: string; title: string}[]>([]);
  const {t} = useTranslation();

  useEffect(() => {
    if (roomsList.length) {
      setRoom(
        roomsList
          .filter((room) => room.roomType !== 'Water closet' && room.isOur)
          .filter((room) => room.roomType !== 'Hall' && room.isOur)
          .map((room) => ({value: room.id, title: room.number})),
      );
    } else {
      setRoom([]);
    }
  }, [roomsList]);

  const transformValue = (id: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!id && !defaultValue && !isFieldChanged) {
        reject('No ID provided for valueTransform function');
      } else if (isFieldChanged) {
        resolve('');
      } else {
        const roomById = roomsList.find((room) => (id ? room.id === id : room.id === defaultValue));
        if (roomById) {
          resolve(roomById.number);
        }
      }
    });
  };

  return (
    <Select
      options={rooms}
      asyncValueTransformer={transformValue}
      placeholder={t('placeholders.choseRoom')}
      defaultValue={defaultValue}
      {...props}
    />
  );
};

export default RoomsSelect;
