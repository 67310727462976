import {styled, Typography} from '@innowise-group/ui-kit';

export const StatusesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 100%;
`;

export const StatusContainer = styled.div`
  z-index: 999;
  width: 100%;
  padding: 3px;
`;

export const StatusBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({color}) => color};
`;

export const Status = styled(Typography)<{color?: string}>`
  margin-left: 5px;
  color: ${({color}) => color};
`;

export const StatusDescription = styled(Status)`
  margin-left: 17px;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: ${({theme}) => theme.palette.general.darkGrey[60]};
`;
