import {Link} from 'react-router-dom';
import {styled} from '../theme-provider';

export const TabsContainer = styled.ul<{noScroll?: boolean}>`
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.palette.general.lightGrey[60]};

  @media (max-width: 993px) {
    max-width: 760px;
    overflow-y: ${({noScroll}) => (noScroll ? 'initial' : 'scroll')};
  }
  @media (max-width: 481px) {
    margin: 0;
    min-height: 40px;
  }
`;

export const TabContainer = styled.li<{selected: boolean}>`
  position: relative;
  padding: 0 20px;
  margin: 0;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  opacity: ${({selected}) => (selected ? 1 : 0.6)};
  white-space: nowrap;

  &:hover {
    background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
    opacity: 1;
  }

  @media (max-width: 481px) {
    padding: 0 10px;
  }
`;

export const TabSelectionLine = styled.div`
  height: 2px;
  border-radius: 3px 3px 0 0;
  background-color: ${({theme}) => theme.palette.general.smart};

  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;

  @media (max-width: 481px) {
    left: 8px;
    right: 8px;
  }
`;

export const NavigationLink = styled(Link)`
  text-decoration: none;
`;
