import React from 'react';
import {withForm} from '../../ui-kit';
import * as Styled from './languages-select.styles';

const languages = [
  {title: 'Русский', value: 'ru'},
  {title: 'English', value: 'en'},
];

type SelectLanguageFormType = {
  value: string;
  onLanguageChange: (language: string) => void;
};

const SelectLanguage: React.FC<SelectLanguageFormType> = (props) => {
  const transformValue = (value) => {
    return languages.find((language) => language.value === value).title;
  };

  return (
    <Styled.LanguagesSelect
      onValueChange={props.onLanguageChange}
      value={props.value}
      options={languages}
      withSearch={false}
      valueTransformer={transformValue}
    />
  );
};

export default withForm(SelectLanguage);
