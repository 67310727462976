import {Input, styled} from '@innowise-group/ui-kit';

export const PageSearchBarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 993px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
`;

export const SearchNameInput = styled(Input)`
  /* @media (max-width: 391px) {
    margin-bottom: 10px;
  } */
`;

export const FormsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;

  @media (max-width: 993px) {
    & > * {
      margin-left: 0;
    }
  }
`;

export const SearchFormContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 993px) {
    & > * {
      margin-left: 0;
    }
  }
`;
