import {styled} from '../theme-provider';

export const RegularText = styled.p`
  font-size: 14px;
  margin: 0;
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
  /* font-family: 'Manrope sans-seri' */

  @media (max-width: 769px) {
    font-size: 12px;
  }
  @media (max-width: 481px) {
    font-size: 10px;
  }
`;

export const H3Text = styled(RegularText)`
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 769px) {
    font-size: 14px;
  }
  @media (max-width: 481px) {
    font-size: 12px;
  }
`;

export const H1Text = styled(RegularText)`
  font-size: 26px;
  font-weight: bold;

  @media (max-width: 769px) {
    font-size: 20px;
  }
  @media (max-width: 481px) {
    font-size: 15px;
  }
`;
