import {styled} from '@innowise-group/ui-kit';

export const ToggleContainer = styled.button<{isLight: boolean}>`
  background-color: transparent;
  border: 1px solid
    ${({theme, isLight}) => (isLight ? theme.palette.general.lightGrey[100] : theme.palette.general.darkGrey[20])};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  width: 60px;
  overflow: hidden;
  position: relative;

  @media (max-width: 769px) {
    height: 26px;
    width: 52px;
  }
`;

export const IconsContainer = styled.div<{isLight: boolean}>`
  position: absolute;
  display: inline-block;
  transition: 0.3s;
  left: ${({isLight}) => (isLight ? '6px' : '36px')};

  @media (max-width: 769px) {
    left: ${({isLight}) => (isLight ? '4px' : '30px')};
  }
`;

export const Ball = styled.div<{isLight: boolean}>`
  background-color: ${({theme, isLight}) =>
    isLight ? theme.palette.general.lightGrey[100] : theme.palette.general.darkGrey[20]};
  height: 22px;
  width: 22px;
  border-radius: 50%;
  transition: all 0.3s linear;
  position: relative;
  z-index: 10;
  transform: ${({isLight}) => (!isLight ? 'translateX(-2px)' : 'translateX(26px)')};
  -webkit-transform: ${({isLight}) => (!isLight ? 'translate3d(-2px,0,0)' : 'translate3d(26px,0,0)')};

  @media (max-width: 769px) {
    height: 16px;
    width: 16px;
    transform: ${({isLight}) => (!isLight ? 'translateX(-2px)' : 'translateX(24px)')};

    @supports (-webkit-touch-callout: none) {
      -webkit-transform: ${({isLight}) => (!isLight ? 'translate3d(-10px,0,0)' : 'translate3d(13px,0,0)')};
    }
  }
`;
