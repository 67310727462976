import {styled, Typography} from '@innowise-group/ui-kit';

export const AppContainer = styled.section`
  flex: 1;
  display: flex;
  overflow: hidden;
  background-color: ${({theme}) => theme.palette.general.lightGrey[40]};
`;

export const TogglesContainer = styled.div`
  display: none;
  @media (max-width: 769px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    margin-top: 30px;
  }
`;

export const HelpLink = styled.a`
  position: absolute;
  bottom: 120px;
  width: inherit;
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  color: ${({theme}) => theme.palette.info.default};
  &:hover {
    background: ${({theme}) => theme.palette.general.lightGrey[20]};
  }

  @media (max-width: 769px) {
    bottom: 80px;
  }
`;

export const SupportLink = styled(HelpLink)`
  bottom: 70px;
  @media (max-width: 769px) {
    bottom: 40px;
  }
`;

export const IconContainer = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HelpText = styled(Typography)`
  position: absolute;
  left: 55px;
  white-space: nowrap;
  font-weight: 400;
  color: ${({theme}) => theme.palette.generalAlt.blue};
`;

export const VersionText = styled(Typography)`
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  font-weight: 400;
  left: 32%;
  color: ${({theme}) => theme.palette.general.darkGrey[40]};
  white-space: nowrap;
  &:hover {
    color: ${({theme}) => theme.palette.general.lightGrey[100]};
    text-decoration: underline;
  }
  @media (max-width: 769px) {
    bottom: 10px;
    left: 44%;
  }
`;
