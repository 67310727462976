import {Modal, modalManager, Typography} from '@innowise-group/ui-kit';
import {Employee, injectable, Workspace} from '@innowise-group/core';
import {Observable} from 'rxjs';
import {
  BookingWorkspaceForm,
  BookingWorkspaceValues,
  ExcludeDates,
  WorkspacesBookingList,
  ChooseOfficeFloorForm,
  ChooseOfficeFloorFormValues,
} from '@shared-components';
import {FloorsModalsFacade} from './floors-modals-facade.types';
import {FloorsContainerTypes} from '../floors-ioc.types';
import {UploadFloorForm, UploadFloorFormValues} from '../../components/upload-floor-form';
import {UploadFloorConfirmationForm} from '../../components/upload-floor-confirmation-form';
import {AddWorkspaceForm, AddWorkspaceValues} from '../../components/add-workspace-form';
import {EditRoomValues} from '../../components/edit-room-form/edit-room-form.component';
import {EditRoomForm} from '../../components/edit-room-form';
import {Dispatch, SetStateAction} from 'react';

@injectable()
class FloorsModalsFacadeService implements FloorsModalsFacade {
  public static readonly type = FloorsContainerTypes.FloorsModalsFacade;

  public openInfoModal(text: string, title: string, button: string): void {
    modalManager.baseModal(<Typography>{text}</Typography>, {title, button});
  }

  public openPlanUploadModal(handler: (values: UploadFloorFormValues) => void, title: string): void {
    modalManager.open(({close}) => (
      <Modal.Container>
        <Modal.Header title={title} onClose={close} />
        <Modal.Body>
          <UploadFloorForm onSubmit={handler} onCancel={close} />
        </Modal.Body>
      </Modal.Container>
    ));
  }
  public openConfirmationModal(
    title?: string,
    text?: string,
    confirmationText?: string,
    cancelText?: string,
    onComplete?: () => void,
  ): Observable<void> {
    return new Observable((observer) => {
      modalManager.confirmationModal({
        text,
        title,
        confirmationText,
        cancelText,
        onApprove: () => {
          observer.next();
        },
        onComplete: onComplete
          ? onComplete
          : () => {
              observer.complete();
            },
      });
    });
  }

  public openUploadConfirmationModal(
    title?: string,
    text?: string,
    confirmationText?: string,
    cancelText?: string,
  ): Observable<boolean> {
    return new Observable((observer) => {
      modalManager.open(({close}) => {
        const onHandlerSubmit = (values: UploadFloorFormValues) => {
          observer.next(values.toSaveArrangements);
          close();
        };
        return (
          <Modal.Container>
            <Modal.Header title={title} onClose={close} />
            <Modal.Body>{text && <Typography>{text}</Typography>}</Modal.Body>
            <Modal.Body>
              <UploadFloorConfirmationForm
                onSubmit={onHandlerSubmit}
                initialValues={{
                  toSaveArrangements: true,
                  confirmationText,
                  cancelText,
                  onCancel: close,
                }}
              />
            </Modal.Body>
          </Modal.Container>
        );
      });
    });
  }

  public openWorkspaceActionsModal(
    handler: (values: AddWorkspaceValues) => void,
    officeId: string,
    initialValues?: Partial<AddWorkspaceValues>,
    title?: string,
    isVirtual?: boolean,
    datesToExclude?: ExcludeDates,
    revertCallback?: Dispatch<SetStateAction<Workspace[]>>,
  ): void {
    modalManager.open(({close}) => {
      const onHandlerSubmit = (values: AddWorkspaceValues) => {
        handler(values);
        close();
      };
      return (
        <Modal.Container>
          <Modal.Header title={title} onClose={close} />
          <Modal.Body>
            <AddWorkspaceForm
              onSubmit={onHandlerSubmit}
              initialValues={initialValues}
              onCancel={close}
              isVirtual={isVirtual}
              datesToExclude={datesToExclude}
              replaceEmployeeModal={this.openConfirmationModal.bind(this)}
              warningModal={this.openInfoModal.bind(this)}
              officeId={officeId}
              revertCallback={revertCallback}
            />
          </Modal.Body>
        </Modal.Container>
      );
    });
  }

  public openEditRoomFormModal(
    handler: (values: EditRoomValues) => void,
    initialValues?: Partial<EditRoomValues>,
    title?: string,
  ): void {
    modalManager.open(({close}) => {
      const onHandlerSubmit = (values: EditRoomValues) => {
        handler(values);
        close();
      };
      return (
        <Modal.Container>
          <Modal.Header title={title} onClose={close} />
          <Modal.Body>
            <EditRoomForm onSubmit={onHandlerSubmit} initialValues={initialValues} onCancel={close} />
          </Modal.Body>
        </Modal.Container>
      );
    });
  }

  public openBookingWorkspaceForm(
    handler?: (values?: BookingWorkspaceValues) => Observable<unknown>,
    initialValues?: Partial<BookingWorkspaceValues>,
    title?: string,
    submitButton?: string,
    employee?: Employee,
    workspacesList?: WorkspacesBookingList,
  ): void {
    modalManager.open(({close}) => (
      <Modal.Container>
        <Modal.Header title={title} onClose={close} />
        <Modal.Body>
          <BookingWorkspaceForm
            submitButton={submitButton}
            initialValues={initialValues}
            onCancel={close}
            onSubmit={handler}
            employee={employee}
            workspacesList={workspacesList}
            confirmationModal={this.openConfirmationModal.bind(this)}
            withValidateBookedWorkspace
          />
        </Modal.Body>
      </Modal.Container>
    ));
  }

  public showSecondPlanForm(
    title?: string,
    permissions?: {[key: string]: number[]},
  ): Observable<ChooseOfficeFloorFormValues> {
    return new Observable((observer) => {
      modalManager.open(({close}) => {
        const onHandlerSubmit = (values: ChooseOfficeFloorFormValues) => {
          observer.next(values);
          observer.complete();
          close();
        };
        return (
          <Modal.Container>
            <Modal.Header title={title} onClose={close} />
            <Modal.Body>
              <ChooseOfficeFloorForm
                onCancel={close}
                onSubmit={onHandlerSubmit}
                submitName="show"
                permissions={permissions}
              />
            </Modal.Body>
          </Modal.Container>
        );
      });
    });
  }
}

export default FloorsModalsFacadeService;
