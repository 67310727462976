import {keyframes} from '../../theme-provider';

export const appearAnimation = keyframes`
  from {
    opacity: 0.7;
    transform: translateY(-10px);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const appearAnimationReversed = keyframes`
  from {
    opacity: 0.7;
    transform: translateY(10px);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;
