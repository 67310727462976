import React, {useContext, useEffect, useMemo} from 'react';
import {GlobalThemeContext, Icon, useTheme} from '@innowise-group/ui-kit';
import * as Styled from './theme-switch.styles';

const ThemeSwitch: React.FC = () => {
  const {theme: themeValue, themeSwitchHandler} = useContext(GlobalThemeContext);
  const theme = useTheme();

  useEffect(() => {
    if (themeValue) {
      localStorage.setItem('theme', themeValue);
    }
  }, [themeValue]);

  const onThemeSwitchClick = () => {
    themeSwitchHandler(themeValue === 'dark' ? 'light' : 'dark');
  };

  const isLight = useMemo(() => themeValue === 'light', [themeValue]);

  return (
    <Styled.ToggleContainer onClick={onThemeSwitchClick} isLight={isLight}>
      <Styled.IconsContainer isLight={isLight}>
        <Icon
          type={isLight ? 'u_moon' : 'u_brightness'}
          size={16}
          fill={isLight ? theme.palette.general.lightGrey[100] : theme.palette.general.white}
        />
      </Styled.IconsContainer>
      <Styled.Ball isLight={isLight} />
    </Styled.ToggleContainer>
  );
};

export default ThemeSwitch;
