import {Controller, ControllerProps, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Form, Input} from '@innowise-group/ui-kit';
import {WorkspaceBookType} from '@innowise-group/core';

export enum WorkspaceCommentFormFields {
  Comment = 'comment',
}

export interface WorkspaceCommentFormValues {
  comment: string;
}

const WorkspaceCommentForm: React.FC<
  Omit<ControllerProps, 'render' | 'name'> & {employee?: string; status?: WorkspaceBookType}
> = ({defaultValue, employee, status, ...props}) => {
  const {control} = useFormContext();

  const {t} = useTranslation();

  const checkIfRequired = () => {
    return !employee && status === 'Reserved';
  };

  return (
    <Controller
      {...props}
      name={WorkspaceCommentFormFields.Comment}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: checkIfRequired(),
          message:
            status === 'Reserved' ? t('modals.messages.reservedWorkspaceFieldsErrors') : t('modals.messages.required'),
        },
      }}
      render={({field: {onChange, value}}) => (
        <Form.Field label={t('modals.comment')}>
          <Input maxLength={96} defaultValue={value} onChange={onChange} placeholder={t('placeholders.comment')} />
        </Form.Field>
      )}
    />
  );
};

export default WorkspaceCommentForm;
