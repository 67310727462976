import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {getOverlappingDaysInIntervals, setHours} from 'date-fns';
import {Observable, defer, of, concatMap, map, finalize} from 'rxjs';
import {
  Employee,
  EmployeesApiService,
  EmployeeWorkspace,
  useService,
  Workspace,
  WorkspaceBookType,
  WorkspacesApiService,
} from '@innowise-group/core';
import {Button, Divider, Form, withForm, WithFormProps} from '@innowise-group/ui-kit';
import {ExcludeDates} from '@shared-components';
import {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {SelectStatusFormValues, WorkspaceStatusForm} from '../workspace-status-form';
import {WorkspaceNumberFormValues} from '../workspace-number-form';
import {
  WorkspaceEmployeeForm,
  WorkspaceEmployeeFormValues,
  WorkspaceEmployeeFormFields,
} from '../workspace-employee-form';
import {WorkspaceBookingDateForm, WorkspaceBookingDateFormValues} from '../workspace-booking-date-form';
import {WorkspaceCommentForm, WorkspaceCommentFormValues, WorkspaceCommentFormFields} from '../workspace-comment-form';
import {SelectStatusFormFields} from '../workspace-status-form';
import * as Styled from './add-workspace-form-styles';

type AddWorkspaceRoomFormValue = {
  room: {
    id: string;
    number: string;
  };
};

export type AddWorkspaceValues = WorkspaceNumberFormValues &
  SelectStatusFormValues &
  WorkspaceEmployeeFormValues &
  WorkspaceBookingDateFormValues &
  WorkspaceCommentFormValues &
  AddWorkspaceRoomFormValue;

type AddWorkspaceFormProps = {
  isVirtual: boolean;
  datesToExclude?: ExcludeDates;
  officeId: string;
  replaceEmployeeModal: (
    title?: string,
    text?: string,
    confirmationText?: string,
    cancelText?: string,
  ) => Observable<void>;
  warningModal?: (text: string, title: string, button: string) => void;
  revertCallback?: Dispatch<SetStateAction<Workspace[]>>;
};

const AddWorkspaceForm: React.FC<WithFormProps<AddWorkspaceValues> & AddWorkspaceFormProps> = ({
  onSubmit,
  onCancel,
  initialValues = {},
  isVirtual = false,
  datesToExclude,
  officeId,
  replaceEmployeeModal,
  warningModal,
  revertCallback,
}) => {
  const {
    handleSubmit,
    formState: {errors},
    watch,
  } = useFormContext();

  const [isEditing, setIsEditing] = useState(false);

  const employeesApi = useService(EmployeesApiService);
  const workspacesApi = useService(WorkspacesApiService);
  const {t} = useTranslation();
  const {
    employee: prevEmployee,
    comment,
    dateRange: [startDay, endDay],
    status: prevStatus,
    id,
  } = initialValues;

  const confirmReplaceEmployee = useCallback(
    (
      tempWorkspace?: EmployeeWorkspace,
      permWorkspace?: EmployeeWorkspace,
      status?: WorkspaceBookType,
      employee?: Employee,
      hasPermanentWorkspace?: boolean,
      temporaryWorkspacesInOffice?: EmployeeWorkspace[],
      temporaryCurrentWorkspacesInOffice?: EmployeeWorkspace,
      isDatesCollapsed?: boolean,
    ): Observable<boolean> => {
      return defer(() => {
        return workspacesApi.getWorkspaceById(id).pipe(
          concatMap((previousWorkspace) => {
            const {
              status: prevStatus,
              employee: {id: prevEmployee},
            } = previousWorkspace;

            if (hasPermanentWorkspace && status === 'Booked') {
              return replaceEmployeeModal(
                t('modals.replaceWorkspaceStatusTitle'),
                t('modals.hasWorkspaceInThisOffice', {
                  name: employee?.fullName,
                  adress: `${permWorkspace?.city}, ${permWorkspace?.address}`,
                }),
                t('buttons.yes'),
                t('buttons.no'),
              ).pipe(map(() => false));
            }
            if (isDatesCollapsed && prevStatus === 'Booked') {
              return replaceEmployeeModal(
                t('modals.changeWorkspaceStatusTitle'),
                t('modals.changeWorkspaceStatusConfirmationText'),
                t('buttons.proceed'),
                t('buttons.cancel'),
              ).pipe(map(() => false));
            }
            if (prevStatus === 'Occupied' && (status === 'Reserved' || status === 'Free')) {
              return replaceEmployeeModal(
                t('modals.replaceWorkspaceStatusTitle'),
                t('modals.hasWorkspaceInThisOffice', {
                  name: employee?.fullName,
                  adress: `${permWorkspace?.city}, ${permWorkspace?.address}`,
                }),
                t('buttons.yes'),
                t('buttons.no'),
              ).pipe(map(() => false));
            }
            if (temporaryWorkspacesInOffice?.length && status === 'Occupied') {
              return replaceEmployeeModal(
                t('modals.replaceWorkspaceStatusTitle'),
                t('modals.changeCurrentWorkspaceStatusConfirmationText', {
                  name: employee?.fullName,
                  seatNumber: initialValues.number,
                  roomName: initialValues.room.number,
                  adress: `${temporaryWorkspacesInOffice[0]?.city}, ${temporaryWorkspacesInOffice[0]?.address}`,
                }),
                t('buttons.yes'),
                t('buttons.no'),
              ).pipe(map(() => false));
            }
            if (tempWorkspace && status === 'Booked') {
              return replaceEmployeeModal(
                t('modals.replaceWorkspaceStatusTitle'),
                t('modals.hasTemporaryWorkspaceMessage', {
                  name: employee?.fullName,
                  status: t(`statuses.${status.toLowerCase()}_secondary`),
                  workspace: temporaryWorkspacesInOffice?.map(({number}) => number).join(', №'),
                  room: tempWorkspace?.room,
                  floor: tempWorkspace?.floor,
                }),
                t('buttons.yes'),
                t('buttons.no'),
              ).pipe(map(() => false));
            }
            if (
              (prevStatus === 'Reserved' && status === 'Free') ||
              (prevStatus === 'Occupied' && status === 'Occupied' && prevEmployee !== employee.id)
            ) {
              return replaceEmployeeModal(
                t('modals.replaceWorkspaceTableStatusTitle'),
                t('modals.replaceWorkspaceTableStatusText'),
                t('buttons.yes'),
                t('buttons.no'),
              ).pipe(map(() => false));
            }
            if (permWorkspace && status !== 'Booked' && status !== 'Reserved' && prevEmployee !== employee.id) {
              return replaceEmployeeModal(
                t('modals.replaceWorkspaceStatusTitle'),
                t('modals.hasPermanentWorkspaceMessage', {
                  name: employee?.fullName,
                  workspace: permWorkspace?.number,
                  room: permWorkspace?.room,
                  floor: permWorkspace?.floor,
                  office: `${permWorkspace?.city}, ${permWorkspace?.address}`,
                }),
                t('buttons.yes'),
                t('buttons.no'),
              ).pipe(map(() => true));
            }
            return of(false);
          }),
        );
      });
    },
    [initialValues.number, initialValues.room.number, replaceEmployeeModal, t, workspacesApi, id],
  );

  const onSubmitHandler = useCallback(
    ({employee, status, dateRange: [startDay, endDay], comment, ...rest}: AddWorkspaceValues) => {
      const targetEmployee = employee || prevEmployee;

      const isDatesCollapsed = datesToExclude.some(
        ({range}) =>
          range.start.getTime() > new Date(startDay).getTime() && range.end.getTime() < new Date(endDay).getTime(),
      );

      if (status) {
        const values: AddWorkspaceValues = {
          employee,
          status,
          dateRange: [startDay, endDay],
          comment: status === 'Free' ? null : comment,
          ...rest,
        };
        if (!targetEmployee) return onSubmit(values);

        setIsEditing(true);

        employeesApi
          .getEmployeeById(targetEmployee)
          .pipe(
            concatMap((employeeData) => {
              const permanentWorkspace = employeeData?.workspaces?.find((ws) => {
                return ws.status === 'Occupied' || ws.status === 'Remote';
              });

              return permanentWorkspace
                ? workspacesApi
                    .getWorkspaceById(permanentWorkspace.id)
                    .pipe(map((workspaceToRevert) => ({employeeData, workspaceToRevert, permanentWorkspace})))
                : of({workspaceToRevert: null, employeeData, permanentWorkspace});
            }),
          )
          .subscribe(({employeeData, permanentWorkspace, workspaceToRevert}) => {
            const temporaryWorkspacesInOffice = employeeData.workspaces?.filter(
              (ws) => ws.status === 'Booked' && ws.officeId === officeId && ws.id !== initialValues.id,
            );
            const selectedDates = {
              start: setHours(new Date(startDay), 9),
              end: setHours(new Date(endDay), 18),
            };
            const temporaryCurrentWorkspacesInOffice = employeeData.workspaces?.find(
              (ws) => ws.status === 'Booked' && ws.id === initialValues.id,
            );
            const temporaryWorkspaceOnThisDate = temporaryWorkspacesInOffice.find((ws) => {
              const existingBookingDates = {start: new Date(ws?.startDay), end: new Date(ws?.endDay)};
              const bookingsOverlap =
                status === 'Booked' && getOverlappingDaysInIntervals(selectedDates, existingBookingDates);
              if (bookingsOverlap) {
                return ws;
              }
              return false;
            });
            const hasPermanentWorkspace = permanentWorkspace?.officeId === officeId;

            confirmReplaceEmployee(
              temporaryWorkspaceOnThisDate,
              permanentWorkspace,
              status,
              employeeData,
              hasPermanentWorkspace,
              temporaryWorkspacesInOffice,
              temporaryCurrentWorkspacesInOffice,
              isDatesCollapsed,
            )
              .pipe(finalize(() => setIsEditing(false)))
              .subscribe((mayRevert) => {
                onSubmit(values);
                if (mayRevert && workspaceToRevert) {
                  revertCallback((values) => [...values, workspaceToRevert]);
                }
              });
          });
      }
    },
    [
      employeesApi,
      onSubmit,
      officeId,
      confirmReplaceEmployee,
      initialValues.id,
      prevEmployee,
      datesToExclude,
      revertCallback,
      workspacesApi,
    ],
  );

  return (
    <Form.Container onSubmit={handleSubmit(onSubmitHandler)}>
      {watch(SelectStatusFormFields.WorkspaceStatus) === 'Reserved' && (
        <Styled.FormError>{errors?.comment?.message || errors?.employee?.message}</Styled.FormError>
      )}
      <Form.Layout layoutType="grid">
        {/* <WorkspaceNumberForm defaultValue={initialValues?.number} /> */}
        <WorkspaceStatusForm defaultValue={prevStatus} isVirtual={isVirtual} />
      </Form.Layout>

      <Divider />

      <Form.Layout layoutType="grid" inset="top">
        <WorkspaceEmployeeForm
          defaultValue={prevEmployee}
          status={watch(SelectStatusFormFields.WorkspaceStatus, prevStatus)}
          comment={watch(WorkspaceCommentFormFields.Comment, comment)}
        />
      </Form.Layout>

      <Divider />

      <Form.Layout layoutType="vertical" inset="top">
        <WorkspaceBookingDateForm
          defaultValue={{startDay, endDay}}
          status={watch(SelectStatusFormFields.WorkspaceStatus, prevStatus)}
          // datesToExclude={datesToExclude}
        />
      </Form.Layout>

      <Divider />

      {watch(SelectStatusFormFields.WorkspaceStatus, prevStatus) !== 'Free' && (
        <Form.Layout layoutType="vertical" inset="top">
          <WorkspaceCommentForm
            defaultValue={comment}
            status={watch(SelectStatusFormFields.WorkspaceStatus, prevStatus)}
            employee={watch(WorkspaceEmployeeFormFields.Employee, prevEmployee)}
          />
        </Form.Layout>
      )}

      <Form.Actions>
        <Button withLoader={isEditing} disabled={isEditing} type="submit">
          {t('buttons.save')}
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
      </Form.Actions>
    </Form.Container>
  );
};

export default withForm(AddWorkspaceForm);
