import {useState, useMemo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Location,
  LocationsApiService,
  Office,
  OfficesApiService,
  useService,
  WorkspacesCount,
  OfficeStatistic,
  useDebounce,
} from '@innowise-group/core';
import {useNavigate} from 'react-router-dom';
import {combineLatest, switchMap, tap} from 'rxjs';
import {OfficesModalsFacadeService} from '../../services/offices-modals-facade';
import {AddOfficeFormValues} from '../../components/add-office-form';

type LocationStatisticType = {
  location: string;
  locationStatistic: WorkspacesCount;
  officesStatistic?: Array<{
    address: string;
    workspacesCount: WorkspacesCount;
    officeId: string;
  }>;
};

export interface UseOfficesOverviewResult {
  countries: Array<{country: string; officesCount: number}>;
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
  officesLocations: Array<{
    location: string;
    offices: Office[];
    locationId: string;
  }>;
  handleOfficeClick: (officeId: string) => void;
  handleAddNewLocation: () => void;
  handleDeleteLocation: (id: string) => void;
  handleAddNewOffice: (initialValues?: Partial<AddOfficeFormValues>) => void;
  handleEditOffice: (id: string) => void;
  handleDeleteOffice: (id: string) => void;
  isLoading: boolean;
  isStatisticLoading: boolean;
  officesLocationsStatistic: Array<LocationStatisticType>;
  handleSearch: (event: React.FormEvent<HTMLOrSVGElement & HTMLInputElement>) => void;
  handleDeleteFloor: (handle: () => void) => void;
}

export function useOfficesOverview(): UseOfficesOverviewResult {
  const navigate = useNavigate();
  const locationsApi = useService(LocationsApiService);
  const officesApi = useService(OfficesApiService);
  const officesModalsFacade = useService(OfficesModalsFacadeService);

  const {t} = useTranslation();

  const [locations, setLocations] = useState<Location[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>(t('pages.offices.allOffices'));
  const [overallStatisticOffices, setOverallStatisticOffices] = useState<OfficeStatistic[]>([]);
  const [search, setSearch] = useState('');

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    setSelectedCountry(t('pages.offices.allOffices'));
  }, [t]);

  useEffect(() => {
    setIsLoading(true);
    const subscriber = combineLatest([locationsApi.getLocations(), officesApi.getOffices()]).subscribe(
      ([fetchedLocations, fetchedOffices]) => {
        setLocations(fetchedLocations);
        setOffices(fetchedOffices);
        setIsLoading(false);
      },
    );
    return () => subscriber.unsubscribe();
  }, [locationsApi, officesApi]);

  useEffect(() => {
    const subscriber = officesApi.getOverallStatisticOffices().subscribe(setOverallStatisticOffices);
    return () => subscriber.unsubscribe();
  }, [officesApi]);

  const countries = useMemo(() => {
    const locationsCountryMap = locations.reduce<Record<string, string[]>>(
      (accumulator, {countryName, id}) => ({
        ...accumulator,
        [countryName]: (accumulator[countryName] || []).concat([id]),
      }),
      {},
    );
    const countries = Object.entries(locationsCountryMap).map(([country, locations]) => ({
      country,
      officesCount: offices.filter((office) => locations.includes(office.locationId)).length,
    }));
    return [{country: t('pages.offices.allOffices'), officesCount: offices.length}, ...countries];
  }, [t, locations, offices]);

  useEffect(() => {
    if (!debouncedValue) {
      setSelectedCountry(t('pages.offices.allOffices'));
    } else {
      setSelectedCountry(
        (value) =>
          [{countryName: value, city: value}, ...locations].find(
            ({countryName, city}) =>
              countryName?.toLowerCase().includes(debouncedValue?.toLowerCase()) ||
              city?.toLowerCase().includes(debouncedValue?.toLowerCase()),
          )?.countryName,
      );
    }
  }, [debouncedValue, locations, setSelectedCountry, t]);

  const officesLocations = useMemo(
    () =>
      Object.entries(
        locations
          .filter(
            ({countryName, city}) =>
              countryName?.toLowerCase().includes(debouncedValue?.toLowerCase()) ||
              city?.toLowerCase().includes(debouncedValue?.toLowerCase()),
          )
          .filter((location) => location.countryName === selectedCountry)
          .reduce<{[city: string]: Office[]}>((accumulator, location) => {
            return {
              ...accumulator,
              [location.id]: offices.filter((office) => office.locationId === location.id),
            };
          }, {}),
      ).map(([locationId, offices]) => {
        const locationById = locations.find((item) => item.id === locationId);
        return {location: locationById ? locationById.city : '', offices, locationId};
      }),
    [locations, offices, selectedCountry, debouncedValue],
  );

  const officesLocationsStatistic = useMemo(() => {
    const overallStatistic = {
      location: t('pages.offices.totalInfo'),
      locationStatistic: {},
    };
    (overallStatisticOffices || []).forEach(({workspacesCount}) => {
      Object.entries(workspacesCount).forEach(([key, value]) => {
        if (!overallStatistic.locationStatistic[key]) {
          overallStatistic.locationStatistic[key] = value;
        } else {
          overallStatistic.locationStatistic[key] += value;
        }
      });
    });
    const locationsStatistic = (overallStatisticOffices || []).map(({id, workspacesCount}) => {
      const locationId = offices.length && offices.find((office) => office.id === id)?.locationId;
      const officeAddress = offices.length && offices.find((office) => office.id === id)?.address;
      const country = locations.length && locations.find((location) => location.id === locationId)?.countryName;
      const city = locations.length && locations.find((location) => location.id === locationId)?.city;
      const locationStatistic = {
        location: country,
        locationStatistic: {},
        officesStatistic: [{address: `${city}, ${officeAddress}`, workspacesCount, officeId: id}],
      };
      Object.entries(workspacesCount).forEach(([key, value]) => {
        if (!locationStatistic.locationStatistic[key]) {
          locationStatistic.locationStatistic[key] = value;
        } else {
          locationStatistic.locationStatistic[key] += value;
        }
      });
      return locationStatistic;
    }) as Array<LocationStatisticType>;
    const overallLocationStatistic: Array<LocationStatisticType> = Object.values(
      locationsStatistic.reduce((accumulator, location) => {
        if (!accumulator[location.location]) {
          accumulator[location.location] = Object.assign({}, location);
        } else {
          Object.keys(location.locationStatistic).forEach((key) => {
            accumulator[location.location].locationStatistic[key] += location.locationStatistic[key];
          });
          accumulator[location.location].officesStatistic.push(...location.officesStatistic);
        }
        return accumulator;
      }, {}),
    );
    const sortedOverallLocationStatistic = overallLocationStatistic.map((obj) => ({
      ...obj,
      officesStatistic: obj.officesStatistic.sort((a, b) => (a.address > b.address ? 1 : -1)),
    }));
    return [overallStatistic, ...sortedOverallLocationStatistic] as Array<LocationStatisticType>;
  }, [t, overallStatisticOffices, offices, locations]);

  const handleSearch = (event: React.FormEvent<HTMLOrSVGElement & HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  return {
    countries,
    selectedCountry,
    setSelectedCountry,
    officesLocations,
    isLoading,
    isStatisticLoading: !overallStatisticOffices.length,
    officesLocationsStatistic,
    handleOfficeClick: (id) => {
      navigate(`/offices/${id}`);
    },
    handleAddNewLocation: () => {
      officesModalsFacade.openAddLocationModal((values) => {
        return locationsApi.addLocation(values).pipe(
          tap((location: Location) => {
            setLocations((values) => [...values, location]);
          }),
        );
      }, t('modals.addNewLocation'));
    },
    handleDeleteLocation: (id: string) => {
      officesModalsFacade
        .openDeleteLocationModal(
          t('modals.deleteCityTitle'),
          t('modals.deleteCityText'),
          t('buttons.delete'),
          t('buttons.cancel'),
        )
        .pipe(switchMap(() => locationsApi.deleteLocation(id)))
        .subscribe(() => {
          setLocations((currentLocations) => currentLocations.filter((location) => location.id !== id));
        });
    },
    handleAddNewOffice: (initialValues?: Partial<AddOfficeFormValues>) => {
      officesModalsFacade.openAddOfficeModal(
        (values: AddOfficeFormValues) => {
          return officesApi.addOffice(values).pipe(
            tap((office: Office) => {
              return setOffices((values) => [...values, office]);
            }),
          );
        },
        initialValues,
        {title: t('modals.addNewOffice')},
      );
    },
    handleEditOffice: (id: string) => {
      combineLatest([officesApi.getOfficeById(id), officesApi.getOfficeFloors(id)]).subscribe(
        ([{locationId, managers, address, postcode, addressEng, addressRu, jiraOfficeId}, floors]) => {
          const initialValues: Partial<AddOfficeFormValues> = {
            location: locationId,
            managers:
              managers?.flatMap(({id, managerType}) =>
                managerType.map((type) => ({office_manager: id, manager_type: type})),
              ) ?? [],
            floors: floors.map((item) => ({number: item.number, isVirtual: item.is_virtual})),
            address: addressRu,
            address_eng: addressEng,
            index: postcode,
            jira_office_id: jiraOfficeId,
          };

          officesModalsFacade.openAddOfficeModal(
            (values: AddOfficeFormValues) => {
              return officesApi.editOffice(id, values).pipe(
                tap((office: Office) =>
                  setOffices((currentOffices) => {
                    const officeIndexById = currentOffices.findIndex((currentOffice) => currentOffice.id === office.id);
                    const result = currentOffices.slice();
                    result.splice(officeIndexById, 1, office);
                    return result;
                  }),
                ),
              );
            },
            initialValues,
            {title: t('modals.editOffice')},
          );
        },
      );
    },
    handleDeleteOffice: (id: string) => {
      officesModalsFacade
        .openDeleteOfficeModal(
          t('modals.deleteOfficeTitle'),
          t('modals.deleteOfficeText'),
          t('buttons.delete'),
          t('buttons.cancel'),
        )
        .pipe(switchMap(() => officesApi.deleteOffice(id)))
        .subscribe(() => {
          setOffices((currentOffices) => currentOffices.filter((office) => office.id !== id));
        });
    },
    handleSearch,
    handleDeleteFloor: (handle) => {
      officesModalsFacade
        .openDeleteFloorModal(
          t('modals.deleteFloorTitle'),
          t('modals.deleteFloorText'),
          t('buttons.delete'),
          t('buttons.cancel'),
        )
        .subscribe(() => {
          handle();
        });
    },
  };
}
