import {Typography} from '../typography';
import * as Styled from './paper.styles';

interface PaperProps {
  title: string | React.ReactNode;
}

const renderTitle = (title: string | React.ReactNode): React.ReactNode => {
  switch (typeof title) {
    case 'string':
      return <Typography type="h3">{title}</Typography>;
    default:
      return title;
  }
};

const Paper: React.FC<PaperProps> = ({title, children, ...props}) => {
  return (
    <Styled.PaperContainer {...props}>
      {title ? <Styled.TitleContainer>{renderTitle(title)}</Styled.TitleContainer> : null}
      {children}
    </Styled.PaperContainer>
  );
};

export default Paper;
