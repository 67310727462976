import React from 'react';
import {useTranslation} from 'react-i18next';
import {Logo, Icon, useTheme} from '@innowise-group/ui-kit';

import * as Styled from './mobile.view.styles';

import background from '../../assets/background.svg';
import top from '../../assets/top.svg';

import building from '../../assets/emojis/building.svg';
import hand from '../../assets/emojis/hand.svg';
import people from '../../assets/emojis/people.svg';
import user from '../../assets/emojis/user.svg';

const EMOJIS = [building, hand, people, user];

interface MobileViewProps {
  handleSignIn: () => void;
  openReleaseNotes: () => void;
}

const MobileView: React.FC<MobileViewProps> = ({handleSignIn, openReleaseNotes}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const color = theme.palette.general.paper;

  return (
    <Styled.Container background={background}>
      <Styled.DividersGroup background={top} />

      <Styled.ContentHeader>
        <Logo logoColor={color} size={'large'} />
        <Styled.HeaderText>ITS Hotelling Tool</Styled.HeaderText>
      </Styled.ContentHeader>

      <Styled.AuthButton onClick={handleSignIn}>
        <Icon type="color_google_icon" size={24} />
        <Styled.AuthButtonText>Continue with Google</Styled.AuthButtonText>
      </Styled.AuthButton>

      <Styled.EmojisContainer>
        {EMOJIS.map((emoji) => (
          <Styled.EmojiContainer key={emoji}>
            <Styled.EmojiImage src={emoji} alt="Emoji"></Styled.EmojiImage>
          </Styled.EmojiContainer>
        ))}
      </Styled.EmojisContainer>

      <Styled.Version onClick={openReleaseNotes}>
        {t('pages.version')}: {process.env.REACT_APP_VERSION}
      </Styled.Version>
      <Styled.SupportLinkBlock>
        <Styled.SupportLink href="https://innowise-group.atlassian.net/servicedesk/customer/portals" target="_blank">
          {t('buttons.support')}
        </Styled.SupportLink>
        <Styled.SupportLinkBackground />
      </Styled.SupportLinkBlock>
    </Styled.Container>
  );
};

export default MobileView;
