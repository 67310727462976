import {Container} from 'inversify';

import {GlobalContainerTypes} from '../types';

import {requestContainer} from '../../../modules/requests/services/requests-ioc.container';
import {floorsContainer} from '../../../modules/floors/services/floors-ioc.container';
import {officesContainer} from '../../../modules/offices/services/offices-ioc.container';
import {historyContainer} from '../../../modules/history/services/history-ioc.container';
import {employeesContainer} from '../../../modules/employees/services/employees-ioc.container';
import {geotrackContainer} from '../../../modules/geotrack/services/geotrack-ioc.container';

import {AuthService, Auth} from './auth';
import {HttpService, Http} from './http';
import {AuthenticationService, Authentication} from './authentication';
import {EmployeesApiService, EmployeesApi} from './employees-api';
import {LocationsApi, LocationsApiService} from './locations-api';
import {OfficesApiService, OfficesApi} from './offices-api';
import {LocationsNormalization, LocationsNormalizationService} from './locations-normalization';
import {EmployeesNormalization, EmployeesNormalizationService} from './employees-normalization';
import {OfficesNormalization, OfficesNormalizationService} from './offices-normalization';
import {FloorsApi, FloorsApiService} from './floors-api';
import {WorkspacesApi, WorkspacesApiService} from './workspaces-api';
import {FloorsNormalization, FloorsNormalizationService} from './floors-normalization';
import {RoomsApi, RoomsApiService} from './rooms-api';
import {WorkspacesNormalization, WorkspacesNormalizationService} from './workspaces-normalization';
import {RequestsApi, RequestsApiService} from './requests-api';
import {RequestsNormalization, RequestsNormalizationService} from './requests-normalization';
import {Language, LanguageService} from './language';

const globalContainer = new Container({defaultScope: 'Singleton'});
globalContainer.bind<Auth>(GlobalContainerTypes.Auth).to(AuthService);
globalContainer.bind<Http>(GlobalContainerTypes.Http).to(HttpService);
globalContainer.bind<Authentication>(GlobalContainerTypes.Authentication).to(AuthenticationService);
globalContainer.bind<EmployeesApi>(GlobalContainerTypes.EmployeesApi).to(EmployeesApiService);
globalContainer.bind<LocationsApi>(GlobalContainerTypes.LocationsApi).to(LocationsApiService);
globalContainer.bind<OfficesApi>(GlobalContainerTypes.OfficesApi).to(OfficesApiService);
globalContainer
  .bind<LocationsNormalization>(GlobalContainerTypes.LocationsNormalization)
  .to(LocationsNormalizationService);
globalContainer
  .bind<EmployeesNormalization>(GlobalContainerTypes.EmployeesNormalization)
  .to(EmployeesNormalizationService);
globalContainer.bind<OfficesNormalization>(GlobalContainerTypes.OfficesNormalization).to(OfficesNormalizationService);
globalContainer.bind<FloorsApi>(GlobalContainerTypes.FloorsApi).to(FloorsApiService);
globalContainer.bind<WorkspacesApi>(GlobalContainerTypes.WorkspacesApi).to(WorkspacesApiService);
globalContainer.bind<RoomsApi>(GlobalContainerTypes.RoomsApi).to(RoomsApiService);
globalContainer.bind<FloorsNormalization>(GlobalContainerTypes.FloorsNormalization).to(FloorsNormalizationService);
globalContainer
  .bind<WorkspacesNormalization>(GlobalContainerTypes.WorkspacesNormalization)
  .to(WorkspacesNormalizationService);
globalContainer.bind<RequestsApi>(GlobalContainerTypes.RequestsApi).to(RequestsApiService);
globalContainer
  .bind<RequestsNormalization>(GlobalContainerTypes.RequestsNormalization)
  .to(RequestsNormalizationService);
globalContainer.bind<Language>(GlobalContainerTypes.Language).to(LanguageService);

// TODO: implement lazy loading for containers
globalContainer.load(requestContainer);
globalContainer.load(floorsContainer);
globalContainer.load(officesContainer);
globalContainer.load(historyContainer);
globalContainer.load(employeesContainer);
globalContainer.load(geotrackContainer);

export {globalContainer};
