import {styled, Typography} from '@innowise-group/ui-kit';

export const MyWorkspaceButtonContainer = styled.div<{disabled: boolean}>`
  display: flex;
  align-items: center;
  gap: 3px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px 10px;
  border-radius: 16px;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? '0.25' : '1')};
  &:hover {
    background: ${({theme}) => theme.palette.general.lightGrey[20]};
  }
`;

export const MyWorkspaceButtonText = styled(Typography)`
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
  white-space: nowrap;
  font-weight: 400;
`;
