import React, {useMemo} from 'react';
import {Select, SelectProps} from '@innowise-group/ui-kit';
import {useTranslation} from 'react-i18next';
import {RequestStatus, RequestsStatuses} from '../../core';
import {internationalizeStatus} from '../../../modules/requests/utilities/internationalize-status.utility';

const RequestStatusSelect: React.FC<SelectProps> = ({defaultValue, ...props}) => {
  const {t} = useTranslation();

  const items = useMemo<{value: string; title: string}[]>(() => {
    const objectWithStatuses = RequestsStatuses.reduce<{[key: string]: RequestStatus[]}>((acc, current) => {
      const internationalizedStatus = internationalizeStatus(current);

      if (internationalizedStatus in acc) {
        return {
          ...acc,
          [internationalizedStatus]: [...acc[internationalizedStatus], current],
        };
      } else {
        return {
          ...acc,
          [internationalizedStatus]: [current],
        };
      }
    }, {});

    return Object.entries(objectWithStatuses).map(([internationalizedStatus, requestStatuses]) => {
      return {
        value: JSON.stringify(requestStatuses),
        title: t(`pages.requests.${internationalizedStatus}`),
      };
    });
  }, [t]);

  const transformValue = (value) => {
    return items.find((item) => item.value === value)?.title || null;
  };

  return (
    <Select
      options={items}
      valueTransformer={transformValue}
      placeholder={t('placeholders.status')}
      position="fixed"
      {...props}
    />
  );
};

export default RequestStatusSelect;
