import {Workspace} from '@innowise-group/core';
import {AddWorkspaceValues} from '../components/add-workspace-form';

export const editWorkspaceUtility = (
  element: Workspace,
  values: AddWorkspaceValues & {selectedData: string},
): Workspace => {
  return {
    ...element,
    status:
      values?.selectedData ||
      values?.status === 'Reserved' ||
      values?.status === 'Coworking' ||
      values?.status === 'Remote'
        ? values?.status
        : 'Free',
    employee: {...element.employee, fullName: values?.selectedData, id: values?.employee},
    range: {
      ...element.range,
      startDay: values?.dateRange?.[0]?.toString(),
      endDay: values?.dateRange?.[1]?.toString(),
    },
    comment: values?.comment,
  };
};
