import React from 'react';
import {Employee} from '@innowise-group/core';
import {redirectToHRMProfile} from '@innowise-group/utilities';
import * as Styled from './workspace-view.styles';
import {Icon, InformationTile, Divider} from '@innowise-group/ui-kit';
import {useTranslation} from 'react-i18next';

interface WorkspaceBookingInfoProps {
  employee: Employee;
  range: {
    startDay: string;
    endDay: string;
  };
  comment?: string;
}

const WorkspaceBookingInfo: React.FC<WorkspaceBookingInfoProps> = ({range, employee, comment}) => {
  const {t} = useTranslation();

  const handleUserProfileClick = () => {
    employee.hrm_id && redirectToHRMProfile(employee.hrm_id);
  };

  return (
    <Styled.BookingInfoContainer>
      {range?.startDay && range?.endDay && (
        <Styled.BookingPeriodBlock>
          <Icon type="u_calender" size={16} />
          <Styled.BookingPeriodText>{`${range?.startDay} - ${range?.endDay}`}</Styled.BookingPeriodText>
        </Styled.BookingPeriodBlock>
      )}
      <Styled.WorkspaceEmployeeBlock>
        <Styled.UserPhoto onClick={handleUserProfileClick} isPointer={true} size={36} imageUrl={employee?.photoUrl} />
        <InformationTile
          title={employee?.fullName || t('pages.floors.withoutEmployee')}
          value={employee?.division ?? employee?.unit}
          isEmployeeAssigned={!!employee?.fullName}
        />
      </Styled.WorkspaceEmployeeBlock>
      {comment && (
        <>
          <Divider />
          <Styled.Tile title={`${t('modals.comment')}:`} value={comment} />
        </>
      )}
    </Styled.BookingInfoContainer>
  );
};

export default React.memo(WorkspaceBookingInfo);
