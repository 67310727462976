import {ContainerModule, interfaces} from '@innowise-group/core';
import {RequestsModalsFacade, RequestsModalsFacadeService} from './request-modals-facade';
import {RequestsContainerTypes} from './requests-ioc.types';

export const requestContainer = new ContainerModule(
  (
    bind: interfaces.Bind,
    unbind: interfaces.Unbind,
    isBound: interfaces.IsBound,
    rebind: interfaces.Rebind,
    unbindAsync: interfaces.UnbindAsync,
    onActivation: interfaces.Container['onActivation'],
    onDeactivation: interfaces.Container['onDeactivation'],
  ) => {
    bind<RequestsModalsFacade>(RequestsContainerTypes.RequestsModalsFacade).to(RequestsModalsFacadeService);
  },
);
