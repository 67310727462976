import {Icon, useTheme} from '@innowise-group/ui-kit';
import {useTranslation} from 'react-i18next';
import {Can, RoomType} from '@innowise-group/core';
import * as Styled from './room-info.styles';
import React, {useMemo} from 'react';
import {subject} from '@casl/ability';

interface RoomInfoProps {
  id: string;
  roomType: RoomType;
  label: string;
  handlerEditRoom: () => void;
  isEditMode: boolean;
  isEditRoom: boolean;
  isOur: boolean;
  workspaceCount: number;
}

const getRoomType = (type: RoomType, t) => {
  switch (type) {
    case 'Coworking room type':
      return t('rooms.coworking');
    case 'Meeting room type':
      return t('rooms.meeting');
    case 'Office room type':
      return t('rooms.office');
    case 'Kitchen':
      return t('rooms.kitchen');
    case 'Water closet':
      return t('rooms.toilet');
    case 'Hall':
      return t('rooms.hall');
    case 'Playroom':
      return t('rooms.playroom');
    case 'Other':
      return t('rooms.other');
  }
};

const RoomInfo: React.FC<RoomInfoProps> = ({
  id,
  roomType,
  label,
  handlerEditRoom,
  isEditMode,
  isEditRoom,
  workspaceCount,
}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const colorIcon = useMemo(() => {
    return isEditRoom ? theme.palette.general.smart : theme.palette.general.darkGrey[60];
  }, [isEditRoom, theme]);

  return (
    <Styled.RoomInfoContainer>
      <Styled.RoomTypeContainer>
        <Styled.RoomType type="h3" isEditRoom={isEditRoom}>
          {`${label}`} ({getRoomType(roomType, t)})
        </Styled.RoomType>
        {isEditMode && <Icon type="u_edit-alt" fill={colorIcon} size={18} onClick={handlerEditRoom} isPointer />}
      </Styled.RoomTypeContainer>
      {!!workspaceCount && (
        <Can I="UPDATE" this={subject('ROOM', {id})}>
          <Styled.RoomType type="h3" isEditRoom={isEditRoom}>
            {t('rooms.workspaceCount', {count: workspaceCount})}
          </Styled.RoomType>
        </Can>
      )}
    </Styled.RoomInfoContainer>
  );
};

export default React.memo(RoomInfo);
