import {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import * as Styled from './portal-plan.styles';
import {Icon} from '@innowise-group/ui-kit';

interface PortalPlanProps {
  styles?: {[key: string]: string | number};
  el?: string;
  onClose?: () => void;
}

const PortalPlan: React.FC<PortalPlanProps> = ({children, styles, el = 'div', onClose}) => {
  const [container] = useState(() => document.createElement(el));

  useEffect(() => {
    const root = document.getElementById('root');
    Object.entries(styles).forEach(([key, value]) => {
      container.style[key] = value;
    });
    if (root) {
      root.appendChild(container);
      return () => {
        root.removeChild(container);
      };
    }
  }, [container, styles]);

  return createPortal(
    <Styled.PortalContainer>
      <Styled.IconContainer onClick={onClose}>
        <Icon type="u_multiply" isHover isPointer />
      </Styled.IconContainer>
      {children}
    </Styled.PortalContainer>,
    container,
  );
};

export default PortalPlan;
