import unknownUserLogo from '../../../../assets/icons/unknown-user-logo.svg';
import firedUserLogo from '../../../../assets/icons/fired-user-logo.svg';
import {Avatar} from '@innowise-group/ui-kit';

interface User {
  employee_id: number;
  hrm_id: number;
  photo: string;
}

interface GeotrackAvatarProps {
  user: User;
  avatarSize?: number;
}

const GeotrackAvatar: React.FC<GeotrackAvatarProps> = ({user, avatarSize}) => {
  const imageUrl = user.employee_id ? user.photo : firedUserLogo;

  return <Avatar size={avatarSize || 36} imageUrl={imageUrl || unknownUserLogo} hrmId={'' + user.hrm_id} />;
};

export default GeotrackAvatar;
