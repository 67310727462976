import {Typography} from '../typography';
import {styled} from '../theme-provider';

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: 5px;
`;

export const Title = styled(Typography)<{isEmployeeAssigned: boolean}>`
  font-size: ${(props) => !props.isEmployeeAssigned && '14px'};
  @media (max-width: 550px) {
    font-size: 12px;
  }
  @media (max-width: 481px) {
    font-size: 10px;
  }
`;

export const Value = styled(Typography)`
  color: ${({theme}) => theme.palette.general.darkGrey[60]};
  @media (max-width: 550px) {
    font-size: 12px;
  }
  @media (max-width: 481px) {
    font-size: 10px;
  }
`;
