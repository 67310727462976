import {EmployeeWorkspace} from '@innowise-group/core';

enum StatusPrecedence {
  Booked,
  Occupied,
  Reserved,
  Remote,
}

export default function sortEmployeeWorkspaces(workspaces: EmployeeWorkspace[]) {
  return [...workspaces].sort((a, b) => {
    if (a.status === 'Booked' && b.status === 'Booked') {
      return +new Date(b.startDay) - +new Date(a.startDay);
    }

    return StatusPrecedence[a.status] - StatusPrecedence[b.status];
  });
}
