import {Dispatch, SetStateAction} from 'react';
import * as Styled from './application-bar.styles';
import {useResizeObserver} from '../use-resize-observer';

export interface ApplicationBarProps {
  user: {
    name: string;
    position: string;
    avatarUrl: string;
  };
  isUserLoading?: boolean;
  onLogoClick?: () => void;
  userActions?: Array<{
    title: string;
    action?: () => void;
  }>;
  onSearch?: () => void;
  toggleNavBar: Dispatch<SetStateAction<boolean>>;
}

const ApplicationBar: React.FC<ApplicationBarProps> = ({
  onLogoClick,
  user: {avatarUrl, name, position},
  isUserLoading = false,
  onSearch,
  userActions,
  children,
  toggleNavBar,
  ...props
}) => {
  const handleBurgerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    toggleNavBar((prev) => !prev);
  };

  const isDesktopView = useResizeObserver('application-container', 1100);

  return (
    <Styled.ApplicationBarContainer {...props}>
      <Styled.IconContainer size={25} type="u_burger" onClick={handleBurgerClick} />
      <Styled.ApplicationLogo onClick={onLogoClick} />
      {isDesktopView && <Styled.VerticalSeparator />}
      <Styled.ActionsContainer>{children}</Styled.ActionsContainer>
    </Styled.ApplicationBarContainer>
  );
};

export default ApplicationBar;
