import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Checkbox, Chip, Form, WithFormProps} from '@innowise-group/ui-kit';
import * as Styled from './floors-form.styles';
import {useOfficesOverview} from '../../pages/offices-overview/use-offices-overview.hook';
import {FormEvent} from 'react';

export enum FloorsFormFields {
  Floors = 'floors',
  FloorNumber = 'floorNumber',
  FloorConfig = 'floorConfig',
}

export interface FloorsFormValues {
  floors: {number: number; isVirtual: boolean}[];
}

const OfficeOwnersForm: React.FC<WithFormProps<FloorsFormValues>> = ({initialValues}) => {
  const {
    control,
    register,
    formState: {errors},
    setValue,
    getValues,
    trigger,
  } = useFormContext();

  const {handleDeleteFloor} = useOfficesOverview();

  const {t} = useTranslation();

  const handleAddFloor = () => {
    const {
      floorNumber,
      floors,
      floorConfig: {virtual, physical},
    } = getValues();

    if (floorNumber === undefined || floorNumber === '') return;

    if (physical || virtual) {
      const sameNumber = floors?.filter((floor) => floor.number === +floorNumber) ?? [];
      const floorsToAdd: FloorsFormValues['floors'] = [];
      const floor: typeof floorsToAdd[number] = {number: +floorNumber, isVirtual: false};

      if (physical && !sameNumber.some((floor) => !floor.isVirtual)) floorsToAdd.push(floor);
      if (virtual && !sameNumber.some((floor) => floor.isVirtual)) floorsToAdd.push({...floor, isVirtual: true});

      if (floorsToAdd.length) setValue(FloorsFormFields.Floors, [...(floors || []), ...floorsToAdd]);
    }

    setValue(FloorsFormFields.FloorNumber, undefined);
    trigger(FloorsFormFields.Floors);
  };

  const changeFloorConfig = (e: FormEvent<HTMLInputElement>, field: 'virtual' | 'physical') => {
    const {floorConfig} = getValues();
    setValue(FloorsFormFields.FloorConfig, {...floorConfig, [field]: e.currentTarget.checked});
  };

  return (
    <Form.Layout layoutType="vertical" noPadding>
      <Form.Field label={`${t('modals.addOrDeleteFloor')} *`} error={errors[FloorsFormFields.Floors]?.message}>
        <Styled.FloorsContainerForm>
          <Styled.FloorInput
            type="number"
            min={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleAddFloor();
              }
            }}
            {...register(FloorsFormFields.FloorNumber)}
            placeholder={t('placeholders.floor')}
          />
          <Styled.AddFloorIcon type="u_plus" onClick={handleAddFloor} isPointer isHover />
        </Styled.FloorsContainerForm>
      </Form.Field>
      <Controller
        name={FloorsFormFields.FloorConfig}
        control={control}
        defaultValue={{virtual: true, physical: true}}
        render={({
          field: {
            value: {virtual, physical},
          },
        }) => (
          <>
            <Checkbox onChange={(e) => changeFloorConfig(e, 'physical')} checked={physical}>
              {t('modals.floor')}
            </Checkbox>
            <Checkbox onChange={(e) => changeFloorConfig(e, 'virtual')} checked={virtual}>
              {t('modals.virtualFloor')}
            </Checkbox>
          </>
        )}
      />
      <Controller
        name={FloorsFormFields.Floors}
        control={control}
        defaultValue={initialValues.floors}
        rules={{
          required: {value: true, message: t('modals.messages.required')},
          validate: (floors) => floors.length !== 0,
        }}
        render={({field: {value: floors}}) => (
          <Styled.FloorsContainer>
            {floors
              ? floors.map(({number, isVirtual}) => {
                  const floorNumber = `${number} ${t(`modals.${isVirtual ? 'virtualFloor' : 'floor'}`)}`;
                  const handleDeleteFloorFunction = () => {
                    handleDeleteFloor(() => {
                      const {floors} = getValues();
                      const changedFloors = floors.filter(
                        (floor) => floor.number !== number || floor.isVirtual !== isVirtual,
                      );
                      setValue(FloorsFormFields.Floors, [...(changedFloors || [])]);
                    });
                  };
                  return (
                    <Chip key={floorNumber} title={floorNumber} clearable onDeleteClick={handleDeleteFloorFunction} />
                  );
                })
              : null}
          </Styled.FloorsContainer>
        )}
      />
    </Form.Layout>
  );
};

export default OfficeOwnersForm;
