import React from 'react';
import {useTranslation} from 'react-i18next';
import {SearchForm, SearchFormValues} from './search-form';
import * as Styled from './page-search-bar.styles';
import {RequestStatusForm, RequestStatusFormValues} from '../request-status-form';

interface PageSearchBarProps {
  searchValue?: string;
  handleSearchSubmit?: (data: SearchFormValues) => void;
  handleSearchName?: (event: React.FormEvent<HTMLOrSVGElement & HTMLInputElement>) => void;
  handleSearchRequestStatus?: (data: RequestStatusFormValues) => void;
  userRole?: 'manager' | 'employee';
  findJiraOffice?: boolean;
}

const PageSearchBar: React.FC<PageSearchBarProps> = ({
  searchValue,
  handleSearchSubmit,
  handleSearchName,
  handleSearchRequestStatus,
  userRole = 'manager',
  findJiraOffice,
  children,
}) => {
  const {t} = useTranslation();

  return (
    <Styled.PageSearchBarContainer>
      {userRole !== 'employee' && (
        <Styled.SearchNameInput
          value={searchValue}
          placeholder={t('placeholders.search')}
          onChange={handleSearchName}
          icon="u_search"
        />
      )}
      {handleSearchSubmit || handleSearchRequestStatus ? (
        <Styled.FormsContainer>
          {handleSearchSubmit && (
            <Styled.SearchFormContainer>
              <SearchForm onSubmit={handleSearchSubmit} findJiraOffice />
            </Styled.SearchFormContainer>
          )}
          {handleSearchRequestStatus && userRole !== 'employee' && (
            <RequestStatusForm onSubmit={handleSearchRequestStatus} />
          )}
        </Styled.FormsContainer>
      ) : null}
      {children}
    </Styled.PageSearchBarContainer>
  );
};

export default PageSearchBar;
