import * as Styled from './switch.styles';

export interface SwitchProps extends React.HTMLAttributes<HTMLInputElement> {
  checked: boolean;
  disabled?: boolean;
  position?: 'left' | 'right';
}

const Switch: React.FC<SwitchProps> = ({
  className,
  checked,
  position = 'right',
  children,
  disabled = false,
  ...props
}) => {
  return (
    <Styled.Container className={className}>
      <Styled.Label position={position} disabled={disabled}>
        <Styled.HiddenCheckbox checked={checked} disabled={disabled} {...props} />
        <Styled.VisibleSwitch checked={checked} position={position} className={checked && 'checked'}>
          <Styled.Pin />
        </Styled.VisibleSwitch>
        {children}
      </Styled.Label>
    </Styled.Container>
  );
};

export default Switch;
