import {useEffect} from 'react';
import {FloorEvents, FloorsEventEmitterService} from '../../services/floors-event-emitter';

export function useFloorEvents<U extends keyof FloorEvents>(
  eventsMap: Record<U, FloorEvents[U]>,
  floorsEventEmitter: FloorsEventEmitterService,
  isClientReadyState: boolean,
) {
  useEffect(() => {
    if (isClientReadyState) {
      const entries = Object.entries(eventsMap);

      entries.forEach(([key, listener]) => {
        floorsEventEmitter.addListener(key, listener as FloorEvents[U]);
      });

      return () => {
        entries.forEach(([key, listener]) => {
          floorsEventEmitter.removeListener(key, listener as FloorEvents[U]);
        });
      };
    }
  }, [eventsMap, floorsEventEmitter, isClientReadyState]);
}
