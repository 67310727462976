import {TableRowContainer} from './table.styles';

type TableRowType = 'highlighted' | 'regular';

export interface TableRowProps extends React.HtmlHTMLAttributes<HTMLTableRowElement> {
  noWhitespace?: boolean;
  additionalContent?: JSX.Element | JSX.Element[];
  type?: TableRowType;
}

const TableRow: React.FC<TableRowProps> = ({additionalContent, children, noWhitespace, ...props}) => {
  return <TableRowContainer {...props}>{children}</TableRowContainer>;
};

export default TableRow;
