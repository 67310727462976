import styled from 'styled-components';
import {Icon} from '../icon';

export const DatePickerContainer = styled.div<{
  disabled?: boolean;
  zIndex?: number;
  autoWidth?: boolean;
  size?: 'default' | 'small' | 'extra-small' | 'large';
}>`
  width: ${({size}) => (size === 'large' ? 360 : size === 'default' ? 250 : size === 'small' ? 160 : 70)}px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  opacity: ${({disabled}) => (disabled ? '0.3' : '1')};
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  border-radius: 6px;
  transition: 0.3s;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  & .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    width: ${({autoWidth}) => (autoWidth ? 'max-content' : 'initial')};

    z-index: ${({zIndex}) => zIndex ?? 10};
  }
  & .react-datepicker__month {
    margin: 2px 6px;
  }
  .react-datepicker__header {
    padding-top: 0.6em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 24px;
    line-height: 26px;
  }
  & .react-datepicker__navigation {
    top: 4px;
  }
  & .react-datepicker__input-container {
    width: 100%;
    z-index: ${({zIndex}) => zIndex ?? 10};
  }
  & .react-datepicker__close-icon {
    right: -5px;
  }
  & .react-datepicker__close-icon::before,
  & .react-datepicker__close-icon::after {
    background-color: ${({theme}) => theme.palette.general.smart};
  }

  & .react-datepicker__day {
    margin: 0;
    padding: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  & .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${({theme}) => theme.palette.state.error.background};
    color: ${({theme}) => theme.palette.general.darkGrey[100]};
    border-radius: 0;
    &:hover {
      background-color: ${({theme}) => theme.palette.general.smart};
      color: ${({theme}) => theme.palette.general.white};
    }
  }

  &
    .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range) {
    background-color: transparent;
    color: black;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  & .react-datepicker__day--selected:not(.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range),
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--range-end.react-datepicker__day--range-start {
    background-color: ${({theme}) => theme.palette.general.smart};
    color: ${({theme}) => theme.palette.general.white};
    border-radius: 0.3rem;
  }

  & .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    border-radius: 0.3rem 0 0 0.3rem;
  }
  & .react-datepicker__day--range-end,
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):hover {
    border-radius: 0 0.3rem 0.3rem 0;
  }

  & .react-datepicker__day--today {
    position: relative;
  }

  & .react-datepicker__day--today::after {
    position: absolute;
    content: '';
    inset: 0;
    border: 2px solid black;
    border-radius: 0.3rem;
  }

  & .react-datepicker__current-month {
    text-transform: capitalize;
  }

  & .react-datepicker__close-icon {
    position: absolute;
    right: 20px;
  }
  & input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: ${({theme}) => theme.palette.general.darkGrey[100]};
    font-family: Manrope;
    font-size: 12px;
    font-weight: bold;
    cursor: inherit;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: Manrope;
      font-size: 14px;
      font-weight: bold;
      color: ${({theme}) => theme.palette.general.lightGrey[100]};
    }

    @media (max-width: 769px) {
      &::placeholder {
        font-size: 12px;
      }
    }

    @media (max-width: 481px) {
      height: 100%;
      &::placeholder {
        font-size: 10px;
      }
    }
  }

  @media (max-width: 769px) {
    width: 180px;
  }
  @media (max-width: 481px) {
    height: 30px;
    font-size: 12px;
  }
`;

export const CalendarIcon = styled(Icon)`
  position: absolute;
  right: 10px;
`;
