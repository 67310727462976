import {styled, css} from '../theme-provider';

export const AvatarWrapper = styled.div<{withTooltip: boolean}>`
  position: relative;
  margin-right: 10px;
  cursor: pointer;

  ${({withTooltip}) => {
    if (withTooltip) {
      return css`
        &::before {
          display: block;
          position: absolute;
          opacity: 0;
          min-width: 100%;
          top: 0;
          left: -100%;
          z-index: 10;
          border-radius: 2px;
          white-space: nowrap;
          padding: 0 5px;
          background-color: ${({theme}) => theme.palette.general.lightGrey[80]};
          text-align: center;
          content: attr(data-tooltip-info);
          transition: 0.3s ease-in-out;
        }
        &:hover::before {
          top: -25px;
          opacity: 1;
        }

        &:hover::after {
          content: ' ';
          position: absolute;
          top: -7px;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: ${({theme}) => theme.palette.general.lightGrey[80]} transparent transparent transparent;
        }
      `;
    }
  }};

  @media (max-width: 481px) {
    margin-right: 5px;
  }
`;

export const Avatar = styled.img`
  object-fit: cover;
  border-radius: 50%;
  pointer-events: none;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 481px) {
    right: -5px;
  }
`;
