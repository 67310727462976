import {useEffect, useState} from 'react';

function useAppLanguage(): string {
  const [language, setLanguage] = useState<string>(null);

  useEffect(() => {
    const language = localStorage.getItem('htlgt-language');
    if (language) {
      setLanguage(language);
    }
  }, []);

  return language;
}

export default useAppLanguage;
