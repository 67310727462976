import {ReportFileType} from '@innowise-group/core';
import {Observable} from 'rxjs';
import {FloorData} from '../pages/floor-details/use-plan-details.hook';

export const getReportName = (fullAddress: string, isVirtualFloor: boolean, language: string) => {
  const virtualFloorName = language === 'ru' ? 'Виртуальный_этаж_' : 'Virtual_Floor_';

  return (isVirtualFloor ? virtualFloorName : '').concat(
    fullAddress
      .split(',')
      .slice(0, isVirtualFloor ? -1 : undefined)
      .join('')
      .replaceAll(/\.| /gi, '_'),
  );
};

export const getReport = (
  apiCallback: (floorId: string, type: ReportFileType) => Observable<{file: File; language: string}>,
  floor: FloorData,
  type: ReportFileType,
) => {
  apiCallback(floor.floor, type).subscribe(({file, language}) => {
    const link = document.createElement('a');
    link.setAttribute('download', `${getReportName(floor.fullAddress, floor.isVirtual, language)}.${type}`);
    const data = new Blob([file], {
      type: `application/${type === 'xlsx' ? 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' : type}`,
    });
    link.href = URL.createObjectURL(data);
    link.click();
    URL.revokeObjectURL(link.href);
  });
};
