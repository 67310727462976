import {Employee} from './employee.model';
import {Floor} from './floor.model';
import {Location} from './location.model';

export interface OfficePreview {
  id: string;
  locationId: string;
  fillPercentage: number;
  floors: Floor[];
}

export interface Office {
  id: string;
  locationId: string;
  address: string;
  addressRu?: string;
  addressEng?: string;
  postcode: string;
  fillPercentage: number;
  floors: Floor[];
  hasFaceId: boolean;
  jiraOfficeId?: string;
}

export enum ManagerTypes {
  OfficeManager = 'Office manager',
  HrManager = 'HR manager',
}

export type Manager = Employee & {managerType: ManagerTypes[]};

export interface OfficeDetails extends Office {
  location: Location;
  managers: Manager[];

  workspacesCount: WorkspacesCount;
}

export interface OfficeStatistic {
  id: string;
  workspacesCount: WorkspacesCount;
}

export interface WorkspacesCount {
  total: number;
  reserved: number;
  occupied: number;
  free: number;
  booked: number;
  remote: number;
}
