import {inject, injectable} from 'inversify';
import {Observable, map} from 'rxjs';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import {format} from 'date-fns';
import {GlobalContainerTypes, Http} from '@innowise-group/core';
import {
  FilterDataPayload,
  GeotrackNormalization,
  GeotrackContainerTypes,
  VisitStats,
  EmployeeStatusesData,
  EmployeeStatusesResponse,
  EmployeeAnalyticsData,
  EmployeeAnalyticsResponse,
  EmployeeStatisticsData,
  EmployeeStatisticsResponse,
  OrgUnit,
  HrmDictionary,
  ChatbotStatuses,
  AvailableFilterValues,
  ChatbotStatusResponse,
  GeotrackApi,
  HrmDictionaryKey,
  HrmDictionaryResponse,
  OrgUnitResponse,
  RegisteredOffice,
  DictionaryMap,
  RequestParams,
  EmployeeAnalyticsByDateData,
} from '../../types';

@injectable()
class GeotrackApiService implements GeotrackApi {
  public static readonly type = GeotrackContainerTypes.Geotrack;

  @inject(GeotrackContainerTypes.GeotrackNormalization) private geotrackNormalization: GeotrackNormalization;
  @inject(GlobalContainerTypes.Http) private http: Http;

  public getOrgUnitTypes = (): Observable<OrgUnit[]> => {
    return this.http
      .GET<OrgUnitResponse>('/employee-management/api/v1/org-units', this.http.hrmRequestConfig)
      .pipe(map((response) => this.geotrackNormalization.normalizeOrgUnitTypes(response.data.orgUnitDtoList)));
  };

  public getChatbotStatuses = (): Observable<ChatbotStatuses> => {
    return this.http
      .GET<ChatbotStatusResponse>('/chatbot/v1/hrm/statuses/', {
        ...this.http.chatbotRequestConfig,
      })
      .pipe(map(this.geotrackNormalization.normalizeChatbotStatuses));
  };

  public getHrmDictionaryEntries = (...keys: HrmDictionaryKey[]): Observable<HrmDictionary[]> => {
    return this.http
      .GET<HrmDictionaryResponse>(
        `/dictionaries/api/v2/dictionaries?filter=${encodeURIComponent('{"name":["' + keys.join('","') + '"]}')}`,
        this.http.hrmRequestConfig,
      )
      .pipe(map(this.geotrackNormalization.normalizeHrmDictionaryEntries));
  };

  public getHrmDictionaryEntriesMap = (...keys: HrmDictionaryKey[]): Observable<DictionaryMap> =>
    this.http
      .GET<HrmDictionaryResponse>(
        `/dictionaries/api/v2/dictionaries?filter=${encodeURIComponent('{"name":["' + keys.join('","') + '"]}')}`,
        this.http.hrmRequestConfig,
      )
      .pipe(
        map((value) =>
          value.reduce<DictionaryMap>(
            (acc, {name, values}) => ({
              ...acc,
              [name]: values.map(({value}) => ({
                value,
                title: value,
              })),
            }),
            null,
          ),
        ),
      );

  public getReport = (params: RequestParams): Observable<any> => {
    return this.http.GET(`/employees_geo_tracker/get_xlsx?${this._getQueryParamsFromFilters(params)}`, {
      responseType: 'blob',
    });
  };

  public getRegisteredOffices = (): Observable<RegisteredOffice[]> => {
    return this.http.GET<RegisteredOffice[]>('/employee-management/api/v1/offices', this.http.hrmRequestConfig);
  };

  public getAvailableFilterValues = (): Observable<AvailableFilterValues> => {
    return this.http.GET<AvailableFilterValues>(
      '/employee-management/api/v1/employees/filter/values',
      this.http.hrmRequestConfig,
    );
  };

  public getEmployeeStatuses = (
    params: RequestParams,
  ): Observable<{data: EmployeeStatusesData[]; totalElements: number; visitStats: VisitStats}> => {
    return this.http
      .GET<{results: {results: EmployeeStatusesResponse[]; visitation_rate: VisitStats}; count: number}>(
        `/employees_geo_tracker/?${this._getQueryParamsFromFilters(params)}`,
      )
      .pipe(
        map((response) => ({
          data: response.results.results.map(this.geotrackNormalization.normalizeEmployeeStatusesFromApi),
          totalElements: response.count,
          visitStats: response.results.visitation_rate,
        })),
      );
  };

  public getEmployeeAnalytics = (
    params: RequestParams,
  ): Observable<{data: EmployeeAnalyticsData[]; totalElements: number}> => {
    return this.http
      .GET<{results: EmployeeAnalyticsResponse[]; count: number}>(
        `/employees_analytics/?${this._getQueryParamsFromFilters(params)}`,
      )
      .pipe(
        map((response) => ({
          data: response.results.map(this.geotrackNormalization.normalizeEmployeeAnalyticsFromApi),
          totalElements: response.count,
        })),
      );
  };

  public getEmployeeStatistics = (params: RequestParams): Observable<EmployeeStatisticsData> => {
    return this.http
      .GET<EmployeeStatisticsResponse>(
        `/employees_analytics/get_statistics_charts/?${this._getQueryParamsFromFilters(params)}`,
      )
      .pipe(map(this.geotrackNormalization.normalizeEmployeeStatisticsFromApi));
  };

  public getEmployeeStatisticsByDates = (params: RequestParams): Observable<EmployeeAnalyticsByDateData> => {
    return this.http
      .GET(`/employees_analytics/get_mark_status_by_dates/?${this._getQueryParamsFromFilters(params)}`)
      .pipe(map(this.geotrackNormalization.normalizeEmployeeMarkStatusByDateFromApi));
  };

  private _getQueryParamsFromFilters({
    filters: rawFilters,
    currentPage,
    pageSize,
    searchName,
  }: RequestParams): URLSearchParams {
    const normalizedFilters = this.geotrackNormalization.normalizeFilterPayload(rawFilters);
    const filters = pickBy(normalizedFilters, (value) => !isEmpty(value)) as Partial<FilterDataPayload>;
    const params = new URLSearchParams();

    filters &&
      Object.keys(filters).forEach((key) => {
        if (!filters[key]) return;
        if (key === 'late') {
          if (Array.isArray(filters[key])) {
            (filters[key] as string[]).forEach((value) => {
              return params.append(key, value);
            });
          } else {
            params.append(key, filters[key] as string);
          }
        } else if (key === 'date_range') {
          const range = filters[key]
            .map((value) => {
              const date = typeof value === 'string' ? new Date(value) : value;
              return format(date, 'yyyy-MM-dd');
            })
            .join(',');
          params.append(key, range);
        } else {
          params.append(key, Array.isArray(filters[key]) ? filters[key].join(';') : filters[key]);
        }
      });

    if (searchName) params.append('preferred_name', searchName);
    params.append('offset', '' + pageSize * (currentPage - 1));
    params.append('limit', '' + pageSize);

    return params;
  }
}

export default GeotrackApiService;
