import React, {useState} from 'react';

export const GlobalThemeContext = React.createContext({
  theme: null,
  themeSwitchHandler: null,
});

const GlobalThemeContextProvider: React.FC = ({children}) => {
  const [currentTheme, setCurrentTheme] = useState(() =>
    localStorage.getItem('theme') == null ? 'light' : localStorage.getItem('theme'),
  );

  const themeSwitchHandler = (themeType: string) => {
    setCurrentTheme(themeType);
  };

  return (
    <GlobalThemeContext.Provider
      value={{
        theme: currentTheme,
        themeSwitchHandler: themeSwitchHandler,
      }}
    >
      {children}
    </GlobalThemeContext.Provider>
  );
};

export default GlobalThemeContextProvider;
