import {Icon} from '../icon';
import * as Styled from './information-tile.styles';

export interface InformationTileProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  value: string;
  valueIcon?: string;
  iconColor?: string;
  isEmployeeAssigned?: boolean;
}

const InformationTile: React.FC<InformationTileProps> = ({
  title,
  value,
  valueIcon,
  iconColor,
  isEmployeeAssigned,
  ...props
}) => {
  return (
    <div {...props}>
      <Styled.Title type="h3" isEmployeeAssigned={isEmployeeAssigned}>
        {title}
      </Styled.Title>
      <Styled.ValueContainer>
        {valueIcon && (
          <Styled.IconContainer>
            <Icon type={valueIcon} fill="white" size={14} background={iconColor} />
          </Styled.IconContainer>
        )}
        <Styled.Value>{value ?? ''}</Styled.Value>
      </Styled.ValueContainer>
    </div>
  );
};

export default InformationTile;
