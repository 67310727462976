import React from 'react';
import {WorkspaceBookType} from '@innowise-group/core';
import {Icon, useTheme, Avatar} from '@innowise-group/ui-kit';
import {getThemeByRequestType} from '@innowise-group/utilities';
import * as Styled from './search-employee-seat.styles';

interface SearchEmployeeSeatInfoProps {
  photo: string;
  name: string;
  department: string;
  searchName: string;
  handleEmployeeSeat?: () => void;
  workspaceStatus?: WorkspaceBookType;
  allowedToInteract?: boolean;
}

const SearchEmployeeSeatInfo: React.FC<SearchEmployeeSeatInfoProps> = ({
  handleEmployeeSeat,
  photo,
  department,
  name,
  searchName,
  workspaceStatus,
  allowedToInteract = true,
  children,
}) => {
  const theme = useTheme();
  const color = getThemeByRequestType(workspaceStatus, theme);
  const getHighlightedText = (text, highlight, other = '') => {
    const reverseText = text.split(' ').reverse().join(' ');
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    const reverseParts = reverseText.split(new RegExp(`(${highlight})`, 'gi'));
    let element = null;
    if (reverseParts.includes(highlight)) {
      element = reverseParts.map((part, i) => {
        const matches = part.toLowerCase() === highlight.toLowerCase();
        return (
          <Styled.Span key={i} matches={matches}>
            {part}
          </Styled.Span>
        );
      });
    } else {
      element = parts.map((part, i) => {
        const matches = part.toLowerCase() === highlight.toLowerCase();
        return (
          <Styled.Span key={i} matches={matches}>
            {part}
          </Styled.Span>
        );
      });
    }

    return (
      <Styled.SeatInfo type="h3">
        {element} {other}
      </Styled.SeatInfo>
    );
  };

  return (
    <Styled.EmployeeSeatBlock
      onClick={allowedToInteract ? handleEmployeeSeat : undefined}
      allowedToInteract={allowedToInteract}
    >
      <Avatar
        size={40}
        imageUrl={photo}
        badge={<Icon type="u_desktop" fill="white" size={16} background={color.default} />}
      />
      <Styled.EmployeeSeatInfo>
        {getHighlightedText(name, searchName, department)}
        {children}
      </Styled.EmployeeSeatInfo>
    </Styled.EmployeeSeatBlock>
  );
};

export default SearchEmployeeSeatInfo;
