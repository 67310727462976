import {styled, Typography} from '@innowise-group/ui-kit';

export const ActionsContainer = styled.div`
  right: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 0 0 6px 6px;
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 5px;
  &:hover {
    background: ${({theme}) => theme.palette.state.error.background};
  }
`;

export const ActionText = styled(Typography)`
  margin-left: 10px;
`;
