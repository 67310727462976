import {Typography} from '../typography';
import {styled} from '../theme-provider';

export type ProgressBarSize = 'medium' | 'large';

interface ProgressBarContainerProps {
  size: ProgressBarSize;
}

export const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  height: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[40]};
  margin: 10px 0;
  width: ${(props) => (props.size === 'medium' ? '100%' : '190px')};

  @media (max-width: 481px) {
    width: 120px;
  }
`;

interface ProgressBlockProps {
  progressValue: number;
  color?: string;
}
export const ProgressBlock = styled.div<ProgressBlockProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: ${({theme, progressValue, color}) => {
    const {warning, error, success} = theme.palette.state;
    if (color) {
      return color;
    }
    if (progressValue < 60) {
      return success.default;
    }
    if (progressValue < 80) {
      return warning.default;
    }
    return error.default;
  }};
  width: ${({progressValue}) => `${progressValue}%`};
`;

export const Title = styled(Typography)<ProgressBlockProps>`
  position: relative;
  text-align: center;
  z-index: 8;
  color: ${({theme, progressValue}) =>
    progressValue > 60 ? theme.palette.general.white : theme.palette.general.darkGrey[100]};

  @media (max-width: 481px) {
    top: 3px;
  }
`;
