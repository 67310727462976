import React from 'react';
import {useTranslation} from 'react-i18next';
import {Logo, Icon, Typography} from '@innowise-group/ui-kit';
import * as Styled from './desktop-view.styles';

import building from '../../assets/emojis/building.svg';
import hand from '../../assets/emojis/hand.svg';
import people from '../../assets/emojis/people.svg';
import user from '../../assets/emojis/user.svg';
import background from '../../assets/background.svg';
import top from '../../assets/top.svg';
import bottom from '../../assets/bottom.svg';

const EMOJIS = [building, hand, people, user];

interface DesktopViewProps {
  handleSignIn: () => void;
  openReleaseNotes: () => void;
}

const DesktopView: React.FC<DesktopViewProps> = ({handleSignIn, openReleaseNotes}) => {
  const {t} = useTranslation();

  return (
    <>
      <Styled.DescriptionContainer background={background}>
        <Styled.DividersGroup background={top} position="top" />
        <Styled.EmojisContainer>
          {EMOJIS.map((emoji) => (
            <Styled.EmojiContainer key={emoji}>
              <Styled.EmojiImage src={emoji} alt="Emoji"></Styled.EmojiImage>
            </Styled.EmojiContainer>
          ))}
        </Styled.EmojisContainer>

        <Styled.LightText type="h1">Innowise ITS Hotelling</Styled.LightText>

        {t<string, string[]>('pages.auth.goals', {returnObjects: true})
          .map((goal) => <Styled.LightText key={goal}>{goal}</Styled.LightText>)
          .map((item, index, array) => (index === array.length - 1 ? [item] : [item, <Styled.Divider key={index} />]))
          .flat()}
        <Styled.DividersGroup background={bottom} position="bottom" />
      </Styled.DescriptionContainer>

      <Styled.ContentSide>
        <Logo />
        <Typography type="h3">
          <Styled.RedText>ITS Hotelling </Styled.RedText>
          <Styled.BlackText>Tool</Styled.BlackText>
        </Typography>
        <Styled.AuthButton onClick={handleSignIn}>
          <Icon type="color_google_icon" size={24} />
          <Styled.AuthButtonText>Continue with Google</Styled.AuthButtonText>
        </Styled.AuthButton>
        <Styled.Version onClick={openReleaseNotes}>
          {t('pages.version')}: {process.env.REACT_APP_VERSION}
        </Styled.Version>
        <Styled.SupportLinkBlock>
          <Styled.SupportLink href="https://innowise-group.atlassian.net/servicedesk/customer/portals" target="_blank">
            {t('buttons.support')}
          </Styled.SupportLink>
        </Styled.SupportLinkBlock>
      </Styled.ContentSide>
    </>
  );
};

export default DesktopView;
