import React from 'react';
import * as Styled from './form.styles';

export interface FormFieldProps {
  label?: string;
  error?: any;
  errorType?: any;
  labelTooltip?: React.ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({label, error, children, errorType = 'required', labelTooltip}) => {
  return (
    <Styled.FormFieldContainer>
      {label ? (
        <Styled.FormFieldLabel>
          {label}
          {labelTooltip}
        </Styled.FormFieldLabel>
      ) : null}
      {children}
      {errorType === 'required' ? <Styled.FormFieldError>{error}</Styled.FormFieldError> : null}
      {errorType === 'min' ? <Styled.FormFieldError>{error}</Styled.FormFieldError> : null}
    </Styled.FormFieldContainer>
  );
};

export default FormField;
