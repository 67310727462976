import {Controller, ControllerProps, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Form} from '@innowise-group/ui-kit';
import {WorkspaceBookType} from '@innowise-group/core';
import {WorkspaceStatusSelect} from '../workspace-status-select';

export enum SelectStatusFormFields {
  WorkspaceStatus = 'status',
}

export interface SelectStatusFormValues {
  status: WorkspaceBookType;
}

const WorkspaceStatusForm: React.FC<Omit<ControllerProps, 'render' | 'name'> & {isVirtual: boolean}> = ({
  isVirtual,
  ...props
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const {t} = useTranslation();
  return (
    <Controller
      {...props}
      name={SelectStatusFormFields.WorkspaceStatus}
      control={control}
      render={({field: {onChange, value}}) => {
        const handleChangeStatus = (status) => {
          onChange(status);
        };
        return (
          <Form.Field label={t('modals.status')} error={errors[SelectStatusFormFields.WorkspaceStatus]}>
            <WorkspaceStatusSelect
              isVirtual={isVirtual}
              disabled={isVirtual}
              onValueChange={handleChangeStatus}
              value={value}
              position="fixed"
            />
          </Form.Field>
        );
      }}
    />
  );
};

export default WorkspaceStatusForm;
