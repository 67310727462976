import React from 'react';
import {TableHeadContainer, TableHeadContent} from './table.styles';

export interface TableHeadProps extends React.HTMLAttributes<HTMLTableCellElement> {
  orderBy?: string;
}

const TableHead: React.FC<TableHeadProps> = ({children, orderBy, ...props}) => {
  return (
    <TableHeadContainer {...props}>
      <TableHeadContent>
        <div>{children}</div>
      </TableHeadContent>
    </TableHeadContainer>
  );
};

export default TableHead;
