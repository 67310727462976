import {useEffect, useState} from 'react';

export const RESIZE_OBSERVER_TABLET_SCREEN_SIZE = 480;
export const RESIZE_OBSERVER_SMALL_DESKTOP_SCREEN_SIZE = 762;
export const RESIZE_OBSERVER_DESKTOP_SCREEN_SIZE = 992;

export type RESIZE_OBSERVER_SCREEN_SIZE =
  | typeof RESIZE_OBSERVER_TABLET_SCREEN_SIZE
  | typeof RESIZE_OBSERVER_SMALL_DESKTOP_SCREEN_SIZE
  | typeof RESIZE_OBSERVER_DESKTOP_SCREEN_SIZE;

const useResizeObserver = (id: string, widthScreen: RESIZE_OBSERVER_SCREEN_SIZE | number = 768): boolean => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const container = document.getElementById(id);
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
          if (contentBoxSize.inlineSize <= widthScreen || entry.contentRect.width <= widthScreen) {
            setIsVisible(false);
          } else {
            setIsVisible(true);
          }
        }
      }
    });

    if (container) {
      resizeObserver.observe(container);
      return () => resizeObserver.unobserve(container);
    }
  }, [id, widthScreen]);

  return isVisible;
};

export default useResizeObserver;
