import {styled} from '../theme-provider';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div<{isOpen: boolean}>`
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -html-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
`;

export const ContentWrapper = styled.div<{
  isOpen: boolean;
  contentHeight: number;
}>`
  transition: 0.3s;
  overflow: hidden;
  height: ${({contentHeight, isOpen}) => (isOpen ? `${contentHeight}px` : '0px')};
`;

export const Content = styled.div``;
