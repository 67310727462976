import {EmployeesApiService, EmployeeWorkspace, PersonContext, useService} from '@innowise-group/core';
import {Icon} from '@innowise-group/ui-kit';
import {sortEmployeeWorkspaces} from '@innowise-group/utilities';
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as Styled from './my-workspace-button.styles';

const MyWorkspaceButton = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'buttons'});
  const person = useContext(PersonContext);
  const employeesApi = useService(EmployeesApiService);
  const navigate = useNavigate();
  const [workspace, setWorkspace] = useState<EmployeeWorkspace>(null);

  const handleMyWorkspaceClick = () => {
    if (!workspace) return;
    const {floorId, id} = workspace;
    navigate(`/floors/${floorId}`, {state: {workspaceId: id}});
  };

  useEffect(() => {
    if (!person) return;
    setWorkspace(null);
    employeesApi.getEmployeeById(person.id).subscribe((employee) => {
      if (!employee?.workspaces.length) return;
      setWorkspace(sortEmployeeWorkspaces(employee.workspaces)[0]);
    });
  }, [person, employeesApi]);

  return (
    <Styled.MyWorkspaceButtonContainer disabled={!workspace} onClick={handleMyWorkspaceClick}>
      <Icon size={16} type="u_user-location" />
      <Styled.MyWorkspaceButtonText>{t('mySeat')}</Styled.MyWorkspaceButtonText>
    </Styled.MyWorkspaceButtonContainer>
  );
};

export default MyWorkspaceButton;
