import React from 'react';
import {TableDataContainer} from './table.styles';

export interface TableDataProps extends React.HTMLAttributes<HTMLTableCellElement> {
  colSpan?: number;
}

const TableData: React.FC<TableDataProps> = ({children, colSpan = 1, ...props}) => {
  return (
    <TableDataContainer colSpan={colSpan} {...props}>
      {children}
    </TableDataContainer>
  );
};

export default TableData;
