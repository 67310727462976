import ModalHeader from './modal-header.component';
import ModalActions from './modal-actions.component';
import {ModalBody, Modal as ModalContainerComponent} from './modal.styles';
export {default as ModalContainer, modalManager} from './modal-container.component';

export const Modal = {
  Container: ModalContainerComponent,
  Header: ModalHeader,
  Actions: ModalActions,
  Body: ModalBody,
};
