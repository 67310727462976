import {useEffect} from 'react';

export const useKeyPress = (key: string, handler: (pressed: boolean) => void) => {
  useEffect(() => {
    const changeSelectableValue = (event: KeyboardEvent) => {
      if (event.code === key) {
        switch (event.type) {
          case 'keydown': {
            handler(false);
            break;
          }
          case 'keyup': {
            handler(true);
            break;
          }
        }
      }
    };

    document.addEventListener('keydown', changeSelectableValue);
    document.addEventListener('keyup', changeSelectableValue);
    return () => {
      document.removeEventListener('keydown', changeSelectableValue);
      document.removeEventListener('keyup', changeSelectableValue);
    };
  }, [handler, key]);
};
