import {RequestStatus} from '@innowise-group/core';

export const internationalizeStatus = (status: RequestStatus): string => {
  switch (status) {
    case 'approved':
      return 'approvedRequestsStatus';
    case 'in-clarification':
    case 'conflict':
      return 'inClarificationRequestsStatus';
    case 'rejected':
      return 'rejectedRequestsStatus';
    case 'delayed':
    case 'canceled':
      return 'canceledRequestsStatus';
  }
};
