import {map, Observable, tap} from 'rxjs';
import {notificationsManager} from '@innowise-group/ui-kit';
import {RoomDataPayload, RoomResponse, RoomsApi} from './rooms-api.types';
import {GlobalContainerTypes, Http, inject, injectable, Room} from '../..';

@injectable()
class RoomsApiService implements RoomsApi {
  public static readonly type = GlobalContainerTypes.RoomsApi;

  @inject(GlobalContainerTypes.Http) private http: Http;

  public changeRoom(id: string, roomData: RoomDataPayload): Observable<Room> {
    return this.http.PATCH<RoomResponse>(`/rooms/${id}/`, roomData).pipe(
      map(({room_type, number, our, id}) => ({
        number,
        id: id && String(id),
        roomType: room_type,
        isOur: our,
      })),
      tap(() => {
        notificationsManager.success({title: 'success', subtitle: 'editRoom'});
      }),
    );
  }

  public getWorkspacesListRoom(roomId: string): Observable<{id: string; number: string}[]> {
    return this.http.GET<{id: number; number: number}[]>(`/rooms/${roomId}/retrieve-workspace-list/`).pipe(
      map((list) =>
        list
          .sort((a, b) => +a.number - +b.number)
          .map(({id, number}) => ({
            id: id && String(id),
            number: number && String(number),
          })),
      ),
    );
  }
}

export default RoomsApiService;
