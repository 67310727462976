import {Controller, ControllerProps, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Form, Input} from '@innowise-group/ui-kit';
import {LocationsSelect} from '@shared-components';

export enum SelectLocationFormFields {
  Location = 'location',
  Index = 'index',
}

export interface SelectLocationFormValues {
  location: string;
  index: string;
}

const SelectLocationForm: React.FC<Omit<ControllerProps, 'render' | 'name'>> = ({defaultValue}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const {t} = useTranslation();

  return (
    <Form.Layout layoutType="grid" noPadding>
      <Controller
        defaultValue={defaultValue?.location}
        name={SelectLocationFormFields.Location}
        control={control}
        rules={{required: {value: true, message: t('modals.messages.required')}}}
        render={({field: {onChange, value}}) => (
          <Form.Field label={`${t('modals.location')} *`} error={errors[SelectLocationFormFields.Location]?.message}>
            <LocationsSelect
              onValueChange={onChange}
              value={value}
              placeholder={t('placeholders.location')}
              position="fixed"
            />
          </Form.Field>
        )}
      />
      <Controller
        defaultValue={defaultValue?.index}
        name={SelectLocationFormFields.Index}
        control={control}
        rules={{required: {value: true, message: t('modals.messages.required')}}}
        render={({field: {onChange, value}}) => (
          <Form.Field label={`${t('modals.index')} *`} error={errors[SelectLocationFormFields.Index]?.message}>
            <Input value={value} onChange={onChange} defaultValue={value} placeholder={t('placeholders.postIndex')} />{' '}
          </Form.Field>
        )}
      />
    </Form.Layout>
  );
};

export default SelectLocationForm;
