import {styled, Typography} from '@innowise-group/ui-kit';

export const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.palette.general.smart};
  color: ${({theme}) => theme.palette.general.paper};
  text-decoration: none;
  border-radius: 6px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: normal;
  border: none;
  margin-left: 30px;
  min-height: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.palette.state.error.hover};
  }
`;

export const TogglesContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  @media (max-width: 769px) {
    display: none;
  }
`;

export const HelpLink = styled.a`
  bottom: 120px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
  &:hover {
    background: ${({theme}) => theme.palette.general.lightGrey[20]};
  }

  @media (max-width: 769px) {
    bottom: 80px;
  }
`;

export const SupportLink = styled(HelpLink)`
  bottom: 70px;
  border-top: 1px solid #ebebeb;
  width: 100%;
  @media (max-width: 769px) {
    bottom: 40px;
  }
`;
export const HelpText = styled.div`
  position: absolute;
  left: 55px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
`;

export const LogoutLink = styled(HelpLink)`
  bottom: 70px;
  border-top: 1px solid #ebebeb;
  width: 100%;
  @media (max-width: 769px) {
    bottom: 40px;
  }
`;

export const ProfileLink = styled(HelpLink)`
  bottom: 70px;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  @media (max-width: 769px) {
    bottom: 40px;
  }
`;

export const SettingsSubMenu = styled.div`
  bottom: 120px;
  width: inherit;
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  @media (max-width: 769px) {
    bottom: 40px;
  }
`;

export const SettingsText = styled(Typography)`
  position: absolute;
  left: 55px;
  white-space: nowrap;
  font-weight: 400;
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
`;

export const ProfileText = styled(Typography)`
  position: absolute;
  left: 55px;
  white-space: nowrap;
  font-weight: 400;
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
`;

export const LogoutText = styled.div`
  position: absolute;
  left: 55px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
`;

export const IconContainer = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserMenuContainer = styled.div`
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 10px;
  height: 100%;
  align-items: center;
  transition: 0.2s;

  @media (max-width: 481px) {
    padding: 0;
  }
`;

export const ThemeSubMenu = styled.div`
  height: 50px;
  display: flex;
  padding: 0 8%;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
`;

export const ThemeText = styled(Typography)`
  white-space: nowrap;
  font-weight: 400;
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
`;

export const LanguageSubMenu = styled.div`
  height: 50px;
  display: flex;
  padding: 0 8%;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
`;

export const LanguageText = styled(Typography)`
  white-space: nowrap;
  font-weight: 400;
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
`;
