import {Elements} from 'react-flow-renderer';
import {useEffect, useState} from 'react';
import {WorkspaceElementData} from '../../components/workspace-view';
import {PlanElementData} from '../../components/plan-element-vew';

export interface UseFlowElementsResult {
  elements: Elements;
}

export const useFlowElements = (
  workspaces: Elements<WorkspaceElementData>,
  rooms: Elements<PlanElementData>,
  planElements: Elements<PlanElementData>,
): UseFlowElementsResult => {
  const [elements, setElements] = useState<Elements>([]);

  useEffect(() => {
    if (workspaces?.length || rooms?.length || planElements?.length) {
      setElements([...rooms, ...planElements, ...workspaces]);
    }
  }, [workspaces, rooms, planElements]);

  return {
    elements,
  };
};
