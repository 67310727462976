import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Elements, OnLoadParams, Node} from 'react-flow-renderer';
import {Employee, useContainerContext, RoomType, useService, useSubscription, Workspace} from '@innowise-group/core';
import {parse} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {combineLatest, finalize, Observable, of} from 'rxjs';
import {SearchFloorValues} from '../../components/search-floor-form';
import {BookingWorkspaceModeFormValues} from '../../components/booking-workspace-mode-form';
import {FloorsDataManagerService} from '../../services/floors-data-manager';
import {
  WorkspaceEventDataType,
  DeletedWorkspaceDataType,
  ErrorDataType,
  FloorsEventEmitterService,
  FloorsSocketEmitMultipleEvents,
  ListWorkspaceDataType,
  PatchedRoomDataType,
} from '../../services/floors-event-emitter';
import {FloorsModalsFacadeService} from '../../services/floors-modals-facade';
import {AddWorkspaceValues} from '../../components/add-workspace-form';
import {editWorkspaceUtility} from '../../utilities/edit-workspace.utility';
import {updateWorkspaceData, UpdatedWorkspaceDataResult} from '../../utilities/update-workspace-data.utility';
import {createEmptyElement} from '../../utilities/create-empty-element.utility';

import {useWorkspaceBooking} from './use-workspace-booking.hook';
import {useKeyPress} from './use-key-press.hook';
import {useFloorReport} from './use-floor-report.hook';
import {FloorData, usePlanDetails} from './use-plan-details.hook';
import {useFloorEvents} from './use-floor-events.hook';
import {useFlowElementsDrag} from './use-flow-elements-drag.hook';
import {useFlowElements} from './use-flow-elements.hook';

export type Room = {id: string; number: string; roomType: RoomType; coordinates: {[key: string]: number}};

interface UseFloorDetailsResult {
  flowElements: Elements;
  floor: FloorData;
  handleAddNewWorkspace: (isVirtual: boolean) => void;
  isPlanExists: boolean;
  isPlanLoading: boolean;
  reactFlowWrapper: React.MutableRefObject<HTMLDivElement>;
  onLoad: (_reactFlowInstance: OnLoadParams) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleUploadPlan: () => void;
  onNodeDragStop: (event: React.MouseEvent<Element, MouseEvent>, node: Node) => void;
  handleSearchFloorSubmit: (data: SearchFloorValues) => void;
  handleBookingWorkspaceMode: (data: BookingWorkspaceModeFormValues) => void;
  handleBookWorkspace: () => void;
  selectable: boolean;
  abortChanges: () => void;
  saveChanges: () => void;
  floorsEventEmitter: FloorsEventEmitterService;
  isClientReadyState: boolean;
}

export const useFloorDetails = (
  floorId: string,
  isViewMode: boolean,
  setEmployees: React.Dispatch<React.SetStateAction<Employee[]>>,
  showNotifications: boolean,
): UseFloorDetailsResult => {
  const [selectable, setSelectable] = useState(true);
  const [roomCoordinates, setRoomCoordinates] = useState<Room[]>([]);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const container = useContainerContext();
  const {
    planElements$,
    workspaces$,
    rooms$,
    initialWorkspaces$,
    createdWorkspaces$,
    deletedWorkspaces$,
    setWorkspacesSubscription,
    deleteWorkspaceSubscription,
    createWorkspaceSubscription,
    editWorkspaceSubscription,
    errorSubscription,
    editRoomSubscription,
    initialize,
    getWorkspaceByIdSubscription,
  } = useMemo<FloorsDataManagerService>(() => container.get(FloorsDataManagerService.type), [container]);
  const workspaces = useSubscription(workspaces$);
  const planElements = useSubscription(planElements$);
  const rooms = useSubscription(rooms$);
  const initialWorkspaces = useSubscription(initialWorkspaces$);
  const createdWorkspaces = useSubscription(createdWorkspaces$);
  const deletedWorkspaces = useSubscription(deletedWorkspaces$);
  const floorsModalsFacade = useService(FloorsModalsFacadeService);
  const floorsEventEmitter = useMemo<FloorsEventEmitterService>(
    () => container.get(FloorsEventEmitterService.type),
    [container],
  );

  // Process Space key press
  useKeyPress('Space', setSelectable);

  // Get plan details
  const {floor, isPlanLoading, isPlanExists, setIsPlanLoading, uploadPlanHandler} = usePlanDetails(
    floorId,
    setRoomCoordinates,
    initialize,
    isViewMode,
  );

  // Handle booking statuses
  const {bookingWorkspaceMode, bookWorkspace, bookChosenWorkspace, bookWorkspaceDateRange} = useWorkspaceBooking(
    floor,
    setIsPlanLoading,
    floorId,
    workspaces$,
    floorsEventEmitter,
  );

  // Handle Drag events
  const {nodeDragStopHandler, dragHandler, dragOverHandler, reactFlowWrapper, loadHandler} = useFlowElementsDrag(
    workspaces,
    roomCoordinates,
    floor?.isVirtual,
    floorsEventEmitter,
    bookWorkspaceDateRange,
  );

  // Get reports methods
  const {getCSVReport, getPDFReport, getXLSXReport} = useFloorReport(floor, reactFlowWrapper);

  const [workspacesToRevert, setWorkspacesToRevert] = useState<Workspace[]>([]);

  const [isClientReadyState, setIsClientReadyState] = useState(false);
  const handleClientState = () => {
    setIsClientReadyState(true);
  };
  useEffect(() => {
    floorsEventEmitter.addListener('clientReady', handleClientState);
    return () => {
      floorsEventEmitter.removeListener('clientReady', handleClientState);
    };
  }, [floorsEventEmitter]);

  const handleEditWorkspace = useCallback(
    (data: Workspace) => {
      const {number, range, employee, status, bookedStatus, id, ...rest} = data;
      const startDay = range?.startDay ? range?.startDay : bookWorkspaceDateRange?.range?.startDay;
      const endDay = range?.endDay ? range?.endDay : bookWorkspaceDateRange?.range?.endDay;

      floorsModalsFacade.openWorkspaceActionsModal(
        (values: AddWorkspaceValues & {selectedData: string}) => {
          const editedWorkspace = editWorkspaceUtility(data, values);
          const updatedWorkspace = updateWorkspaceData(editedWorkspace);
          floorsEventEmitter.emit('patch', {workspace: updatedWorkspace});
          if (employee?.id) {
            const workspace = workspaces.find(({data}) => data.employee?.id === employee?.id);
            setEmployees((employees) => employees.filter(({id}) => id !== employee?.id));
            if (workspace) {
              floorsEventEmitter.emit('retrieve', {id: Number(workspace.data.id)});
            }
          }
          floorsEventEmitter.emit('list');
        },
        floor?.address,
        {
          id: id,
          number,
          dateRange: [startDay ? new Date(startDay) : undefined, endDay ? new Date(endDay) : undefined],
          employee: employee?.id,
          status: bookWorkspaceDateRange ? 'Booked' : status,
          ...rest,
        },
        `${t('modals.workspace')} ${number ? `№${number}` : ''}`,
        floor?.isVirtual,
        bookedStatus?.map((status) => ({
          employeeId: status.employee.id,
          range: {
            start: parse(status.range.startDay, 'dd.MM.yyyy', new Date()),
            end: parse(status.range.endDay, 'dd.MM.yyyy', new Date()),
          },
        })),
        setWorkspacesToRevert,
      );
    },
    [
      floorsModalsFacade,
      bookWorkspaceDateRange,
      t,
      floor.address,
      floor.isVirtual,
      setEmployees,
      floorsEventEmitter,
      workspaces,
    ],
  );

  const handleEditRoom = useCallback(
    (roomId: string, type: RoomType, number: string, isOur: boolean) => {
      floorsModalsFacade.openEditRoomFormModal(
        (values) => {
          const room = {id: Number(roomId), number: values.number, room_type: values.type, our: values.isOur};
          floorsEventEmitter.emit('patch_room', {room});
        },
        {isOur, number, type},
        t('modals.editRoom'),
      );
    },
    [floorsModalsFacade, t, floorsEventEmitter],
  );

  const eventsMap = useMemo(() => {
    return {
      list_workspace: ({workspaces}: ListWorkspaceDataType) => {
        setWorkspacesSubscription(workspaces, isViewMode);
      },
      delete_workspace: ({to_delete, deleted_workspace_id}: DeletedWorkspaceDataType) => {
        deleteWorkspaceSubscription(to_delete, isViewMode, deleted_workspace_id, showNotifications);
      },
      create_workspace: ({workspace}: WorkspaceEventDataType<'create_workspace'>) => {
        createWorkspaceSubscription(workspace, isViewMode, showNotifications);
      },
      change_workspace: ({workspace}: WorkspaceEventDataType<'change_workspace'>) => {
        editWorkspaceSubscription(workspace, isViewMode, true, showNotifications);
      },
      error: ({error, status_code}: ErrorDataType) => {
        errorSubscription(status_code, error, showNotifications);
      },
      patch_room_response: ({room}: PatchedRoomDataType) => {
        editRoomSubscription(room, showNotifications);
      },
      rotate_workspace_result: ({workspace}: WorkspaceEventDataType<'rotate_workspace_result'>) => {
        editWorkspaceSubscription(workspace, isViewMode, false, false);
      },
      retrieve_workspace: ({workspace}: WorkspaceEventDataType<'retrieve_workspace'>) => {
        getWorkspaceByIdSubscription(workspace, isViewMode);
      },
      change_workspace_drag_n_drop: ({workspace}: WorkspaceEventDataType<'change_workspace_drag_n_drop'>) => {
        editWorkspaceSubscription(workspace, isViewMode, true, showNotifications);
      },
      downloadCSVReport: getCSVReport,
      downloadPDFReport: getPDFReport,
      downloadXLSXReport: getXLSXReport,
      bookWorkspace: bookChosenWorkspace,
      editWorkspace: handleEditWorkspace,
      editRoom: handleEditRoom,
    };
  }, [
    bookChosenWorkspace,
    createWorkspaceSubscription,
    deleteWorkspaceSubscription,
    editRoomSubscription,
    editWorkspaceSubscription,
    errorSubscription,
    getCSVReport,
    getPDFReport,
    getWorkspaceByIdSubscription,
    getXLSXReport,
    handleEditRoom,
    handleEditWorkspace,
    isViewMode,
    setWorkspacesSubscription,
    showNotifications,
  ]);

  // Handle socket events
  useFloorEvents(eventsMap, floorsEventEmitter, isClientReadyState);

  const abortChanges = useCallback(() => {
    const workspacesToUpdate = initialWorkspaces
      .map(({data}) => updateWorkspaceData(data, true))
      .concat(workspacesToRevert.map((workspace) => updateWorkspaceData(workspace, true)));

    const workspacesToDelete = createdWorkspaces.map(({data}) => Number(data.id));
    const workspacesToCreate = deletedWorkspaces.map(({data}) => updateWorkspaceData(data, true));

    function emitMultipleEvent(
      event: keyof FloorsSocketEmitMultipleEvents,
      args: {[key: string]: number[] | UpdatedWorkspaceDataResult[]},
    ): Observable<unknown> {
      const value = Object.values(args);
      return value[0].length > 0
        ? new Observable<unknown>((observer) => {
            floorsEventEmitter.emit(event, args);
            observer.complete();
          })
        : of({});
    }

    combineLatest([
      emitMultipleEvent('multiple_patch', {workspace_list: workspacesToUpdate}),
      emitMultipleEvent('multiple_delete', {id_list: workspacesToDelete}),
      emitMultipleEvent('multiple_create', {workspace_list: workspacesToCreate}),
    ])
      .pipe(
        finalize(() => {
          floorsEventEmitter.emit('list');
          createdWorkspaces$.next([]);
          deletedWorkspaces$.next([]);
        }),
      )
      .subscribe(() => setWorkspacesToRevert([]));
  }, [
    initialWorkspaces,
    createdWorkspaces,
    deletedWorkspaces,
    floorsEventEmitter,
    createdWorkspaces$,
    deletedWorkspaces$,
    workspacesToRevert,
  ]);

  const saveChanges = useCallback(() => {
    initialWorkspaces$.next(workspaces);
    createdWorkspaces$.next([]);
    deletedWorkspaces$.next([]);
    floorsEventEmitter.emit('list');
    setWorkspacesToRevert([]);
  }, [createdWorkspaces$, deletedWorkspaces$, floorsEventEmitter, initialWorkspaces$, workspaces]);

  const handleSearchFloorSubmit = useCallback(
    (data: SearchFloorValues) => {
      setIsPlanLoading(true);
      navigate(`/floors/${data.floor}`);
      setRoomCoordinates([]);
    },
    [navigate, setIsPlanLoading],
  );

  const {elements} = useFlowElements(workspaces, rooms, planElements);

  useEffect(() => {
    floorsEventEmitter.connect(floorId);
    return () => floorsEventEmitter.close();
  }, [floorsEventEmitter, floorId]);

  useEffect(() => {
    const workspaces = workspaces$.getValue();
    const newWorkspaces = workspaces.map((element) => ({
      ...element,
      data: {...element.data, isViewMode},
      draggable: !isViewMode,
    }));
    workspaces$.next(newWorkspaces);
    const rooms = rooms$.getValue();
    const newRooms = rooms.map((element) => ({
      ...element,
      data: {...element.data, isViewMode},
    }));
    rooms$.next(newRooms);
  }, [isViewMode, workspaces$, rooms$]);

  useEffect(() => {
    const rooms = rooms$.getValue();
    const newRooms = rooms.map((element) => ({...element, selectable}));
    rooms$.next(newRooms);
  }, [rooms$, selectable]);

  const handleAddNewWorkspace = useCallback(
    (isVirtual: boolean) => {
      const newWorkspace = createEmptyElement(isVirtual);
      const workspace = updateWorkspaceData(newWorkspace);
      floorsEventEmitter.emit('create', {workspace});
    },
    [floorsEventEmitter],
  );

  return {
    flowElements: elements,
    floor,
    handleAddNewWorkspace,
    isPlanLoading,
    isPlanExists,
    reactFlowWrapper,
    onLoad: loadHandler,
    onDragOver: dragOverHandler,
    onDrop: dragHandler,
    handleUploadPlan: uploadPlanHandler,
    onNodeDragStop: nodeDragStopHandler,
    handleSearchFloorSubmit,
    handleBookingWorkspaceMode: bookingWorkspaceMode,
    handleBookWorkspace: bookWorkspace,
    abortChanges,
    saveChanges,
    selectable,
    floorsEventEmitter,
    isClientReadyState,
  };
};
