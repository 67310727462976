import {FloorsApiService, useAppLanguage, useService} from '@innowise-group/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {MutableRefObject, useCallback} from 'react';
import {getReport, getReportName} from '../../utilities/get-report.utility';
import {FloorData} from './use-plan-details.hook';

export interface UseFloorReportResult {
  getCSVReport: () => void;
  getPDFReport: () => void;
  getXLSXReport: () => void;
}

export const useFloorReport = (
  floor: FloorData,
  reactFlowWrapper: MutableRefObject<HTMLDivElement>,
): UseFloorReportResult => {
  const floorsApi = useService(FloorsApiService);
  const language = useAppLanguage();

  const getCSVReport = useCallback(() => {
    getReport(floorsApi.getReport, floor, 'csv');
  }, [floorsApi, floor]);

  const getPDFReport = useCallback(() => {
    html2canvas(reactFlowWrapper.current, {useCORS: true, scale: 4})
      .then((canvas) => {
        const planWidth = reactFlowWrapper.current.offsetWidth;
        const planHeight = reactFlowWrapper.current.offsetHeight;
        const orientation = planWidth >= planHeight ? 'l' : 'p';
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({orientation});

        let width = pdf.internal.pageSize.getWidth();
        let height = pdf.internal.pageSize.getHeight();
        let widthRatio = width / canvas.width;
        let heightRatio = height / canvas.height;
        let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        pdf.addImage(imgData, 'JPEG', 0, 20, canvas.width * ratio, canvas.height * ratio, undefined, 'FAST');

        pdf.save(`${getReportName(floor.fullAddress, floor.isVirtual, language)}.pdf`);
      })
      .catch((e) => console.log(e));
  }, [reactFlowWrapper, floor.fullAddress, floor.isVirtual, language]);

  const getXLSXReport = useCallback(() => {
    getReport(floorsApi.getReport, floor, 'xlsx');
  }, [floorsApi, floor]);

  return {
    getCSVReport,
    getPDFReport,
    getXLSXReport,
  };
};
