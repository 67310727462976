import {useEffect} from 'react';
import {Controller, ControllerProps, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Form, CustomDatePicker} from '@innowise-group/ui-kit';
import {WorkspaceBookType} from '@innowise-group/core';
import {ExcludeDates} from '@shared-components';
import {SelectStatusFormFields} from '../workspace-status-form';

export enum WorkspaceBookingDateFormFields {
  DateRange = 'dateRange',
}

export interface WorkspaceBookingDateFormValues {
  dateRange: [Date, Date];
}

const WorkspaceBookingDateForm: React.FC<
  Omit<ControllerProps, 'render' | 'name'> & {status?: WorkspaceBookType; datesToExclude?: ExcludeDates}
> = ({defaultValue, status, datesToExclude, ...props}) => {
  const {
    control,
    formState: {errors},
    watch,
    clearErrors,
  } = useFormContext();

  const {t} = useTranslation();
  useEffect(() => clearErrors(WorkspaceBookingDateFormFields.DateRange), [clearErrors, status]);

  const checkIfRequired = () => {
    return status === 'Booked';
  };

  return (
    <Form.Layout layoutType="horizontal">
      <Controller
        {...props}
        name={WorkspaceBookingDateFormFields.DateRange}
        defaultValue={[
          defaultValue?.startDay ? new Date(defaultValue.startDay) : undefined,
          defaultValue?.endDay ? new Date(defaultValue.endDay) : undefined,
        ]}
        control={control}
        rules={{validate: (value) => !checkIfRequired() || value?.every(Boolean) || t('modals.messages.required')}}
        render={({field: {onChange, value}}) => (
          <Form.Field
            label={`${t('modals.bookingStart')} / ${t('modals.bookingEnd')} ${checkIfRequired() ? '*' : ''}`}
            error={errors[WorkspaceBookingDateFormFields.DateRange]?.message}
          >
            <CustomDatePicker
              selectsRange
              selected={watch(WorkspaceBookingDateFormFields.DateRange)?.[0]}
              startDate={watch(WorkspaceBookingDateFormFields.DateRange)?.[0]}
              endDate={watch(WorkspaceBookingDateFormFields.DateRange)?.[1]}
              onChange={onChange}
              dateFormat="dd/MM/yyyy"
              placeholderText={t('placeholders.datePicker')}
              minDate={new Date()}
              excludeDateIntervals={datesToExclude?.map((status) => status.range)}
              disabled={watch(SelectStatusFormFields.WorkspaceStatus, status) !== 'Booked'}
              popperPlacement="top"
              isClearable={true}
            />
          </Form.Field>
        )}
      />
    </Form.Layout>
  );
};

export default WorkspaceBookingDateForm;
