export const CONFIRMED_VISIT = 'Confirmed Visit';
export const UNCONFIRMED_VISIT = 'Unconfirmed Visit';
export const REMOTE_WORK_WITH_VISIT = 'Remote Work with Visit';
export const REMOTE_WORK = 'Remote Work';
export const ABSENT = 'Absent';
export const NOT_DEFINED = 'Not defined';

export const colors = {
  [CONFIRMED_VISIT]: '#2AB0A8',
  [UNCONFIRMED_VISIT]: '#3179E3',
  [REMOTE_WORK_WITH_VISIT]: ['#2AB0A8', '#8B53E9'],
  [REMOTE_WORK]: '#8B53E9',
  [ABSENT]: '#AEAEAE',
  [NOT_DEFINED]: 'rgb(53, 162, 235)',
};

export const chartLabels = {
  [CONFIRMED_VISIT]: 'pages.geotrack.workingDays.confirmedVisit',
  [UNCONFIRMED_VISIT]: 'pages.geotrack.workingDays.unconfirmedVisit',
  [REMOTE_WORK_WITH_VISIT]: 'pages.geotrack.workingDays.remoteWorkWithVisit',
  [REMOTE_WORK]: 'pages.geotrack.workingDays.remoteWork',
  [ABSENT]: 'pages.geotrack.workingDays.absent',
  [NOT_DEFINED]: 'pages.geotrack.workingDays.notDefined',
};

export const options = {
  plugins: {
    title: {
      display: true,
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};
