import {css, styled} from '../theme-provider';

export const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Label = styled.label<{position?: 'left' | 'right'; disabled?: boolean}>`
  align-items: center;
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
  cursor: pointer;
  display: flex;
  flex-direction: ${({position}) => (position === 'left' ? 'row' : 'row-reverse')};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  ${({disabled}) => {
    return css`
      opacity: ${disabled ? 0.5 : 1};
    `;
  }}
`;

export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:disabled {
    opacity: 0.5;
  }
`;

export const VisibleSwitch = styled.div<{checked?: boolean; position?: 'left' | 'right'}>`
  align-items: center;
  background: #e1e1e1;
  border-radius: 360px;
  border: ${({theme: {palette}}) => `solid 1px ${palette.general.lightGrey[80]}`};
  box-shadow: ${({theme: {palette}}) => `inset 0 0 4px 2px ${palette.general.lightGrey[80]}`};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: flex-start;
  margin-left: ${({position}) => (position === 'right' ? '12px' : 0)};
  margin-right: ${({position}) => (position === 'left' ? '12px' : 0)};
  min-height: 30px;
  min-width: 60px;
  padding: 1px;
  transition-duration: 0.25s;
  transition-property: background border-color;
  width: 60px;

  &.checked {
    background: #2ab0a8;
    border: solid 1px #098c84;
    box-shadow: inset 0 0 4px 2px #098c84;
    justify-content: flex-end;
  }

  &:hover span {
    box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.125), 0 0 2px 1px rgba(0, 0, 0, 0.25);
  }
`;

export const Pin = styled.span`
  background: ${({theme}) => theme.palette.generalAlt.background.primary};
  border-radius: 50%;
  box-shadow: none;
  display: inline-block;
  height: 26px;
  transition-duration: 0.3s;
  transition-property: box-shadow;
  width: 26px;
`;
