import styled from 'styled-components';

export const FormError = styled.span`
  color: ${({theme}) => theme.palette.state.error.default};
  position: absolute;
  bottom: 120px;
  left: 100px;
  font-size: 14px;

  @media (max-width: 991px) {
    bottom: 58px;
    left: 0px;
  }
  @media (max-width: 481px) {
    bottom: 44px;
    left: 0px;
    font-size: 10px;
  }
`;
