import {format} from 'date-fns';
import {
  inject,
  injectable,
  BookedStatus,
  GlobalContainerTypes,
  Workspace,
  AvailableRoomsAndWorkspaces,
  AvailableRoomsAndWorkspacesRequest,
} from '../..';
import {WorkspacesNormalization} from './workspaces-normalization.types';
import {EmployeesNormalization} from '../employees-normalization';
import {BookedStatusResponse, WorkspaceResponse} from '../workspaces-api';

@injectable()
class WorkspacesNormalizationService implements WorkspacesNormalization {
  public static readonly type = GlobalContainerTypes.FloorsNormalization;

  @inject(GlobalContainerTypes.EmployeesNormalization) private employeesNormalization: EmployeesNormalization;

  constructor() {
    this.normalizeWorkspaceFromApi = this.normalizeWorkspaceFromApi.bind(this);
    this.normalizeBookedStatusFromApi = this.normalizeBookedStatusFromApi.bind(this);
    this.normalizeAvailableRoomsAndWorkspacesFromApi = this.normalizeAvailableRoomsAndWorkspacesFromApi.bind(this);
  }

  public normalizeWorkspaceFromApi(workspaces: WorkspaceResponse[], type: string): Workspace[] {
    return workspaces.map(
      ({
        status,
        id,
        number,
        room,
        room_number,
        room_type,
        employee,
        occupation_end_date,
        occupation_start_date,
        comment,
        coordinates,
        booked_status = [],
        active_requests = [],
        blue,
        rotate_degree,
      }) => ({
        type,
        id: id && String(id),
        number: number && String(number),
        status,
        room: {id: room && String(room), number: room_number, room_type: room_type},
        employee: this.employeesNormalization.normalizeEmployeeFromApi(employee),
        comment,
        bookedStatus: booked_status?.length ? this.normalizeBookedStatusFromApi(booked_status) : [],
        activeRequests: active_requests?.length ? this.normalizeBookedStatusFromApi(active_requests) : [],
        isBooked: blue,
        range: {
          startDay: occupation_start_date && format(new Date(occupation_start_date), 'yyyy-MM-dd'),
          endDay: occupation_end_date && format(new Date(occupation_end_date), 'yyyy-MM-dd'),
        },
        rotateDegree: rotate_degree,
        coordinates: coordinates,
        width: 100,
        height: 50,
      }),
    );
  }

  public normalizeAvailableRoomsAndWorkspacesFromApi(
    rooms: AvailableRoomsAndWorkspacesRequest[],
  ): AvailableRoomsAndWorkspaces[] {
    return rooms.map((room) => ({
      id: room.id.toString(),
      number: room.number,
      floor: room.floor,
      roomType: room.room_type,
      isOur: room.our,
      workspaces: room.workspaces.map((workspace) => ({
        id: workspace.id.toString(),
        number: workspace.number.toString(),
      })),
    }));
  }

  private normalizeBookedStatusFromApi(booked_status: BookedStatusResponse[]): BookedStatus[] {
    return booked_status
      .sort((a, b) => new Date(a.occupation_start_date).getTime() - new Date(b.occupation_start_date).getTime())
      .map(({id, employee, occupation_end_date, occupation_start_date, comment, workspace}) => ({
        id: id && String(id),
        number: workspace && String(workspace),
        employee: this.employeesNormalization.normalizeEmployeeFromApi(employee),
        comment,
        range: {
          startDay: occupation_start_date && format(new Date(occupation_start_date), 'dd.MM.yyyy'),
          endDay: occupation_end_date && format(new Date(occupation_end_date), 'dd.MM.yyyy'),
        },
      }));
  }
}

export default WorkspacesNormalizationService;
