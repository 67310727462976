import {FadeInOutView} from '../fade-in-out-view';
import {Typography} from '../typography';
import * as Styled from './tabs.styles';

export interface TabProps extends React.HTMLAttributes<HTMLLIElement> {
  label?: string;
  value?: unknown;
  tabsvalue?: unknown;
  path?: string;
}

const Tab: React.FC<TabProps> = ({label, tabsvalue, value, path = '#', ...props}) => {
  const selected = tabsvalue === value;
  return (
    <Styled.TabContainer {...props} selected={selected}>
      <Styled.NavigationLink to={path}>
        <Typography>{label}</Typography>
      </Styled.NavigationLink>
      {selected ? (
        <FadeInOutView>
          <Styled.TabSelectionLine />
        </FadeInOutView>
      ) : null}
    </Styled.TabContainer>
  );
};

export default Tab;
