import {styled, Typography} from '@innowise-group/ui-kit';

export const UnorderedList = styled.ul`
  padding-inline-start: 17px;
`;

export const ListItem = styled.li`
  &::marker {
    color: ${({theme}) => theme.palette.general.darkGrey[100]};

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`;

export const VersionText = styled(Typography)`
  color: ${({theme}) => theme.palette.general.smart};
`;
