import {darkTheme, GlobalThemeContext, lightTheme, ThemeProvider} from '@innowise-group/ui-kit';
import React, {useContext, useMemo} from 'react';

const light = lightTheme();
const dark = darkTheme();

const ThemeWrapper: React.FC = ({children}) => {
  const currentTheme = useContext(GlobalThemeContext);

  const theme = useMemo(() => {
    switch (currentTheme.theme) {
      case 'light':
        return light;
      case 'dark':
        return dark;
    }
  }, [currentTheme.theme]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <>{children}</>
      </ThemeProvider>
    </>
  );
};

export default ThemeWrapper;
