import {styled} from '../theme-provider';

export const Loader = styled.div<{size: number}>`
  border-radius: 50%;
  width: ${({size}) => `${size}px`};
  height: ${({size}) => `${size}px`};

  &:after {
    border-radius: 50%;
    width: ${({size}) => `${size}px`};
    height: ${({size}) => `${size}px`};
  }

  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid ${({theme}) => theme.palette.state.error.background};
  border-right: 2px solid ${({theme}) => theme.palette.state.error.background};
  border-bottom: 2px solid ${({theme}) => theme.palette.state.error.background};
  border-left: 2px solid ${({theme}) => theme.palette.general.smart};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
