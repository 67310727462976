import {RequestType} from '@shared-components';
import {Employee} from './employee.model';

export type RequestStatus = 'in-clarification' | 'approved' | 'rejected' | 'canceled' | 'delayed' | 'conflict';

export const RequestsStatuses: RequestStatus[] = ['in-clarification', 'approved', 'rejected', 'canceled', 'delayed'];

export interface Request {
  id: string;
  user: Employee;
  office: {address: string; id: string};
  room: {number: string; id: string};
  seat: {number: string; id: string};
  range: {
    start: Date;
    end: Date;
  };
  status: RequestStatus;
  created: Date;
  floor: {number: string; id: string};
  comment: string;
  managerComment: string;
  officeManager: Employee;
  personalRequest: boolean;
  requestType: RequestType;
}
