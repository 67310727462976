import React, {useState, useRef} from 'react';
import {Controller, ControllerProps, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Form, Input, Icon, Typography, useTheme} from '@innowise-group/ui-kit';
import * as Styled from './select-file-form.styles';

export enum SelectFileFormFields {
  Layout = 'layout',
}

export interface SelectFileFormValues {
  [SelectFileFormFields.Layout]: File;
}

const SelectFileForm: React.FC<Omit<ControllerProps, 'render' | 'name'>> = (props) => {
  const {
    control,
    formState: {errors},
    resetField,
  } = useFormContext();

  const [drag, setDrag] = useState<boolean>(false);

  const uploadFileRef = useRef<HTMLInputElement>(null);

  const {t} = useTranslation();

  const theme = useTheme();

  const dragStartHandler = (event: React.DragEvent) => {
    event.preventDefault();
    setDrag(true);
  };

  const dragLeaveHandler = (event: React.DragEvent) => {
    event.preventDefault();
    setDrag(false);
  };

  const onDropHandler = (event: React.DragEvent, onChange) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    onChange(file);
    setDrag(false);
  };

  const uploadChangeHandler = (event, onChange) => onChange(event.target.files[0]);

  const uploadFile = (e) => {
    e.preventDefault();
    uploadFileRef.current.click();
  };

  return (
    <Controller
      {...props}
      name={SelectFileFormFields.Layout}
      control={control}
      rules={{
        required: {value: true, message: t('modals.messages.noPlan')},
        validate: (file) =>
          file && file?.name.split('.').pop() !== 'svg' ? (t('modals.messages.wrongFormat') as string) : null,
      }}
      render={({field: {onChange, value}}) => (
        <Form.Field
          label={t('modals.plan')}
          error={errors[SelectFileFormFields.Layout]?.message}
          errorType={errors[SelectFileFormFields.Layout]?.type as unknown as string}
        >
          <Styled.DropArea
            onDragLeave={dragLeaveHandler}
            onDragOver={dragStartHandler}
            onDrop={(event) => onDropHandler(event, onChange)}
          >
            {value && (
              <>
                <Styled.PlanIcon>
                  <Icon className="svg_icon" type="u_clipboard" size={50} />
                </Styled.PlanIcon>
                <span>{value?.name}</span>
                <Styled.ClearIcon>
                  <Icon
                    onClick={() => {
                      resetField('layout');
                      setDrag(false);
                    }}
                    className="upload_cancel"
                    type="u_multiply"
                  />
                </Styled.ClearIcon>
              </>
            )}
            <Styled.DropAreaInputFile>
              <Input type="file" />
            </Styled.DropAreaInputFile>
            <Typography>
              {drag && !value
                ? t('modals.messages.dropFileMessage')
                : !value
                ? t('modals.messages.uploadPlanInfo')
                : null}
            </Typography>
          </Styled.DropArea>
          <Input
            type="file"
            ref={uploadFileRef}
            hidden
            onChange={(event) => uploadChangeHandler(event, onChange)}
            defaultValue={value}
          />
          <Styled.UploadButton
            variant="text"
            onClick={uploadFile}
            icon="u_plus"
            iconColor={theme.palette.general.smart}
          >
            {t('buttons.uploadPlan')}
          </Styled.UploadButton>
        </Form.Field>
      )}
    />
  );
};

export default SelectFileForm;
