import {useLayoutEffect} from 'react';
import {useFormContext, Controller, ControllerProps} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Form} from '@innowise-group/ui-kit';
import {EmployeesSelect} from '@shared-components';
import {WorkspaceBookType} from '@innowise-group/core';

export enum WorkspaceEmployeeFormFields {
  Employee = 'employee',
}

export interface WorkspaceEmployeeFormValues {
  employee: string;
}

const WorkspaceEmployeeForm: React.FC<
  Omit<ControllerProps, 'render' | 'name'> & {status?: WorkspaceBookType; comment: string}
> = ({status, comment, defaultValue}) => {
  const {
    control,
    formState: {errors},
    clearErrors,
  } = useFormContext();

  const {t} = useTranslation();

  useLayoutEffect(() => clearErrors([WorkspaceEmployeeFormFields.Employee]), [clearErrors, status]);

  const checkIfRequired = () => {
    return status === 'Booked' || status === 'Occupied' || (status === 'Reserved' && !comment);
  };

  return (
    <Form.Layout layoutType="vertical">
      <Controller
        name={WorkspaceEmployeeFormFields.Employee}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: {
            value: checkIfRequired(),
            message:
              status === 'Reserved'
                ? t('modals.messages.reservedWorkspaceFieldsErrors')
                : t('modals.messages.required'),
          },
        }}
        render={({field: {onChange, value}}) => (
          <Form.Field
            label={`${t('modals.employee')} ${checkIfRequired() ? '*' : ''}`}
            error={status !== 'Reserved' && errors[WorkspaceEmployeeFormFields.Employee]?.message}
          >
            <EmployeesSelect
              value={value}
              onValueChange={onChange}
              placeholder={t('placeholders.employee')}
              defaultValue={defaultValue}
              position="fixed"
            />
          </Form.Field>
        )}
      />
    </Form.Layout>
  );
};

export default WorkspaceEmployeeForm;
