import React, {useContext, useEffect, useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Avatar} from '@innowise-group/ui-kit';
import {ApplicationBar, Icon, Typography, useClickOutside, useTheme} from '@innowise-group/ui-kit';
import {LanguageService, PersonContext, useService, WorkspaceBookType} from '@innowise-group/core';
import {redirectToHRMProfile} from '@innowise-group/utilities';
import {SearchEmployeeSeat, ThemeSwitch, MyWorkspaceButton} from '..';
import {AppRoutes} from '../../core/constants/app.router';
import * as Styled from './application-bar-container.styles';
import * as BarStyled from '@shared/ui-kit/application-bar/application-bar.styles';

import {LanguageSwitch} from '@shared/components/language-switch';
import {NavBarCollapseContext} from '@shared/core/services/nav-bar';

interface ApplicationBarContainerProps {
  onLogout: () => void;
  checkAccessByWorkspaceStatus?: (workspaceStatus: WorkspaceBookType) => boolean;
}
const ApplicationBarContainer: React.FC<ApplicationBarContainerProps> = ({
  onLogout,
  checkAccessByWorkspaceStatus,
  ...props
}) => {
  const {t, i18n} = useTranslation();
  const person = useContext(PersonContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const {getAppLanguage} = useService(LanguageService);
  const {toggleNavBar} = useContext(NavBarCollapseContext);

  useEffect(() => {
    i18n.changeLanguage(getAppLanguage());
  }, [getAppLanguage, i18n]);

  const handleGoHome = () => {
    navigate(AppRoutes.Home);
  };

  const [isUserActionsOpened, setIsUserActionsOpened] = useState(false);

  const closeUserActionsTooltip = () => {
    setIsUserActionsOpened(false);
  };

  const userMenuRef = useRef(null);

  useClickOutside(userMenuRef, closeUserActionsTooltip);

  const openUserActionsTooltip = () => {
    setIsUserActionsOpened((prev) => !prev);
  };

  const handleUserProfileClick = () => {
    person.hrm_id && redirectToHRMProfile(person.hrm_id);
  };
  const handleUserLogout = () => {
    onLogout();
  };

  const userContainer = (
    <BarStyled.UserPreviewContainer ref={userMenuRef}>
      <Styled.UserMenuContainer onClick={openUserActionsTooltip}>
        <Avatar imageUrl={person?.photoUrl} />
        <BarStyled.UserInfoContainer>
          <Typography>{person?.fullName}</Typography>
          <BarStyled.UserPosition>{person?.division}</BarStyled.UserPosition>
        </BarStyled.UserInfoContainer>
        <BarStyled.IconContainer desktopOnly isOpen={isUserActionsOpened} type="u_angle-down" />
      </Styled.UserMenuContainer>

      {isUserActionsOpened ? (
        <BarStyled.UserMenu>
          <Styled.ProfileLink onClick={handleUserProfileClick}>
            <Styled.IconContainer>
              <Icon type="u_user-circle" fill={theme.palette.generalAlt.font.tertiary} size={24} />
            </Styled.IconContainer>
            {<Styled.ProfileText>{t('buttons.profile')}</Styled.ProfileText>}
          </Styled.ProfileLink>
          <Styled.SettingsSubMenu>
            <Styled.IconContainer>
              <Icon type="u_setting" fill={theme.palette.generalAlt.font.tertiary} size={24} />
            </Styled.IconContainer>
            {<Styled.SettingsText>{t('buttons.settings')}</Styled.SettingsText>}
          </Styled.SettingsSubMenu>

          <Styled.LanguageSubMenu>
            {<Styled.LanguageText>{t('buttons.language')}</Styled.LanguageText>}
            <LanguageSwitch />
          </Styled.LanguageSubMenu>
          <Styled.ThemeSubMenu>
            {<Styled.ThemeText>{t('buttons.darkTheme')}</Styled.ThemeText>}
            <ThemeSwitch />
          </Styled.ThemeSubMenu>

          <Styled.SupportLink href="https://innowise-group.atlassian.net/servicedesk/customer/portals" target="_blank">
            <Styled.IconContainer>
              <Icon type="u_chat-info" fill={theme.palette.generalAlt.font.tertiary} size={24} />
            </Styled.IconContainer>
            {<Styled.HelpText>{t('buttons.support')}</Styled.HelpText>}
          </Styled.SupportLink>
          <Styled.HelpLink
            href="https://innowise-group.atlassian.net/wiki/spaces/CS/pages/2297593870/Hotelling+Tool"
            target="_blank"
          >
            <Styled.IconContainer>
              <Icon type="u_question-circle" fill={theme.palette.generalAlt.font.tertiary} size={24} />
            </Styled.IconContainer>
            {<Styled.HelpText>{t('buttons.help')}</Styled.HelpText>}
          </Styled.HelpLink>
          <Styled.LogoutLink onClick={handleUserLogout}>
            <Styled.IconContainer>
              <Icon type="u_exit" fill={theme.palette.generalAlt.red} size={24} />
            </Styled.IconContainer>
            <Styled.LogoutText>
              <BarStyled.UserMenuItem>{t('buttons.logout')}</BarStyled.UserMenuItem>
            </Styled.LogoutText>
          </Styled.LogoutLink>
        </BarStyled.UserMenu>
      ) : null}
    </BarStyled.UserPreviewContainer>
  );

  return (
    <ApplicationBar
      user={{
        name: person?.fullName,
        avatarUrl: person?.photoUrl,
        position: person?.department,
      }}
      userActions={[{title: t('buttons.logout'), action: onLogout}]}
      onLogoClick={handleGoHome}
      toggleNavBar={toggleNavBar}
      {...props}
    >
      <SearchEmployeeSeat checkAccessByWorkspaceStatus={checkAccessByWorkspaceStatus} />
      <Styled.TogglesContainer>
        <MyWorkspaceButton />
        {userContainer}
      </Styled.TogglesContainer>
    </ApplicationBar>
  );
};

export default ApplicationBarContainer;
