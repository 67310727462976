import React from 'react';
import * as Styled from './input.styles';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {ref?: React.Ref<HTMLInputElement>} & {
  icon?: string;
  type?: string;
  value?: string;
  inputSize?: 'small' | 'default';
  fullWidth?: boolean;
  multiline?: boolean;
};

const Input: React.ForwardRefExoticComponent<InputProps> = React.forwardRef<HTMLInputElement, InputProps>(
  ({icon, inputSize, fullWidth, ...props}, ref) => {
    const Element: typeof Styled.Element = props.multiline ? Styled.TextArea : Styled.Element;
    return (
      <Styled.Container inputSize={inputSize} fullWidth={fullWidth}>
        <Element ref={ref} {...props} icon={icon} inputSize={inputSize} autoComplete="off" />
        {icon && <Styled.InputIcon type={icon} />}
      </Styled.Container>
    );
  },
);

export default Input;
