import {styled, css} from '../theme-provider';

export const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
  z-index: 1000;
`;

export const ToolTip = styled.div<{withPointer?: boolean}>`
  margin: 3px 0;
  align-items: center;
  background: ${({theme}) => theme.palette.general.paper};
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 2px 2px ${({theme}) => theme.palette.general.lightGrey[60]};
  padding: 12px 10px;
  cursor: initial;
  position: absolute;
  color: ${({theme}) => theme.palette.general.darkGrey[80]};
  font-size: 12px;
  font-weight: 500;
  min-width: 80px;
  min-height: 30px;
  border-radius: 6px;

  &.top-left {
    left: 50%;
    bottom: 100%;
    transform: translateX(-100%);
    margin-bottom: 3px;
  }

  &.top-right {
    right: 50%;
    bottom: 100%;
    transform: translateX(100%);
    margin-bottom: 3px;
  }

  &.bottom-left {
    left: 95%;
    transform: translateX(-100%);
    ${({withPointer}) => {
      if (withPointer) {
        return css`
          &::after {
            content: ' ';
            position: absolute;
            top: -10px;
            right: 15%;
            margin-left: -5px;
            width: 20px;
            height: 20px;
            background: ${({theme}) => theme.palette.general.paper};
            transform: rotateZ(45deg);
            box-shadow: -1px -1px 1px ${({theme}) => theme.palette.general.lightGrey[60]};
          }
        `;
      }
    }}
  }

  &.bottom-right {
    right: 95%;
    top: 100%;
    transform: translateX(100%);
    ${({withPointer}) => {
      if (withPointer) {
        return css`
          &::after {
            content: ' ';
            position: absolute;
            top: -8px;
            left: 10%;
            margin-left: -5px;
            width: 16px;
            height: 16px;
            background: ${({theme}) => theme.palette.general.paper};
            transform: rotateZ(45deg);
            box-shadow: -1px -1px 1px ${({theme}) => theme.palette.general.lightGrey[60]};
          }
        `;
      }
    }}
  }

  &.bottom-center {
    right: 50%;
    transform: translateX(50%);
    ${({withPointer}) => {
      if (withPointer) {
        return css`
          &::before {
            content: '';
            position: absolute;
            left: 44%;
            top: -3%;
            width: 10px;
            height: 10px;
            background: ${({theme}) => theme.palette.general.paper};
            transform: rotateZ(45deg);
            box-shadow: -1px -1px 1px ${({theme}) => theme.palette.general.lightGrey[60]};
          }
        `;
      }
    }}

    @media(max-width: 480px) {
      right: 20%;
      width: 350px;
    }
    @media (max-width: 390px) {
      right: 25%;
      width: 340px;
    }
  }

  &.top-center {
    right: 50%;
    transform: translateX(50%);
    /* transform: translateY(-30px); */
    bottom: 105%;
    ${({withPointer}) => {
      if (withPointer) {
        return css`
          &::before {
            content: '';
            position: absolute;
            left: 48%;
            bottom: -5px;
            width: 10px;
            height: 10px;
            background: ${({theme}) => theme.palette.general.paper};
            transform: rotateZ(45deg);
            box-shadow: 1px 1px 1px ${({theme}) => theme.palette.general.lightGrey[60]};
          }
        `;
      }
    }}
  }
`;
