import React, {useEffect, useState} from 'react';
import {Ability, AbilityBuilder} from '@casl/ability';
import {Permissions, useAuth, useAuthentication, AbilityContext} from '@innowise-group/core';
import {Employee} from '../../models';
import {Loader} from '../../../ui-kit';

const ability = new Ability([]);
export const PersonContext = React.createContext<Employee & {allowed_locations: {[key: string]: number[]}}>(null);

export const AbilityProvider: React.FC = ({children}) => {
  const {authenticate} = useAuthentication();
  const {authenticated} = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const [person, setPerson] = useState<Employee & {allowed_locations: {[key: string]: number[]}}>(null);

  useEffect(() => {
    const authenticatedValue = authenticated.getValue();
    if (authenticatedValue) {
      const subscription = authenticate().subscribe({
        next(permissions) {
          ability.update(defineRules(permissions));
          setPerson({...permissions.person, allowed_locations: permissions.allowed_locations});
          setIsLoading(false);
        },
        error() {
          setIsLoading(false);
        },
      });
      return subscription.unsubscribe;
    } else {
      setIsLoading(false);
    }
  }, [authenticate, authenticated]);

  function defineRules(authentication: Permissions) {
    const {can, rules} = new AbilityBuilder(Ability);
    const ids = authentication.ids;
    authentication.permissions.forEach((permission) => {
      const [subject, action] = permission.split('_');
      let condition = undefined;

      if (ids && ids[permission]) {
        return ids[permission].forEach((id) => {
          can(action, subject, {id: String(id)});
        });
      }
      can(action, subject, condition);
    });
    return rules;
  }

  return (
    <AbilityContext.Provider value={ability}>
      <PersonContext.Provider value={person}>{isLoading ? <Loader /> : children}</PersonContext.Provider>
    </AbilityContext.Provider>
  );
};
