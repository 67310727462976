import {styled, Typography} from '@innowise-group/ui-kit';

export const RoomInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RoomTypeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RoomType = styled(Typography)<{isEditRoom: boolean}>`
  color: ${({theme, isEditRoom}) => (isEditRoom ? theme.palette.general.smart : theme.palette.general.darkGrey[60])};
  margin-right: 5px;
`;
