import React from 'react';
import {Typography} from '../typography';
import * as Styled from './chip.styles';

export interface ChipProps {
  title: string;
  clearable?: boolean;
  onDeleteClick?: (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => void;
}

const Chip: React.FC<ChipProps> = ({title, clearable, onDeleteClick}) => {
  return (
    <Styled.ChipContainer>
      <Typography>{title}</Typography>
      {clearable ? (
        <Styled.IconContainer type="u_multiply" size={13} onClick={onDeleteClick} isPointer isHover />
      ) : null}
    </Styled.ChipContainer>
  );
};

export default Chip;
