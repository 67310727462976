import {RouteProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import img from './error.svg';
import * as Styled from './error.styles';

type ErrorProps = RouteProps & {
  title?: string;
  description?: string;
};

const Error: React.FC<ErrorProps> = (props) => {
  const {t} = useTranslation();

  const {title, description} =
    props.title && props.description
      ? props
      : {title: t('notifications.titles.error'), description: t('notifications.text.unknownError')};

  return (
    <Styled.ErrorContainer>
      <Styled.Image background={img} />
      <Styled.Error type="h1">{title}</Styled.Error>
      <Styled.ErrorMessage type="h3">{description}</Styled.ErrorMessage>
    </Styled.ErrorContainer>
  );
};

export default Error;
