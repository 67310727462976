import {Controller, useFormContext} from 'react-hook-form';
import {Button, Divider, Form, withForm, WithFormProps} from '@innowise-group/ui-kit';
import {FloorsSelect, LocationsSelect, OfficesSelect} from '@shared-components';
import {useTranslation} from 'react-i18next';
import {useContext, useEffect, useRef} from 'react';
import * as Styled from './choose-office-floor-form.styles';
import {PersonContext} from '@innowise-group/core';

export enum ChooseOfficeFloorFormFields {
  Location = 'location',
  Address = 'address',
  Floor = 'floor',
}

export interface ChooseOfficeFloorFormValues {
  [ChooseOfficeFloorFormFields.Location]: string;
  [ChooseOfficeFloorFormFields.Address]: string;
  [ChooseOfficeFloorFormFields.Floor]: string;
}

interface ChooseOfficeFloorFormProps {
  submitName?: string;
  permissions?: {[key: string]: number[]};
}

const ChooseOfficeFloorForm: React.FC<WithFormProps & ChooseOfficeFloorFormProps> = ({
  onSubmit,
  onCancel,
  isWatchFieldChanged,
  setIsWatchFieldChanged,
  submitName = 'move',
  permissions = {},
}) => {
  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
    resetField,
  } = useFormContext();

  const {t} = useTranslation();
  const locationRef = useRef(null);
  const addressRef = useRef(null);
  const person = useContext(PersonContext);

  useEffect(() => {
    setIsWatchFieldChanged({
      [ChooseOfficeFloorFormFields.Address]: false,
      [ChooseOfficeFloorFormFields.Floor]: false,
    });
  }, [setIsWatchFieldChanged]);

  useEffect(() => {
    const subscription = watch((values: ChooseOfficeFloorFormValues, {type}) => {
      if (type) {
        if (locationRef.current !== values[ChooseOfficeFloorFormFields.Location]) {
          resetField(ChooseOfficeFloorFormFields.Address);
          resetField(ChooseOfficeFloorFormFields.Floor);
          locationRef.current = values[ChooseOfficeFloorFormFields.Location];
          setIsWatchFieldChanged({
            [ChooseOfficeFloorFormFields.Address]: true,
            [ChooseOfficeFloorFormFields.Floor]: true,
          });
        } else if (addressRef.current !== values[ChooseOfficeFloorFormFields.Address]) {
          resetField(ChooseOfficeFloorFormFields.Floor);
          addressRef.current = values[ChooseOfficeFloorFormFields.Address];
          setIsWatchFieldChanged({
            [ChooseOfficeFloorFormFields.Floor]: true,
          });
        } else {
          setIsWatchFieldChanged({
            [ChooseOfficeFloorFormFields.Address]: false,
            [ChooseOfficeFloorFormFields.Floor]: false,
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField, setIsWatchFieldChanged]);

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      <Form.Layout layoutType="grid">
        <Controller
          name={ChooseOfficeFloorFormFields.Location}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field label={`${t('modals.location')}`} error={errors[ChooseOfficeFloorFormFields.Location]?.message}>
              <LocationsSelect
                value={value}
                onValueChange={onChange}
                availableLocations={Object.keys(person?.allowed_locations)}
                position="fixed"
              />
            </Form.Field>
          )}
        />
        <Controller
          name={ChooseOfficeFloorFormFields.Address}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field label={`${t('modals.address')}`} error={errors[ChooseOfficeFloorFormFields.Address]?.message}>
              <OfficesSelect
                locationId={watch(ChooseOfficeFloorFormFields.Location)}
                value={value}
                disabled={!watch(ChooseOfficeFloorFormFields.Location)}
                onValueChange={onChange}
                toUseDefaultValue={false}
                isFieldChanged={isWatchFieldChanged[ChooseOfficeFloorFormFields.Address]}
                availableOffices={Object.values(person?.allowed_locations).flat()}
                position="fixed"
              />
            </Form.Field>
          )}
        />
      </Form.Layout>

      <Form.Layout layoutType="vertical">
        <Styled.FloorFieldForm>
          <Controller
            name={ChooseOfficeFloorFormFields.Floor}
            control={control}
            render={({field: {onChange, value}}) => (
              <Form.Field label={`${t('modals.floor')}`} error={errors[ChooseOfficeFloorFormFields.Floor]?.message}>
                <FloorsSelect
                  officeId={watch(ChooseOfficeFloorFormFields.Address)}
                  value={value}
                  onValueChange={onChange}
                  disabled={!watch(ChooseOfficeFloorFormFields.Address)}
                  isFieldChanged={isWatchFieldChanged[ChooseOfficeFloorFormFields.Floor]}
                />
              </Form.Field>
            )}
          />
        </Styled.FloorFieldForm>
      </Form.Layout>

      <Divider />

      <Form.Actions>
        <Button type="submit" disabled={!watch(ChooseOfficeFloorFormFields.Floor)}>
          {t(`buttons.${submitName}`)}
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
      </Form.Actions>
    </Form.Container>
  );
};

export default withForm(ChooseOfficeFloorForm);
