import React, {useState, useRef, useLayoutEffect} from 'react';
import {TooltipContainer, ToolTip} from './tooltip.styles';

export type TooltipHorizontal = 'left' | 'right' | 'center';
export type TooltipVertical = 'top' | 'bottom';

export interface TooltipProps {
  children: React.ReactChildren | React.ReactElement;
  content: React.ReactNode;
  horizontal: TooltipHorizontal;
  vertical: TooltipVertical;
  active?: boolean;
  withPointer?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  active = false,
  vertical = 'top',
  horizontal = 'right',
  withPointer = true,
}: TooltipProps) => {
  // const [active, setActive] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [verticalCheck, setVerticalCheck] = useState<string>(vertical);
  const [horizontalCheck, setHorizontalCheck] = useState<string>(horizontal);

  useLayoutEffect(() => {
    if (active) {
      const {current} = tooltipRef;
      const coords = current?.getBoundingClientRect();
      const {top, bottom, right, left} = coords || {top: 0, bottom: 0, right: 0, left: 0};

      switch (verticalCheck) {
        case 'top':
          top < 0 && setVerticalCheck('bottom');
          break;
        case 'bottom':
          bottom > window.innerHeight && setVerticalCheck('top');
          break;
      }

      switch (horizontalCheck) {
        case 'right':
          right > window.innerWidth && setHorizontalCheck('left');
          break;
        case 'left':
          left < 0 && setHorizontalCheck('right');
          break;
      }
    }
  }, [active, horizontalCheck, verticalCheck]);

  return (
    <TooltipContainer>
      {children}
      {active && (
        <ToolTip ref={tooltipRef} withPointer={withPointer} className={`${verticalCheck}-${horizontalCheck}`}>
          {content}
        </ToolTip>
      )}
    </TooltipContainer>
  );
};

export default Tooltip;
