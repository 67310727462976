import React from 'react';
import {Select, SelectProps} from '@innowise-group/ui-kit';
import {useTranslation} from 'react-i18next';

const RoomTypesSelect: React.FC<SelectProps> = ({...props}) => {
  const {t} = useTranslation();

  const items = [
    {value: 'Office room type', title: t('rooms.office')},
    {value: 'Coworking room type', title: t('rooms.coworking')},
    {value: 'Meeting room type', title: t('rooms.meeting')},
    {value: 'Kitchen', title: t('rooms.kitchen')},
    {value: 'Water closet', title: t('rooms.toilet')},
    {value: 'Hall', title: t('rooms.hall')},
    {value: 'Playroom', title: t('rooms.playroom')},
    {value: 'Other', title: t('rooms.other')},
  ];

  const transformValue = (value) => {
    return items.find((room) => room.value === value).title;
  };

  return <Select {...props} options={items} withSearch={false} valueTransformer={transformValue} />;
};

export default RoomTypesSelect;
