import {
  CONFIRMED_VISIT,
  UNCONFIRMED_VISIT,
  REMOTE_WORK_WITH_VISIT,
  REMOTE_WORK,
  ABSENT,
  NOT_DEFINED,
} from '../../components/stacked-chart/utils';

const order = {
  [CONFIRMED_VISIT]: 0,
  [UNCONFIRMED_VISIT]: 1,
  [REMOTE_WORK_WITH_VISIT]: 2,
  [REMOTE_WORK]: 3,
  [ABSENT]: 4,
  [NOT_DEFINED]: 5,
};

export const getSortedAndMappedEmployeeMarkStatusData = (data) => {
  if (data?.error) {
    return {
      list: [],
      labels: [],
      errors: data,
    };
  }
  const result: Array<[string, number[]]> = Object.entries(data).reduce((acc, [date, values]) => {
    Object.entries(values).forEach(([key, value]) => {
      const existing = acc.find((item) => item[0] === key);
      if (existing) {
        existing[1].push(value);
      } else {
        acc.push([key, [value]]);
      }
    });
    return acc;
  }, []);

  const sortedArray = [...result].sort((a, b) => order[a[0]] - order[b[0]]);

  return {
    list: sortedArray,
    labels: Object.keys(data),
    errors: null,
  };
};
