import 'react-datepicker/dist/react-datepicker.css';
import {Controller, useFormContext} from 'react-hook-form';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {Form, withForm, WithFormProps, CustomDatePicker} from '@innowise-group/ui-kit';
import {Switch} from '@shared/ui-kit/switch';
import {validateDate} from '@innowise-group/utilities';

export enum BookingWorkspaceModeFormFields {
  BookingMode = 'bookingMode',
  DateRange = 'dateRange',
}

export type DateRange = {
  startDay: string;
  endDay: string;
};

interface BookingWorkspaceModeFormValuesRaw {
  bookingMode: boolean;
  dateRange: [Date, Date];
}

export type BookingWorkspaceModeFormValues = Omit<BookingWorkspaceModeFormValuesRaw, 'dateRange'> & DateRange;

const BookingWorkspaceForm: React.FC<WithFormProps<BookingWorkspaceModeFormValues>> = ({onSubmit}) => {
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    resetField,
  } = useFormContext();

  const {t} = useTranslation();

  useEffect(() => {
    const subscription = watch((values: BookingWorkspaceModeFormValuesRaw) => {
      const {dateRange} = values;
      onSubmit({
        ...values,
        startDay: dateRange?.[0]?.toString() ?? null,
        endDay: dateRange?.[1]?.toString() ?? null,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch, onSubmit]);

  const handleClearDateFieldsForm = () => {
    resetField(BookingWorkspaceModeFormFields.DateRange);
  };

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      <Form.Layout layoutType="horizontal" noPadding style={{alignItems: 'center', marginRight: 16}}>
        <Controller
          name={BookingWorkspaceModeFormFields.BookingMode}
          control={control}
          defaultValue={false}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[BookingWorkspaceModeFormFields.BookingMode]}>
              <Switch checked={value} onChange={onChange} onClick={handleClearDateFieldsForm}>
                {t('buttons.booking')}
              </Switch>
            </Form.Field>
          )}
        />

        {watch(BookingWorkspaceModeFormFields.BookingMode) && (
          <Controller
            name={BookingWorkspaceModeFormFields.DateRange}
            control={control}
            render={({field: {onChange, value}}) => (
              <Form.Field error={errors[BookingWorkspaceModeFormFields.DateRange]}>
                <CustomDatePicker
                  selectsRange
                  selected={watch(BookingWorkspaceModeFormFields.DateRange)?.[0]}
                  startDate={watch(BookingWorkspaceModeFormFields.DateRange)?.[0]}
                  endDate={watch(BookingWorkspaceModeFormFields.DateRange)?.[1]}
                  onChange={onChange}
                  placeholderText={t('placeholders.datePicker')}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  minDate={new Date()}
                  maxDate={validateDate.maxInRange(watch(BookingWorkspaceModeFormFields.DateRange))}
                />
              </Form.Field>
            )}
          />
        )}
      </Form.Layout>
    </Form.Container>
  );
};

export default withForm(BookingWorkspaceForm);
