import {MutableRefObject, useCallback, useRef, useState} from 'react';
import {Employee, useService, Workspace} from '@innowise-group/core';
import {Elements, Node, OnLoadParams} from 'react-flow-renderer';
import {useTranslation} from 'react-i18next';
import {FloorsEventEmitterService} from '../../services/floors-event-emitter';
import {WorkspaceElementData} from '../../components/workspace-view';
import {FloorsModalsFacadeService} from '../../services/floors-modals-facade';
import {Room} from './use-floor-details.hook';
import {DateRange} from '../../components/booking-workspace-mode-form';
import {updateWorkspaceData} from '../../utilities/update-workspace-data.utility';

export interface UseFlowElementsDragResult {
  dragOverHandler: (event: React.DragEvent<HTMLDivElement>) => void;
  dragHandler: (event: React.DragEvent<HTMLDivElement>) => void;
  nodeDragStopHandler: (event: React.MouseEvent<Element, MouseEvent>, node: Node<WorkspaceElementData>) => void;
  loadHandler: (_reactFlowInstance: OnLoadParams) => void;
  reactFlowWrapper: MutableRefObject<HTMLDivElement>;
}

export const useFlowElementsDrag = (
  workspaceElements: Elements<WorkspaceElementData>,
  roomCoordinates: Room[],
  isVirtual: boolean = false,
  floorsEventEmitter: FloorsEventEmitterService,
  bookWorkspaceDateRange: {range: DateRange},
): UseFlowElementsDragResult => {
  const {t} = useTranslation();
  const floorsModalsFacade = useService(FloorsModalsFacadeService);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: Math.floor(event.clientX - reactFlowBounds.left),
        y: Math.floor(event.clientY - reactFlowBounds.top),
      });
      const workspace = workspaceElements.find(
        ({position: {x, y}, data: {width, height}}: Node<WorkspaceElementData>) => {
          const XPosition = x + width;
          const YPosition = y + height;
          return x <= position.x && position.x <= XPosition && y <= position.y && position.y <= YPosition;
        },
      );

      if (event.dataTransfer.getData('employee')) {
        const newEmployee: Employee = JSON.parse(event.dataTransfer.getData('employee'));

        if (workspace) {
          if (workspace.data?.employee?.fullName) {
            const currentName = newEmployee?.fullName;
            const previousName = workspace.data?.employee?.fullName;
            floorsModalsFacade
              .openConfirmationModal(
                t('modals.replaceEmployeeTitle'),
                t('modals.replaceEmployeeText', {previousName, currentName}),
                t('modals.replaceEmployeeConfirmationText'),
                t('buttons.cancel'),
              )
              .subscribe(() => {
                floorsEventEmitter.emit('editWorkspace', {
                  ...workspace.data,
                  employee: newEmployee,
                  status: 'Occupied',
                });
              });
          } else if (workspace?.data?.status === 'Booked') {
            floorsModalsFacade.openInfoModal(
              t('modals.hasWorkspaceBookedMessage'),
              t('modals.attention'),
              t('buttons.ok'),
            );
          } else if (isVirtual) {
            floorsEventEmitter.emit('editWorkspace', {
              ...workspace.data,
              employee: newEmployee,
              status: 'Remote',
            });
          } else if (bookWorkspaceDateRange) {
            floorsEventEmitter.emit('editWorkspace', {
              ...workspace.data,
              employee: newEmployee,
              status: 'Booked',
            });
          } else {
            floorsEventEmitter.emit('editWorkspace', {
              ...workspace.data,
              employee: newEmployee,
              status: 'Occupied',
            });
          }
        }
      } else {
        const {employee, status, comment, range, id}: Workspace = JSON.parse(event.dataTransfer.getData('workspace'));
        if (workspace && workspace.data.status === 'Free') {
          const updatedNewWorkspace = updateWorkspaceData({...workspace.data, status, employee, comment, range});
          floorsEventEmitter.emit('patch_drag_n_drop', {workspace: updatedNewWorkspace});
          floorsEventEmitter.emit('retrieve', {id: Number(id)});
          floorsEventEmitter.emit('list');
        }
      }
    },
    [
      bookWorkspaceDateRange,
      floorsEventEmitter,
      floorsModalsFacade,
      isVirtual,
      reactFlowInstance,
      reactFlowWrapper,
      t,
      workspaceElements,
    ],
  );

  const onNodeDragStop = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, node: Node<WorkspaceElementData>) => {
      const {
        position: {x, y},
        data: {width, height, id},
      } = node;

      const x1workspace = x + 25;
      const x2workspace = x + width - 25;
      const y1workspace = y;
      const y2workspace = y + height;
      const room = roomCoordinates.find(({coordinates: {x1, x2, y1, y2}}) => {
        return (
          x1 <= x1workspace &&
          x1workspace <= x2 &&
          x1 <= x2workspace &&
          x2workspace <= x2 &&
          y1 <= y1workspace &&
          y1workspace <= y2 &&
          y1 <= y2workspace &&
          y2workspace <= y2
        );
      });

      if (!room?.id) {
        return floorsModalsFacade.openInfoModal(t('modals.withinRoomMessage'), t('modals.attention'), t('buttons.ok'));
      }

      const collidedWorkspace = workspaceElements
        .filter((workspace) => workspace.data.id !== id)
        .find(
          ({position: {x, y}, data: {width, height}}: Node) =>
            x2workspace >= x + 25 && x1workspace <= x + width - 25 && y1workspace <= y + height && y2workspace >= y,
        );

      if (collidedWorkspace?.id) {
        const {number} = collidedWorkspace?.data;
        return floorsModalsFacade.openInfoModal(
          t('modals.workspacesIntersectionMessage', {number}),
          t('modals.attention'),
          t('buttons.ok'),
        );
      }

      const updatedWorkspace = updateWorkspaceData({
        ...node.data,
        coordinates: [[x + 50, y + 25]],
        room: {id: room.id, number: room.number, room_type: room.roomType},
      });
      floorsEventEmitter.emit('patch', {workspace: updatedWorkspace});
    },
    [workspaceElements, roomCoordinates, floorsEventEmitter, floorsModalsFacade, t],
  );

  const onLoad = useCallback((_reactFlowInstance: OnLoadParams) => {
    setReactFlowInstance(_reactFlowInstance);
    _reactFlowInstance.fitView();
  }, []);

  return {
    nodeDragStopHandler: onNodeDragStop,
    dragOverHandler: onDragOver,
    dragHandler: onDrop,
    loadHandler: onLoad,
    reactFlowWrapper,
  };
};
