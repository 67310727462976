import {FC, useMemo, useState} from 'react';
import {useTheme} from '@innowise-group/ui-kit';
import {format} from 'date-fns';
import {RequestOnPlan, Workspace} from '@innowise-group/core';
import {SeatRequestProps} from './seat-request.component';
import {PortalPlan} from '../portal-plan';
import {FloorDetails} from '../../../floors/pages/floor-details';
import {getColorByRequestType} from '../../utilities/get-color-by-request-type.utility';

interface WithShowOnPlanProps extends SeatRequestProps {}

function withShowOnPlan(WrappedComponent: FC<SeatRequestProps>) {
  return function ({data, ...props}: WithShowOnPlanProps) {
    const {seat, range, floor, user, status} = data;
    const [showOnPlan, setShowOnPlan] = useState(false);
    const theme = useTheme();

    const [workspace, setWorkspace] = useState<Workspace & {floorId: string}>();

    const showOnPlanHandler = (workspace?: Workspace & {floorId: string}) => {
      setWorkspace(workspace);
      setShowOnPlan((value) => !value);
    };

    const planStyles = {
      'background-color': theme.palette.general.paper,
      'z-index': 10000,
      position: 'absolute',
      height: '85vh',
      width: '90vw',
      top: '8%',
      left: '4%',
      'border-radius': '10px',
      border: `1px solid ${theme.palette.general.darkGrey[60]}`,
      padding: '6px',
    };

    const requestData: RequestOnPlan = useMemo(
      () => ({
        idFloor: floor?.id,
        idWorkspace: seat?.id,
        user,
        showOnPlan: showOnPlan,
        bookingRange: {
          startDay: range?.start && format(new Date(range.start), 'dd.MM.yyyy'),
          endDay: range?.end && format(new Date(range.end), 'dd.MM.yyyy'),
        },
        requestStatus: status,
        colorStatus: getColorByRequestType(status, theme),
      }),
      [floor, user, showOnPlan, range, theme, seat, status],
    );

    return (
      <>
        <WrappedComponent showOnPlanHandler={showOnPlanHandler} data={data} {...props} />
        {showOnPlan && (
          <PortalPlan onClose={showOnPlanHandler} styles={planStyles}>
            {workspace ? <FloorDetails focusWorkspace={workspace} /> : <FloorDetails requestData={requestData} />}
          </PortalPlan>
        )}
      </>
    );
  };
}

export default withShowOnPlan;
