import {useLocation} from 'react-router';
import {useNavigate} from 'react-router-dom';

import {useTheme, useResizeObserver, RESIZE_OBSERVER_DESKTOP_SCREEN_SIZE} from '@innowise-group/ui-kit';

import * as Styled from './navigation-bar.styles';
import {Icon} from '../icon';

export interface NavigationBarProps {
  routes: Array<Route>;
  isCollapsed: boolean;
  toggleCollapsed: () => void;
}

interface Route {
  name: string;
  path: string;
  icon: string;
  activeWhen?: (route: string) => boolean;
  allowed: boolean;
  count?: number;
}

const NavigationBar: React.FC<NavigationBarProps> = ({routes, children, isCollapsed, toggleCollapsed}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const isDesktopView = useResizeObserver('application-container', RESIZE_OBSERVER_DESKTOP_SCREEN_SIZE);

  const toggleNavBar = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    toggleCollapsed();
  };

  return (
    <Styled.NavigationBarContainer collapsed={+isCollapsed}>
      <Styled.NavigationBarContentContainer>
        <Styled.CollapseButton onClick={toggleNavBar} collapsed={+isCollapsed}>
          <Icon
            type={isCollapsed ? 'u_angle-right-b' : 'u_angle-left-b'}
            fill={theme.palette.general.darkGrey[80]}
            size={18}
          />
        </Styled.CollapseButton>
        {routes.map(({name, path, icon, activeWhen, allowed, count}, index) => {
          const isSelected = activeWhen ? activeWhen(location.pathname) : location.pathname.includes(path);
          const handleNavigationItemClick = () => {
            navigate(path);
            if (!isDesktopView) {
              toggleCollapsed();
            }
          };
          return (
            allowed && (
              <Styled.NavigationItem key={index} onClick={handleNavigationItemClick} selected={isSelected ? 1 : 0}>
                {isSelected && <Styled.NavigationItemSelectedIndicator />}
                <Styled.NavigationLink to={path}>
                  <Styled.IconItem
                    type={icon}
                    fill={isSelected ? theme.palette.generalAlt.font.highlight : theme.palette.generalAlt.font.tertiary}
                  />
                  {!isCollapsed && (
                    <>
                      <Styled.NavigationLinkText>{name}</Styled.NavigationLinkText>
                      <Styled.Count>{count}</Styled.Count>
                    </>
                  )}
                </Styled.NavigationLink>
              </Styled.NavigationItem>
            )
          );
        })}
        {children}
      </Styled.NavigationBarContentContainer>
    </Styled.NavigationBarContainer>
  );
};

export default NavigationBar;
