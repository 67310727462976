import {styled, Typography} from '@innowise-group/ui-kit';

export const FloorPlanContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
`;

export const ReactFlowWrapper = styled.div<{selectable: boolean; focus: boolean}>`
  width: 100%;
  height: 100%;
  border: 2px solid ${({theme, focus}) => (focus ? theme.palette.general.smart : theme.palette.general.lightGrey[60])};
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
  cursor: ${({selectable}) => (!selectable ? 'grab' : 'default')};
  padding: 3px;

  & .react-flow__controls {
    width: 32px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    left: 24px;
    bottom: 24px;

    & .react-flow__controls-button {
      width: 100%;
      height: 32px;
      margin: 0;
      padding: 0;
      box-sizing: border-box;

      & svg {
        width: 16px;
        height: 16px;
        max-width: 16px;
        max-height: 16px;
      }
    }
  }
`;

export const Address = styled(Typography)`
  font-size: 18px;
  position: absolute;
`;
