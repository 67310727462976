import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Form, withForm, Button, WithFormProps, Divider} from '@innowise-group/ui-kit';
import {SelectLocationForm, SelectLocationFormValues} from '../select-location-form';
import {OfficeInformationForm, OfficeInformationFormValues} from '../office-information-form';
import {FloorsForm, FloorsFormValues} from '../floors-form';
import {ManagerTypes} from '@innowise-group/core';

export type AddOfficeFormSection = 'location' | 'floor';

export type AddOfficeFormValues = SelectLocationFormValues &
  OfficeInformationFormValues &
  FloorsFormValues & {managers: {office_manager: string; manager_type: ManagerTypes}[]};

const AddOfficeForm: React.FC<
  WithFormProps<AddOfficeFormValues> & {
    sectionsToShow?: AddOfficeFormSection[];
  }
> = ({onSubmit, onCancel, initialValues = {}, sectionsToShow = ['location', 'floor'], isSubmit}) => {
  const {handleSubmit} = useFormContext();

  const {t} = useTranslation();
  const {location, address, address_eng, index, floors, jira_office_id} = initialValues;

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      {sectionsToShow.length ? (
        sectionsToShow.includes('location') ? (
          <>
            <Form.Layout layoutType="vertical">
              <SelectLocationForm defaultValue={{location, index}} />
            </Form.Layout>

            <Divider />

            <Form.Layout layoutType="vertical">
              <OfficeInformationForm
                initialValues={{address, address_eng, jira_office_id}}
                floors={floors}
                showFloorsForm={sectionsToShow.includes('floor')}
              />
            </Form.Layout>

            <Divider />
          </>
        ) : (
          <>
            <Form.Layout layoutType="vertical" inset="bottom">
              <FloorsForm initialValues={{floors}} />
            </Form.Layout>

            <Divider />
          </>
        )
      ) : null}

      <Form.Actions>
        <Button type="submit" disabled={isSubmit} withLoader={isSubmit}>
          {t('buttons.save')}
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
      </Form.Actions>
    </Form.Container>
  );
};

export default withForm(AddOfficeForm);
