import React, {useContext, useMemo} from 'react';

import {GlobalThemeContext, Icon, useTheme} from '@innowise-group/ui-kit';

import * as Styled from './button-helper.styles';

interface ButtonHelperProps {
  onClick: () => void;
  text: string;
  icon: string;
  desktopOnly?: boolean;
  color?: string;
  noExtraMargin?: boolean;
}

const ButtonHelper: React.FC<ButtonHelperProps> = ({
  text,
  icon,
  onClick,
  desktopOnly,
  color: externalColor,
  noExtraMargin,
}) => {
  const theme = useTheme();
  const {theme: themeContext} = useContext(GlobalThemeContext);

  const color = useMemo(() => {
    if (externalColor) return externalColor;
    return themeContext === 'dark' ? theme.palette.general.darkGrey[100] : theme.palette.general.smart;
  }, [themeContext, theme, externalColor]);

  return (
    <Styled.Container onClick={onClick} desktopOnly={desktopOnly} noExtraMargin={noExtraMargin}>
      <Icon type={icon} fill={color} />
      <Styled.Text color={color}>{text}</Styled.Text>
    </Styled.Container>
  );
};

export default React.memo(ButtonHelper);
