import * as Styled from './loader.styles';

export interface LoaderProps {
  size?: number;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({size = 40, className}) => {
  return <Styled.Loader size={size} className={className} />;
};

export default Loader;
