import {RequestStatus, WorkspaceBookType} from '@innowise-group/core';

const getIconByRequestType = (status: WorkspaceBookType | RequestStatus) => {
  switch (status) {
    case 'Reserved':
      return 'u_check-circle';
    case 'Booked':
      return 'u_clock-five';
    case 'Occupied':
      return 'u_exclamation-circle';
    case 'Remote':
      return 'u_home';
    case 'approved':
      return 'u_check-circle';
    case 'in-clarification':
    case 'conflict':
      return 'u_clock-seven';
    case 'delayed':
      return 'u_multiply';
    case 'canceled':
      return 'u_multiply';
    case 'rejected':
      return 'u_exclamation-triangle';
    default:
      return 'u_check';
  }
};

export default getIconByRequestType;
