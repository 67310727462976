import {Fragment, useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {Employee, EmployeesApiService, useDebounce, useService, WorkspaceBookType} from '@innowise-group/core';

import {
  FadeInOutView,
  Loader,
  RESIZE_OBSERVER_TABLET_SCREEN_SIZE,
  Tooltip,
  Typography,
  useClickOutside,
  useResizeObserver,
} from '@innowise-group/ui-kit';

import * as Styled from './search-employee-seat.styles';
import SearchEmployeeSeatInfo from './search-employee-seat-info.component';
import {useNavigate} from 'react-router-dom';

interface SearchEmployeeSeatProps {
  checkAccessByWorkspaceStatus?: (workspaceStatus: WorkspaceBookType) => boolean;
}

const SearchEmployeeSeat: React.FC<SearchEmployeeSeatProps> = ({checkAccessByWorkspaceStatus}) => {
  const [employeeName, setEmployeeName] = useState('');
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const name = useDebounce(employeeName, 500);
  const employeesApi = useService(EmployeesApiService);
  const employeeSeatsRef = useRef<HTMLDivElement>();
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

  const navigate = useNavigate();
  const {t} = useTranslation();

  const changeEmployeeStates = (value: string = '') => {
    setEmployees([]);
    setEmployeeName(value);
  };

  const closeTooltip = () => {
    setIsShowTooltip(false);
    changeEmployeeStates();
  };

  useClickOutside(employeeSeatsRef, closeTooltip);

  const isDesktopView = useResizeObserver('application-container', RESIZE_OBSERVER_TABLET_SCREEN_SIZE);

  useEffect(() => {
    if (name) {
      const subscriber = employeesApi.getEmployees(name).subscribe(({employees}) => {
        setEmployees(employees);
        setIsLoading(false);
      });
      return () => subscriber.unsubscribe();
    }
  }, [employeesApi, name]);

  const handleSearchName = (event: React.FormEvent<HTMLOrSVGElement & HTMLInputElement>) => {
    setIsShowTooltip(true);
    changeEmployeeStates(event.currentTarget.value);
    setIsLoading(true);
  };

  return (
    <Tooltip
      vertical="bottom"
      horizontal="center"
      active={Boolean(employeeName) && isShowTooltip}
      withPointer={false}
      content={
        <FadeInOutView>
          {isLoading ? (
            <Styled.LoaderContainer>
              <Loader />
            </Styled.LoaderContainer>
          ) : (
            <Styled.EmployeeSeatsContainer ref={employeeSeatsRef}>
              {employees.length > 0 ? (
                employees.map(({photoUrl, department, fullName, workspaces, id}) =>
                  workspaces.length > 0 ? (
                    workspaces.map(({id, city, floor: floorNumber, floorId, room, number, address, status}) => {
                      const handleEmployeeSeat = () => {
                        changeEmployeeStates();
                        navigate(`/floors/${floorId}`, {state: {workspaceId: id}});
                      };
                      const floor = status === 'Remote' ? t(`pages.floors.virtualFloor`) : floorNumber;
                      return (
                        <SearchEmployeeSeatInfo
                          key={id}
                          handleEmployeeSeat={handleEmployeeSeat}
                          name={fullName}
                          department={department}
                          photo={photoUrl}
                          searchName={employeeName}
                          workspaceStatus={status}
                          allowedToInteract={checkAccessByWorkspaceStatus(status)}
                        >
                          <Styled.SeatInfoBlock>
                            <Styled.LocationIcon type="u_location-point" size={14} />
                            <Styled.SeatInfo>
                              {city}, {address}
                            </Styled.SeatInfo>
                          </Styled.SeatInfoBlock>
                          <Styled.SeatInfoBlock>
                            {Object.entries({floor, room, seat: number}).map(([key, value]) => (
                              <Fragment key={key}>
                                <Typography>{t(`employeeSeat.${key}`)}:</Typography>
                                <Styled.SeatInfo margin>{value}</Styled.SeatInfo>
                              </Fragment>
                            ))}
                          </Styled.SeatInfoBlock>
                        </SearchEmployeeSeatInfo>
                      );
                    })
                  ) : (
                    <SearchEmployeeSeatInfo
                      key={id}
                      photo={photoUrl}
                      department={department}
                      name={fullName}
                      searchName={employeeName}
                      handleEmployeeSeat={() => {
                        changeEmployeeStates();
                        navigate('/employees', {state: {employeeFullName: fullName}});
                        // navigate('/employees');
                      }}
                      allowedToInteract={checkAccessByWorkspaceStatus(undefined)}
                    >
                      <Typography>{t('employeeSeat.noSeat')}</Typography>
                    </SearchEmployeeSeatInfo>
                  ),
                )
              ) : (
                <Typography>{t('employeeSeat.noMatches')}</Typography>
              )}
            </Styled.EmployeeSeatsContainer>
          )}
        </FadeInOutView>
      }
    >
      <Styled.InputContainer>
        <Styled.SearchInput
          value={employeeName}
          placeholder={t('placeholders.search')}
          onChange={handleSearchName}
          icon={isDesktopView ? 'u_search' : undefined}
        />
      </Styled.InputContainer>
    </Tooltip>
  );
};

export default SearchEmployeeSeat;
