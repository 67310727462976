import {RouteProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Error} from '@shared-components';

const NotFound: React.FC<RouteProps> = () => {
  const {t} = useTranslation();
  return <Error title={t('pages.notFound.error')} description={t('pages.notFound.errorMessage')} />;
};

export default NotFound;
