import {InformationTile, styled, Avatar, Typography, Form} from '@innowise-group/ui-kit';
import {WorkspaceStatusSelect} from '../../../floors/components/workspace-status-select';
import {Value, Title, IconContainer} from '../../../../shared/ui-kit/information-tile/information-tile.styles';
import {Element} from '../../../../shared/ui-kit/input/input.styles';
import {DropdownContainer} from '../../../../shared/ui-kit/select/select.styles';
import {DatePickerContainer} from '../../../../shared/ui-kit/custom-date-picker/custom-date-picker.styles';
import {FormFieldLabel} from '../../../../shared/ui-kit/form/form.styles';

export const SeatRequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 510px;
  margin-top: -10px;
`;

export const PreviewContainer = styled.div`
  background-color: ${({theme}) => theme.palette.general.lightGrey[20]};
  border-radius: 6px;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[60]};
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const InformationBlock = styled.div`
  position: relative;
  padding: 0 30px;
  box-sizing: border-box;
`;

export const InformationRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media (max-width: 481px) {
    &:nth-child(n + 3) {
      margin-top: 10px;
    }
    &:last-child {
      margin-top: 8px;
    }
  }
`;

export const Tile = styled(InformationTile)<{color?: string}>`
  min-width: 120px;
  ${Value} {
    color: ${({color}) => color};
  }
  &:only-child {
    margin: 0 auto;
  }

  @media (max-width: 480px) {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${IconContainer} {
      margin-right: 2px;
      margin-left: 13px;
    }
  }
`;

export const StatusTile = styled(Tile)`
  min-width: 135px;
`;

export const LeftBlock = styled.div`
  flex: 1;
  margin-right: 100px;
`;

export const UserPhoto = styled(Avatar)`
  margin-right: 20px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 10px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 20px 10px 0 0;
  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const MessageBlock = styled(InformationRow)`
  margin-top: 15px;
`;

export const Message = styled.span`
  display: block;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
  color: ${({theme}) => theme.palette.state.error.hover};
`;

export const ShowButton = styled(Typography)`
  position: absolute;
  top: 9px;
  cursor: pointer;
  color: ${({theme}) => theme.palette.state.error.hover};
  text-decoration: underline;
`;

export const CommentContainer = styled(PreviewContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0;
`;

export const CommentBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
`;
export const Comment = styled(InformationTile)`
  width: 90%;
  ${Title} {
    font-size: 12px;
    color: ${({theme}) => theme.palette.general.darkGrey[60]};
  }

  ${Value} {
    width: 100%;
    word-break: break-word;
    color: ${({theme}) => theme.palette.general.darkGrey[80]};
  }
`;

export const CommentInput = styled(Element)`
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
`;

export const Error = styled(Typography)`
  position: absolute;
  bottom: 5px;
  left: 50px;
  font-size: 12px;
  color: ${({theme}) => theme.palette.general.smart};
`;

export const ConflictWarningContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
  margin: 5px 0 5px 0;
`;

export const ConflictWarningText = styled(Typography)`
  color: ${({theme}) => theme.palette.state.error.default};
`;

export const StatusSelect = styled(WorkspaceStatusSelect)`
  ${DropdownContainer} {
    width: 205px;
    transition: 0.3s;

    @media (max-width: 481px) {
      width: 230px;
    }
  }
`;

export const DatePicker = styled(DatePickerContainer)`
  width: 205px;

  @media (max-width: 481px) {
    width: 230px;
  }

  & input {
    @media (max-width: 481px) {
      font-size: 10px;
    }
  }
`;

export const FormLayout = styled(Form.Layout)`
  padding: 0 20px 0 30px;
  margin-top: 30px;

  @media (max-width: 481px) {
    padding: 0 40px;
    margin: 10px 0 20px 0;
  }
`;

export const FormFieldContainer = styled.div`
  & ${FormFieldLabel} {
    font-size: 14px;

    @media (max-width: 550px) {
      font-size: 12px;
    }

    @media (max-width: 481px) {
      font-size: 10px;
    }
  }
`;
