import {Typography} from '@innowise-group/ui-kit';
import {Modal} from '../..';
import {ModalProps} from '../../manager/modal-manager.service';

export interface ConfirmationModalProps extends ModalProps {
  text?: string;
  title?: string;
  confirmationText?: string;
  cancelText?: string;
  onApprove?: () => void;
  onCancel?: () => void;
  noVerticalPadding?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  close,
  title,
  onApprove,
  text,
  confirmationText,
  cancelText,
  noVerticalPadding,
  children,
}) => (
  <Modal.Container>
    <Modal.Header title={title || 'Modal window'} onClose={close} />
    <Modal.Body>{text ? <Typography>{text}</Typography> : children}</Modal.Body>
    <Modal.Actions
      noVerticalPadding={noVerticalPadding}
      actions={[
        {
          title: confirmationText || 'Approve',
          onClick: () => {
            if (onApprove) {
              onApprove();
            }
            close();
          },
          highlighted: true,
        },
        {
          title: cancelText || 'Cancel',
          onClick: () => {
            close();
          },
        },
      ]}
    />
  </Modal.Container>
);

export default ConfirmationModal;
