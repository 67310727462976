import {styled} from '@innowise-group/ui-kit';

export const PortalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10000;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10000;
`;
