import * as Styled from './checkbox.styles';

type CheckboxProps = React.HTMLAttributes<HTMLInputElement> & {
  checked: boolean;
  disabled?: boolean;
  position?: 'left' | 'right';
};

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  checked,
  position = 'left',
  children,
  disabled = false,
  ...props
}) => (
  <Styled.CheckboxContainer className={className}>
    <Styled.Label position={position} disabled={disabled}>
      <Styled.HiddenCheckbox checked={checked} disabled={disabled} {...props} />
      <Styled.StyledCheckbox checked={checked} position={position}>
        <Styled.Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Styled.Icon>
      </Styled.StyledCheckbox>
      {children}
    </Styled.Label>
  </Styled.CheckboxContainer>
);

export default Checkbox;
