import {styled} from '../theme-provider';
import {FadeInOutView} from '../fade-in-out-view';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  /* overflow-y: scroll; */

  box-sizing: border-box;
  padding: 20px;
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const Modal = styled.div`
  background-color: ${({theme}) => theme.palette.general.paper};
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 640px;
  min-width: 600px;
  padding: 0 20px;

  @media (max-width: 481px) {
    min-width: 350px;
    max-width: 350px;
    max-height: 90%;
    padding: 0 5px;
  }
`;

const ModalSection = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 100%;

  @media (max-width: 481px) {
    padding: 10px 15px;
  }
`;

export const ModalHeader = styled(ModalSection)`
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.palette.general.lightGrey[60]};

  @media (max-width: 481px) {
    padding: 10px 15px;
  }
`;

export const ModalBody = styled(ModalSection)`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  min-height: 50px;

  @media (max-width: 481px) {
    min-height: 30px;
    padding: 10px 15px 5px 10px;
  }
`;

export const ModalActionsContainer = styled(ModalSection)<{noVerticalPadding?: boolean}>`
  display: flex;
  padding-left: 0;
  margin-top: ${({noVerticalPadding}) => (noVerticalPadding ? '0' : '20px')};
  & > * {
    min-width: 200px;
    margin-right: 10px;
  }

  @media (max-width: 481px) {
    margin-top: ${({noVerticalPadding}) => (noVerticalPadding ? '0' : '10px')};
    padding: ${({noVerticalPadding}) => (noVerticalPadding ? '0 15px' : '10px 15px')};
    & > * {
      min-width: 100px;
    }
  }
`;

export const SingleModalContainer = styled(FadeInOutView)`
  height: 100%;
`;

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow-y: scroll; */
  height: 100%;
`;
