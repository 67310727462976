import {ContainerModule, interfaces} from '@innowise-group/core';
import {FloorsEventEmitterService, FloorsEventEmitter} from './floors-event-emitter';
import {FloorsModalsFacadeService, FloorsModalsFacade} from './floors-modals-facade';
import {FloorsContainerTypes} from './floors-ioc.types';
import {FloorsDataManagerService, FloorsDataManager} from './floors-data-manager';

export const floorsContainer = new ContainerModule(
  (
    bind: interfaces.Bind,
    unbind: interfaces.Unbind,
    isBound: interfaces.IsBound,
    rebind: interfaces.Rebind,
    unbindAsync: interfaces.UnbindAsync,
    onActivation: interfaces.Container['onActivation'],
    onDeactivation: interfaces.Container['onDeactivation'],
  ) => {
    bind<FloorsEventEmitter>(FloorsContainerTypes.FloorsEventEmitter).to(FloorsEventEmitterService).inTransientScope();
    bind<FloorsModalsFacade>(FloorsContainerTypes.FloorsModalsFacade).to(FloorsModalsFacadeService);
    bind<FloorsDataManager>(FloorsContainerTypes.FloorsDataManager).to(FloorsDataManagerService).inTransientScope();
  },
);
