import {Select, SelectProps} from '@innowise-group/ui-kit';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AvailableRoomsAndWorkspaces} from '@innowise-group/core';

interface WorkspacesSelectProps extends SelectProps {
  roomId?: string;
  startDay?: string;
  endDay?: string;
  idEdit?: boolean;
  editRequestId?: string;
  roomsList?: AvailableRoomsAndWorkspaces[];
}

const WorkspacesSelect: React.FC<WorkspacesSelectProps> = ({
  roomId,
  roomsList,
  defaultValue,
  isFieldChanged,
  startDay,
  endDay,
  value,
  onValueChange,
  editRequestId,
  ...props
}) => {
  const [workspaces, setWorkspaces] = useState<{value: string; title: string}[]>([]);

  const {t} = useTranslation();

  useEffect(() => {
    if (roomsList.length && roomId) {
      setWorkspaces(
        roomsList
          .find((room) => roomId === room.id)
          .workspaces.map((room) => ({value: room.id, title: room.number}))
          .sort((a, b) => Number(a.title) - Number(b.title)),
      );
    } else {
      setWorkspaces([]);
    }
  }, [roomId, startDay, endDay, roomsList, editRequestId, value]);

  useEffect(() => {
    const isValueExistInAvailable = workspaces.find((workspace) => workspace.value === value);
    if (!isValueExistInAvailable && workspaces.length > 0) {
      onValueChange(null);
    }
  }, [workspaces, value, onValueChange]);

  const transformValue = (id: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!id && !defaultValue && !isFieldChanged) {
        reject('No ID provided for valueTransform function');
      } else if (isFieldChanged) {
        resolve('');
      } else {
        if (id) {
          const workspaceById = workspaces.find((workspace) => workspace.value === id);
          if (workspaceById) {
            resolve(workspaceById.title);
          }
        } else {
          resolve('');
        }
      }
    });
  };

  return (
    <Select
      options={workspaces}
      asyncValueTransformer={transformValue}
      placeholder={t('placeholders.choseSeat')}
      onValueChange={onValueChange}
      defaultValue={defaultValue}
      value={value}
      {...props}
    />
  );
};

export default WorkspacesSelect;
