import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ModalManager} from './manager';
import {ModalInstance} from './manager/modal-manager.service';
import * as Styled from './modal.styles';

export const modalManager = new ModalManager();

interface ModalContainerProps {
  notifyWhenReady?: () => void;
}

const ModalContainer: React.FC<ModalContainerProps> = ({notifyWhenReady}) => {
  const [collection, setCollection] = useState<ModalInstance[]>([]);
  const location = useLocation();

  useEffect(() => {
    modalManager.setDispatch(setCollection);
    notifyWhenReady?.();
  }, [setCollection, notifyWhenReady]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && collection.length > 0) {
        modalManager.close(collection.slice().pop().id);
      }
    };

    window.addEventListener('keyup', listener);

    return () => {
      window.removeEventListener('keyup', listener);
    };
  }, [collection]);

  useEffect(() => {
    modalManager.close();
  }, [location]);

  return (
    <>
      {collection.map(({id, component: Component, onBackdropClick}) => {
        const close = () => {
          modalManager.close(id);
        };

        return (
          <Styled.ModalBackdrop key={id}>
            <Styled.SingleModalContainer>
              <Styled.Overlay onClick={onBackdropClick || close} />
              <Styled.ComponentWrapper>
                <Component close={close} />
              </Styled.ComponentWrapper>
            </Styled.SingleModalContainer>
          </Styled.ModalBackdrop>
        );
      })}
    </>
  );
};

export default ModalContainer;
