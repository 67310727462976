import styled, {css} from 'styled-components';
import {Avatar as UserAvatar, Button, InformationTile, StateColor, Typography} from '@innowise-group/ui-kit';
import {Title as TileTitle, Value} from '../../../../shared/ui-kit/information-tile/information-tile.styles';
import {Avatar} from '../../../../shared/ui-kit/avatar/avatar.styles';

interface WorkspaceViewContainerProps {
  selected: boolean;
  themeColor: StateColor;
  rotateDegree: number;
  isViewMode: boolean;
}
export const WorkspaceViewContainer = styled.div<WorkspaceViewContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  transform: ${({rotateDegree}) => `rotate(${rotateDegree}deg)`};
  border-radius: 6px;
  transition: 0.2s;
  cursor: ${({isViewMode}) => (isViewMode ? 'pointer' : 'grab')};

  ${({selected, themeColor}) => {
    return css`
      background-color: ${selected ? themeColor.default : themeColor.backgroundSecondary};
      border: ${selected ? `1px solid ${themeColor.pressed}` : 'none'};
      box-sizing: border-box;      
      &:hover {
        background-color: ${themeColor.hover};
      },`;
  }}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  height: 50px;
`;

export const Title = styled(Typography)`
  font-size: 10px;
`;

export const Subtitle = styled(Typography)`
  width: inherit;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const WorkspaceActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WorkspaceActionsBlock = styled.div`
  display: flex;

  & > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const WorkspaceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  box-sizing: border-box;
`;

export const WorkspaceStatusBlock = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
`;

export const WorkspaceStatusText = styled(Typography)<{textColor: string}>`
  margin-left: 5px;
  color: ${({textColor}) => textColor};
`;

export const BookingInfoContainer = styled.div`
  border-top: 1px solid ${({theme}) => theme.palette.general.lightGrey[60]};
  padding-top: 5px;
`;

export const WorkspaceEmployeeBlock = styled(WorkspaceStatusBlock)``;

export const BookingPeriodBlock = styled(WorkspaceEmployeeBlock)``;

export const BookingPeriodText = styled(Typography)`
  margin-left: 5px;
`;

export const UserPhoto = styled(UserAvatar)<{margin?: number; draggable?: boolean; isPointer?: boolean}>`
  ${Avatar} {
    pointer-events: ${({draggable = false}) => (draggable ? 'all' : 'none')};
  }
  margin-right: ${({margin = 10}) => `${margin}px`};
  cursor: ${({draggable = false, isPointer = false}) => (draggable ? 'grab' : isPointer ? 'pointer' : 'default')};
`;

export const BookButton = styled(Button)`
  margin-top: 10px;
`;

export const Tile = styled(InformationTile)`
  padding-top: 4px;
  ${TileTitle} {
    font-size: 12px;
  }

  ${Value} {
    font-size: 12px;
  }
`;
