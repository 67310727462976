import {styled} from '../theme-provider';
import {Typography} from '../typography';
import {Select} from '../select';
import {DropdownHeader, DropdownContainer, ListItem, ExpandIcon} from '../select/select.styles';
import {Input} from '../input';

interface PaginationContainerProps {
  isHidden?: boolean;
}

export const PaginationContainer = styled.div<PaginationContainerProps>`
  display: ${({isHidden}): string => (isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  padding: 10px 0;

  @media (max-width: 481px) {
    font-size: 10px;
  }
`;

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  margin: 0 6px;
  border-radius: 4px;
  border: none;
  width: 22px;
  height: 22px;
  cursor: pointer;
  &.dots {
    cursor: default;
    background: transparent;
  }
  & > * {
    transition: 0.2s;
    opacity: 1;
  }
  &:hover > * {
    opacity: 0.8;
  }
  &:disabled > * {
    opacity: 0.2;
    cursor: default;
  }

  @media (max-width: 481px) {
    width: 20px;
    height: 20px;
  }
`;

export const PaginationItem = styled.div<{panelActive?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  border-radius: 4px;
  border: none;
  min-width: 27px;
  height: 27px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: ${({panelActive, theme}) =>
    panelActive ? theme.palette.generalAlt.background.highlightAlt : 'none'};
  color: ${({panelActive, theme}) =>
    panelActive ? theme.palette.generalAlt.font.highlightAlt : theme.palette.generalAlt.font.tertiary};
  &.dots {
    pointer-events: none;
  }
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 481px) {
    min-width: 18px;
    height: 16px;
  }
`;

export const HelperText = styled(Typography)`
  margin-right: 10px;
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
  font-size: 16px;
`;

export const CountSelect = styled(Select)`
  ${DropdownContainer} {
    padding: 0 10px;

    @media (max-width: 481px) {
      width: auto;
      padding: 0 5px;
    }
  }
  ${DropdownHeader} {
    height: 27px;
    width: 60px;
    background-color: ${({theme}) => theme.palette.generalAlt.background.highlightAlt};
    border: none;

    @media (max-width: 481px) {
      height: 22px;
      width: 48px;
    }
  }
  ${ListItem} {
    font-size: 16px;
    color: ${({theme}) => theme.palette.generalAlt.font.highlightAlt};

    @media (max-width: 481px) {
      font-size: 12px;
    }
  }
  ${ExpandIcon} {
    svg {
      fill: ${({theme}) => theme.palette.generalAlt.font.highlightAlt};
    }
  }
`;

export const PageInput = styled(Input)`
  height: 27px;
  min-width: 60px;
  border: 2px solid #ebebeb;
  background: inherit;
  font-weight: 600;
  font-size: 16px;
  font-family: Manrope;
  color: ${({theme}) => theme.palette.generalAlt.font.tertiary};
  text-align: center;

  @media (max-width: 481px) {
    height: 28px;
    min-width: 20px;
  }
`;
