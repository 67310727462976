import {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, matchRoutes} from 'react-router-dom';

import {useResizeObserver, RESIZE_OBSERVER_DESKTOP_SCREEN_SIZE} from '@innowise-group/ui-kit';

import {FORCE_COLLAPSED_PAGE_PATHS} from './settings';

interface NavBarCollapseContextProps {
  isNavBarCollapsed: boolean;
  collapseNavBar: () => void;
  expandNavBar: () => void;
  toggleNavBar: () => void;
}

export const NavBarCollapseContext = createContext<NavBarCollapseContextProps>(null);

export const NavBarCollapseContextProvider = ({children}) => {
  const [isNavBarCollapsed, setIsNavBarCollapsed] = useState(false);
  const [forceCollapsedPageKeys, setForceCollapsedPageKeys] = useState<Array<string>>(
    Object.keys(FORCE_COLLAPSED_PAGE_PATHS),
  );

  const isDesktopView = useResizeObserver('root', RESIZE_OBSERVER_DESKTOP_SCREEN_SIZE);
  const {pathname} = useLocation();

  const currentRouteKey = useMemo(() => {
    return Object.keys(FORCE_COLLAPSED_PAGE_PATHS).find((routeKey) => {
      return matchRoutes(
        FORCE_COLLAPSED_PAGE_PATHS[routeKey].map((path) => ({path: `${routeKey}${path}`})),
        pathname,
      );
    });
  }, [pathname]);

  const isNavBarFinallyCollapsed = useMemo(() => {
    if (currentRouteKey && forceCollapsedPageKeys.includes(currentRouteKey)) {
      return true;
    }

    return isNavBarCollapsed;
  }, [currentRouteKey, forceCollapsedPageKeys, isNavBarCollapsed]);

  useEffect(() => {
    if (isDesktopView) {
      setIsNavBarCollapsed(false);
    } else {
      setIsNavBarCollapsed(true);
    }
  }, [isDesktopView]);

  const collapseNavBar = useCallback(() => {
    if (currentRouteKey) {
      if (!forceCollapsedPageKeys.includes(currentRouteKey)) {
        setForceCollapsedPageKeys((prevCollapsedKeys) => {
          return prevCollapsedKeys.concat(currentRouteKey);
        });
      }
    } else {
      return setIsNavBarCollapsed(true);
    }
  }, [currentRouteKey, forceCollapsedPageKeys]);

  const expandNavBar = useCallback(() => {
    if (currentRouteKey) {
      if (forceCollapsedPageKeys.includes(currentRouteKey)) {
        setForceCollapsedPageKeys((prevCollapsedKeys) => {
          return prevCollapsedKeys.filter((prevCollapsedKey) => prevCollapsedKey !== currentRouteKey);
        });
      }
    } else {
      return setIsNavBarCollapsed(false);
    }
  }, [currentRouteKey, forceCollapsedPageKeys]);

  const toggleNavBar = useCallback(() => {
    if (currentRouteKey) {
      if (forceCollapsedPageKeys.includes(currentRouteKey)) {
        setForceCollapsedPageKeys((prevCollapsedKeys) => {
          return prevCollapsedKeys.filter((prevCollapsedKey) => prevCollapsedKey !== currentRouteKey);
        });
      } else
        setForceCollapsedPageKeys((prevCollapsedKeys) => {
          return prevCollapsedKeys.concat(currentRouteKey);
        });
    } else {
      return setIsNavBarCollapsed((prevState) => !prevState);
    }
  }, [currentRouteKey, forceCollapsedPageKeys]);

  return (
    <NavBarCollapseContext.Provider
      value={{
        collapseNavBar,
        expandNavBar,
        isNavBarCollapsed: isNavBarFinallyCollapsed,
        toggleNavBar,
      }}
    >
      {children}
    </NavBarCollapseContext.Provider>
  );
};
