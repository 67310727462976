import React, {useEffect, useState} from 'react';
import {LanguageService, useService} from '@shared/core';
import {useTranslation} from 'react-i18next';
import {setLanguageToLC} from '@shared/utilities';
import {LanguagesSelect} from '@shared/components';

const LanguageSwitch: React.FC = () => {
  const {i18n} = useTranslation();
  const {getAppLanguage} = useService(LanguageService);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() => getAppLanguage());

  const onLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setLanguageToLC(language);
    window.location.reload();
  };

  useEffect(() => {
    i18n.changeLanguage(getAppLanguage());
  }, [getAppLanguage, i18n]);

  return <LanguagesSelect value={selectedLanguage} onLanguageChange={onLanguageChange} />;
};

export default LanguageSwitch;
