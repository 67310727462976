import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FieldValues, UseFormResetField} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Office, OfficesApiService, useService} from '@innowise-group/core';
import {Select, SelectProps} from '@innowise-group/ui-kit';

interface OfficesSelectProps extends SelectProps {
  locationId?: string | string[];
  resetField?: UseFormResetField<FieldValues>;
  availableOffices?: number[];
  toUseDefaultValue?: boolean;
  findJiraOffice?: boolean;
}

const OfficesSelect: React.FC<OfficesSelectProps> = ({
  locationId,
  resetField,
  findJiraOffice = false,
  defaultValue,
  toUseDefaultValue = true,
  isFieldChanged,
  multiple,
  availableOffices = [],
  onValueChange,
  ...props
}) => {
  const [offices, setOffices] = useState<Office[]>([]);
  const officesApi = useService(OfficesApiService);
  const {t} = useTranslation();

  useEffect(() => {
    const subscriber = officesApi.getOffices().subscribe(setOffices);
    return () => subscriber.unsubscribe();
  }, [officesApi]);

  const officeFilter = useCallback(
    (office: Office) => {
      if (locationId)
        return Array.isArray(locationId) ? locationId.includes(office.locationId) : office.locationId === locationId;
      return true;
    },
    [locationId],
  );

  const locationOffices = useMemo(() => {
    if (availableOffices.length) {
      return offices.filter((office) => availableOffices.some((id) => office.id === String(id))).filter(officeFilter);
    }
    return offices.filter(officeFilter);
  }, [availableOffices, offices, officeFilter]);

  const transformValue = (id: string): string => {
    if (isFieldChanged && locationOffices.length === 1) {
      if (findJiraOffice) {
        onValueChange(locationOffices[0].jiraOfficeId);
        return locationOffices[0].address;
      } else {
        onValueChange(locationOffices[0].id);
        return locationOffices[0].address;
      }
    } else if (isFieldChanged) {
      return '';
    } else {
      if (multiple) {
        const values = id?.split(', ');
        return offices
          .filter((office) => values?.some((value) => value === office.id))
          .map((location) => location.address)
          .join(', ');
      }
      const officeById = findJiraOffice
        ? offices.find((office) => (id ? office.jiraOfficeId === id : office.jiraOfficeId === defaultValue))
        : offices.find((office) => (id ? office.id === id : office.id === defaultValue));
      if (officeById) {
        return officeById.address;
      }
    }
  };

  return (
    <Select
      options={locationOffices.map((office) => ({
        value: findJiraOffice ? office.jiraOfficeId : office.id,
        title: office.address,
      }))}
      valueTransformer={transformValue}
      defaultValue={
        toUseDefaultValue
          ? locationOffices.length === 1
            ? findJiraOffice
              ? locationOffices[0].jiraOfficeId
              : locationOffices[0].id
            : null
          : null
      }
      resetField={resetField}
      placeholder={t('placeholders.choseAddress')}
      multiple={multiple}
      onValueChange={onValueChange}
      {...props}
    />
  );
};

export default OfficesSelect;
