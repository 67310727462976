import React, {useEffect, useMemo, useState} from 'react';
import {Select, SelectProps} from '@innowise-group/ui-kit';
import {useService, Location, LocationsApiService} from '@innowise-group/core';
import {useTranslation} from 'react-i18next';

const getAddressFromLocation = ({countryName, city}: Location, cityNamesOnly: boolean): string =>
  cityNamesOnly ? city : [countryName, city].join(', ');

const LocationsSelect: React.FC<SelectProps & {cityNamesOnly?: boolean; availableLocations?: string[]}> = ({
  defaultValue,
  cityNamesOnly,
  multiple,
  availableLocations = [],
  ...props
}) => {
  const [locations, setLocations] = useState<Location[]>([]);
  const locationsApi = useService(LocationsApiService);

  const {t} = useTranslation();

  useEffect(() => {
    const subscriber = locationsApi.getLocations().subscribe(setLocations);
    return () => subscriber.unsubscribe();
  }, [locationsApi]);

  const locationsList = useMemo(() => {
    if (availableLocations.length) {
      return locations.filter((location) => availableLocations.some((id) => location.id === id));
    }
    return locations;
  }, [locations, availableLocations]);

  const transformValue = (id: string): string => {
    if (multiple) {
      const values = id?.split(', ');
      const transformedValue = locationsList
        .filter((location) => values?.some((value) => value === location.city))
        .map((location) => location.city)
        .join(', ');
      return transformedValue;
    }
    if (!isFinite(+id) && id !== undefined) return id;
    const locationById = locationsList.find((location) => (id ? location.id === id : location.id === defaultValue));
    if (locationById) {
      return getAddressFromLocation(locationById, cityNamesOnly);
    }
  };

  return (
    <Select
      options={locationsList.map((location) => ({
        value: cityNamesOnly ? location.city : location.id,
        title: getAddressFromLocation(location, cityNamesOnly),
      }))}
      valueTransformer={transformValue}
      placeholder={t('placeholders.location')}
      multiple={multiple}
      {...props}
    />
  );
};

export default LocationsSelect;
