import React, {useLayoutEffect, useState} from 'react';
import * as Styled from './accordion.styles';
// import {Icon} from '../icon';

export interface AccordionProps {
  renderHeader: (isOpen: boolean, withChildren?: boolean) => React.ReactNode;
  initialValue?: boolean;
  withIcon?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({renderHeader, initialValue = false, withIcon = false, children}) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialValue);
  // const [contentHeight, setContentHeight] = useState<number>(0);
  // const childRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsOpen((currentState) => !currentState);
  };

  useLayoutEffect(() => {
    // const {height} = childRef.current?.getBoundingClientRect() || {height: 0};
    // setContentHeight(isOpen ? height : 0);
  }, [isOpen]);

  return (
    <Styled.Container>
      <Styled.TitleContainer isOpen={isOpen} onClick={handleClick}>
        {/*<Styled.ExpandIcon>*/}
        {/*  {withIcon && children && <Icon type={isOpen ? 'u_angle-up' : 'u_angle-down'} size={24} />}*/}
        {/*</Styled.ExpandIcon>*/}
        {renderHeader(isOpen, Boolean(children))}
      </Styled.TitleContainer>
      {isOpen ? <Styled.Content>{children}</Styled.Content> : null}
      {/* <Styled.ContentWrapper isOpen={isOpen} contentHeight={contentHeight}></Styled.ContentWrapper> */}
    </Styled.Container>
  );
};

export default Accordion;
