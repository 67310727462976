import {Select, SelectProps} from '@innowise-group/ui-kit';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const RequestTypeSelect: React.FC<SelectProps> = (props) => {
  const {t} = useTranslation();

  const options = useMemo(
    () => [
      {title: t('modals.temporaryRequest'), value: 'Temporal occupation'},
      {title: t('modals.permanentRequest'), value: 'Full occupation'},
    ],
    [t],
  );

  const valueTransformer = (value: string) => {
    return options.find((option) => option.value === value)?.title;
  };

  return (
    <Select
      options={options}
      valueTransformer={valueTransformer}
      placeholder={t('placeholders.requestType')}
      {...props}
    />
  );
};

export default RequestTypeSelect;
