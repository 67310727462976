import {styled, Typography} from '@innowise-group/ui-kit';

export const ContentSide = styled.section`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.palette.general.paper};
`;

export const DescriptionContainer = styled(ContentSide)<{background: string}>`
  background-image: url(${({background}) => background});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const DividersGroup = styled.div<{background: string; position: 'top' | 'bottom'}>`
  background-image: url(${({background}) => background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 149px;
  height: 90px;
  z-index: 10;
  right: ${({position}) => position === 'top' && '2%'};
  left: ${({position}) => position === 'bottom' && '2%'};
  bottom: ${({position}) => position === 'bottom' && '2%'};
  top: ${({position}) => position === 'top' && '2%'};
`;

export const EmojisContainer = styled.div`
  display: flex;

  & > * {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EmojiContainer = styled.div`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background-color: ${({theme}) => theme.palette.general.paper};
`;

export const EmojiImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Divider = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({theme}) => theme.palette.general.white};
`;

export const LightText = styled(Typography)`
  margin: 24px 0;
  color: ${({theme}) => theme.palette.general.white};
`;

export const RedText = styled.span`
  color: ${({theme}) => theme.palette.general.smart};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
`;

export const BlackText = styled(RedText)`
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
`;

export const AuthButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 220px;
  margin-top: 26px;
  border-radius: 29px;
  border: 2px solid ${({theme}) => theme.palette.general.darkGrey[60]};
  box-sizing: border-box;
  background: none;
  cursor: pointer;
  &:hover {
    border: 2px solid ${({theme}) => theme.palette.general.darkGrey[80]};
  }
`;

export const AuthButtonText = styled(Typography)`
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
`;

export const Version = styled(Typography)`
  position: absolute;
  bottom: 100px;
  font-size: 18px;
  cursor: pointer;
  color: ${({theme}) => theme.palette.info.default};
  &:hover {
    color: ${({theme}) => theme.palette.info.hover};
  }
`;

export const SupportLinkBlock = styled.div`
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid ${({theme}) => theme.palette.general.lightGrey[40]};
  text-align: center;
  position: absolute;
  bottom: 20px;
`;

export const SupportLink = styled.a`
  text-decoration: none;
  color: ${({theme}) => theme.palette.info.default};
  cursor: pointer;
  &:hover {
    color: ${({theme}) => theme.palette.info.hover};
  }
`;
