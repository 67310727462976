import {createContext, FC, useContext} from 'react';
import {interfaces} from 'inversify';

export const InversifyContext = createContext<interfaces.Container>(null);

const ContainerProvider: FC<{container: interfaces.Container}> = ({container, children}) => (
  <InversifyContext.Provider value={container}>{children}</InversifyContext.Provider>
);

const useInjectable = <T extends unknown = any>(type: interfaces.ServiceIdentifier<any>) => {
  const container = useContext(InversifyContext);
  return container.get<T>(type);
};

const useService = <T extends unknown = any>(
  service: {type: interfaces.ServiceIdentifier<any>} & (new (...args: any[]) => T),
): T => {
  const container = useContext(InversifyContext);
  return container.get<T>(service.type);
};

const useContainerContext = () => {
  return useContext(InversifyContext);
};

export * from '../types/global-ioc.types';
export * from './auth';
export * from './http';
export * from './authentication';
export * from './employees-api';
export * from './employees-normalization';
export * from './offices-api';
export * from './locations-api';
export * from './global-ioc.container';
export * from './floors-api';
export * from './requests-api';
export * from './rooms-api';
export * from './workspaces-api';
export * from './workspaces-normalization';
export * from './floors-normalization';
export * from './language';
export {ContainerProvider, useInjectable, useService, useContainerContext};
