import {styled} from '../theme-provider';
import {css} from 'styled-components';
import {Icon} from '../icon';

export const Container = styled.div<{inputSize?: 'small' | 'default'; fullWidth?: boolean}>`
  position: relative;
  width: ${({inputSize, fullWidth}) => (fullWidth ? '100%' : inputSize === 'small' ? '50px' : '250px')};

  ${({fullWidth}) =>
    !fullWidth
      ? `
    @media (max-width: 481px) {
      width: 170px;
    }
  `
      : ''}
`;

export const Element = styled.input<{icon?: string; inputSize?: 'small' | 'default'; multiline?: boolean}>`
  padding: ${({icon, inputSize}) => (icon ? '0 20px 0 40px' : inputSize === 'small' ? '0 10px' : '0 20px')};
  height: ${({multiline}) => (multiline ? '80px' : '40px')};
  outline: none;
  margin: 0;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  border-radius: 6px;
  width: 100%;
  background-color: ${({theme}) => theme.palette.general.paper};
  color: ${({theme}) => theme.palette.general.darkGrey[100]};

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:hover {
    border-color: ${({theme}) => theme.palette.general.darkGrey[40]};
  }

  &:focus {
    border-color: ${({theme}) => theme.palette.general.darkGrey[60]};
  }

  &::placeholder {
    color: ${({theme}) => theme.palette.general.lightGrey[100]};
  }

  ${({theme}) => {
    const color = theme.palette.general.darkGrey[90].slice(1);
    return css`
      ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,
    <svg style="color:%23${color}" xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/>
    </svg>');
      }
    `;
  }}

  @media (max-width: 481px) {
    height: 30px;
    font-size: 10px;
    min-width: 100px;
    padding: ${({icon}) => (icon ? '0 5px 0 28px' : '0 10px')};
  }
`;

export const TextArea = styled(Element).attrs({as: 'textarea'})`
  font-family: inherit;
  resize: none;

  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 481px) {
    height: 60px;
  }
`;

export const InputIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  left: 10px;
  @media (max-width: 481px) {
    top: 6px;
    left: 3px;
  }
`;
