import React, {useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FadeInOutView, Icon, Tooltip, useClickOutside} from '@innowise-group/ui-kit';
import * as Styled from './export-files.styles';
import {ButtonHelper} from '../button-helper';

type Button = {
  callback: () => void;
  buttonName: string;
};

interface ExportFilesProps {
  buttons: Button[];
  helperColor?: string;
}

const ExportFiles: React.FC<ExportFilesProps> = ({buttons = [], helperColor}) => {
  const [isShowActions, setIsShowActions] = useState<boolean>(false);
  const actionsRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation();

  const closeExtraActions = useCallback(() => {
    setIsShowActions(false);
  }, []);

  const showActions = () => {
    setIsShowActions((value) => !value);
  };

  useClickOutside(actionsRef, closeExtraActions);

  return (
    <div ref={actionsRef}>
      <Tooltip
        vertical="bottom"
        horizontal="left"
        active={isShowActions}
        content={
          <FadeInOutView>
            <Styled.ActionsContainer>
              {buttons.length > 0 &&
                buttons.map(({buttonName, callback}) => {
                  const downloadReportHandler = () => {
                    callback();
                    closeExtraActions();
                  };
                  return (
                    <Styled.Action onClick={downloadReportHandler} key={buttonName}>
                      <Icon type="u_file-download-alt" />
                      <Styled.ActionText>{t(`buttons.${buttonName}`)}</Styled.ActionText>
                    </Styled.Action>
                  );
                })}
            </Styled.ActionsContainer>
          </FadeInOutView>
        }
      >
        <ButtonHelper color={helperColor} onClick={showActions} text={t('buttons.export')} icon="u_export" />
      </Tooltip>
    </div>
  );
};

export default React.memo(ExportFiles);
