import {Form, withForm, WithFormProps} from '@innowise-group/ui-kit';
import {Controller, useFormContext} from 'react-hook-form';
import {LocationsSelect, OfficesSelect} from '@shared-components';
import {useCallback, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

export enum SearchFormFields {
  Office = 'office',
  Country = 'country',
}

export type SearchFormValues = {
  country: string;
  office: string;
};

const SearchRequestForm: React.FC<WithFormProps<SearchFormValues> & {findJiraOffice?: boolean}> = ({
  onSubmit,
  isWatchFieldChanged,
  setIsWatchFieldChanged,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    resetField,
    formState: {errors},
  } = useFormContext();
  const {pathname} = useLocation();

  useEffect(() => {
    setIsWatchFieldChanged({
      [SearchFormFields.Office]: false,
    });
  }, [setIsWatchFieldChanged]);

  const countryRef = useRef(null);

  const validateId = useCallback(() => {
    return pathname.includes('/employees');
  }, [pathname]);

  useEffect(() => {
    const subscription = watch((values: SearchFormValues, {type}) => {
      if (type) {
        if (!values.country) {
          resetField(SearchFormFields.Office);
          countryRef.current = null;
          onSubmit({country: null, office: null});
          setIsWatchFieldChanged({
            [SearchFormFields.Office]: true,
          });
        } else if (countryRef.current !== values.country) {
          resetField(SearchFormFields.Office);
          countryRef.current = values.country;
          if (!validateId()) {
            onSubmit({country: values.country, office: null});
          }
          setIsWatchFieldChanged({
            [SearchFormFields.Office]: true,
          });
        } else {
          onSubmit(values);
          setIsWatchFieldChanged({
            [SearchFormFields.Office]: false,
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onSubmit, resetField, setIsWatchFieldChanged, control, handleSubmit, validateId]);

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      <Form.Layout layoutType="horizontal" noPadding>
        <Controller
          name={SearchFormFields.Country}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[SearchFormFields.Country]}>
              <LocationsSelect onValueChange={onChange} value={value} clearable />
            </Form.Field>
          )}
        />

        <Controller
          name={SearchFormFields.Office}
          control={control}
          render={({field: {onChange, value}}) => (
            <Form.Field error={errors[SearchFormFields.Office]}>
              <OfficesSelect
                locationId={watch(SearchFormFields.Country)}
                value={value}
                onValueChange={onChange}
                disabled={!watch(SearchFormFields.Country)}
                resetField={resetField}
                clearable
                isFieldChanged={isWatchFieldChanged[SearchFormFields.Office]}
                findJiraOffice={validateId()}
              />
            </Form.Field>
          )}
        />
      </Form.Layout>
    </Form.Container>
  );
};

export default withForm(SearchRequestForm);
