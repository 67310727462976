import {notificationsManager} from '@innowise-group/ui-kit';
import {map, tap, Observable} from 'rxjs';
import {RoomResponse} from '../rooms-api';
import {FloorPlan, FloorResponse, GlobalContainerTypes, Http, inject, injectable, Room, Language} from '../..';
import {
  FloorsApi,
  RetrievePlanResponse,
  UploadPlanPayload,
  RetrievePlan,
  EditFloorTypePayload,
  ReportFileType,
  EditFloorManagersPayload,
  EditFloorManagersResponse,
} from './floors-api.types';
import {FloorsNormalization} from '../floors-normalization';

@injectable()
class FloorsApiService implements FloorsApi {
  public static readonly type = GlobalContainerTypes.FloorsApi;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(GlobalContainerTypes.Language) private language: Language;
  @inject(GlobalContainerTypes.FloorsNormalization) private floorPlanNormalization: FloorsNormalization;

  constructor() {
    this.getReport = this.getReport.bind(this);
  }

  public uploadPlan(id: string, floorData: UploadPlanPayload, toSaveArrangements: boolean): Observable<unknown> {
    const formData = new FormData();
    Object.keys(floorData).forEach((key) => {
      formData.append(key, floorData[key]);
    });
    return this.http.PATCH(`/floors/${id}/create-plan/?save_arrangements=${toSaveArrangements}`, formData).pipe(
      tap<void>(() => {
        notificationsManager.success({title: 'success', subtitle: 'updatePlan'});
      }),
    );
  }

  public retrievePlan(id: string): Observable<RetrievePlan> {
    return this.http
      .GET<RetrievePlanResponse>(`/floors/${id}/retrieve-plan/`)
      .pipe(map(this.floorPlanNormalization.normalizeRetrievePlanFromApi));
  }

  public getReport(id: string, type: ReportFileType): Observable<{file: File; language: string}> {
    const path = `retrieve-${type === 'xlsx' ? 'xlsx-statistic' : `floor-${type === 'csv' ? 'data' : 'image'}`}`;
    return this.http
      .GET(`/floors/${id}/${path}`, {responseType: 'arraybuffer'})
      .pipe(map((data: File) => ({file: data, language: this.language.getAppLanguage()})));
  }

  public getRoomsListFloor(floorId: string): Observable<Room[]> {
    return this.http.GET<RoomResponse[]>(`/floors/${floorId}/retrieve-room-list/`).pipe(
      map((list) =>
        list
          .sort((a, b) => +a.number - +b.number)
          .map(({id, number, room_type, our}) => ({
            id: id && String(id),
            number: number && String(number),
            roomType: room_type,
            isOur: our,
          })),
      ),
    );
  }

  public getAvailableRoomsListFloor(floorId: string): Observable<Room[]> {
    return this.http.GET<RoomResponse[]>(`/floors/${floorId}/retrieve-room-list/?available-only=true`).pipe(
      map((list) =>
        list
          .sort((a, b) => +a.number - +b.number)
          .map(({id, number, room_type, our}) => ({
            id: id && String(id),
            number: number && String(number),
            roomType: room_type,
            isOur: our,
          })),
      ),
    );
  }

  public editFloorType(id: string, floorType: EditFloorTypePayload) {
    return this.http.PATCH(`floors/${id}/change-floor-type/`, floorType).pipe(
      tap<void>(() => {
        notificationsManager.success({title: 'success', subtitle: 'updateTypeFloor'});
      }),
    );
  }

  public getListFloors(): Observable<FloorPlan[]> {
    return this.http
      .GET<FloorResponse[]>(`floors/`)
      .pipe(map((list) => list.map((floor) => this.floorPlanNormalization.normalizeFloor(floor, 'floor'))));
  }

  public editFloorManagers(id: string, managers: EditFloorManagersPayload): Observable<EditFloorManagersResponse> {
    return this.http
      .PATCH<EditFloorManagersResponse, EditFloorManagersPayload>(`floors/${id}/update-floor-manager/`, managers)
      .pipe(
        tap(() => {
          notificationsManager.success({title: 'success', subtitle: 'updateFloor'});
        }),
      );
  }
}

export default FloorsApiService;
